import { handleAxiosHTTPException } from "WebUI_Framework";

let salesordersserviceroutines = {};

salesordersserviceroutines.list = async (serverInstance, accessToken, offset, recordsPerFrame, company_id) => {
    let response;
    try {
        response =
            await serverInstance.get(
                `biop/subscriber/sales_order/list?offset=${offset}&num_recs=${recordsPerFrame}&seller_org_id=${company_id}`,
                {
                    headers: {
                        "X-Auth": accessToken
                    }
                }
            );
        return response.data;
    }
    catch (e) {
        return handleAxiosHTTPException(e);
    }
}

salesordersserviceroutines.fetch = async (serverInstance, accessToken, selectedOrderId) => {
    let response;
    try {
        response = await serverInstance.get(`biop/subscriber/sales_order/fetch?order_id=${selectedOrderId}`,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
        );

        return response.data;
    }
    catch (e) {
        return handleAxiosHTTPException(e);
    }
}

salesordersserviceroutines.fetchUsingNumberAndDate = async (serverInstance, accessToken,
														sellerOrgId, orderDate, orderNumber) => {
    let response;
	let params = {
		order_number : orderNumber,
		order_issue_date : orderDate,
		buyer_org_id : sellerOrgId,
	};
    try {
        response = await serverInstance.get(`biop/subscriber/sales_order/fetch_using_number_and_date`,
            {
				params,
                headers: {
                    "X-Auth": accessToken
                }
            }
        );

        return response.data;
    }
    catch (e) {
        return handleAxiosHTTPException(e);
    }
}

export default salesordersserviceroutines;
