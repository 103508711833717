import { getServiceDetails } from '../common/utils'
import { handleAxiosHTTPException } from "WebUI_Framework";

let quotationServiceRoutines = {};

quotationServiceRoutines.list = async (session, obj) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(
			`biop/subscriber/rrfq/list`,
			{
				headers: {
					"X-Auth": accessToken,
				},
				params : obj
			});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

quotationServiceRoutines.fetchReceivedRFQ = async (session, rrfqId) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(`biop/subscriber/rrfq/fetch?rrfq_id=${rrfqId}`,
			{
				headers: {
					"X-Auth": accessToken,
				},
			}
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

quotationServiceRoutines.fetch = async (session, selectedQuotationId) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(`biop/subscriber/quotation/fetch?quotation_id=${selectedQuotationId}`,
			{
				headers: {
					"X-Auth": accessToken,
				},
			}
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

quotationServiceRoutines.fetchUsingNumberAndDate = async (session, quotationNumber, quotationDate, sellerOrgId, buyerOrgId) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
    let response;
	let params = {
		quotation_number : quotationNumber,
		quotation_issue_date : quotationDate,
		buyer_org_id : buyerOrgId,
		seller_org_id : sellerOrgId,
	};
	try {
		response = await serverInstance.get(
			`biop/subscriber/quotation/fetch_using_number_and_date`,
			{
				params,
				headers: {
					"X-Auth": accessToken,
				},
			}
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};


quotationServiceRoutines.modify = async (session, retObj_modify) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post("biop/subscriber/quotation/modify", retObj_modify, {
			headers: {
				"X-Auth": accessToken,
			},
		});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

quotationServiceRoutines.add = async (session, retObj) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post("biop/subscriber/quotation/add", retObj, {
			headers: {
				"X-Auth": accessToken,
			},
		});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

quotationServiceRoutines.approve = async (session, quotation) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post('/biop/subscriber/quotation/approve',
			quotation,
			{
				headers: {
					"X-Auth": accessToken
				}
			}
		);
		return response.data;
	}
	catch (e) {
		return handleAxiosHTTPException(e);
	}
}

quotationServiceRoutines.delete = async (session, retData) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.put(`biop/subscriber/quotation/delete`, retData, {
			headers: {
				"X-Auth": accessToken,
			},
		});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

export default quotationServiceRoutines;
