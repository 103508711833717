import {
	GenericComponent,
	NarrowButton,
	GenericForm,
	FormField,

	MultiRec,
	MultiRecFormField,
	copyMultiRecFormFieldsToDataArray,
	copyDataArrayToMultiRecFormFields,
	deleteMultiRecRow,
	addMultiRecRow,
	multiRecFieldValidations,

	fieldValidations,
	getInitialState,
	copyFormFieldsToDataFields,
	copyDataFieldsToFormFields,
	deepCopy,
	raiseError,
	valuePresent,

	filteredselect,
	filtered_select,
	searchselect,
	search_select,
	document_number,
	documentnumber,
	place_holder,
	placeholder,
	input_date,
	inputdate,
	genericstring,
	generic_string,
	genericstring_256,
	generic_string_256,
	genericcheckbox,
	generic_checkbox,
	itemid,
	item_id,
	genericdecimal,
	generic_decimal,
	unit_measure,
	unitmeasure,
	file_set,
	FileSet,

} from 'WebUI_Framework';

import Attachments from '../common/Attachments'
import receiptNoteServiceRoutines from './ReceiptNoteServiceRoutines'
import PartnerList from '../common/PartnerList'
import PurchaseOrderList from '../common/PurchaseOrderList'
import { isSubscriber } from '../common/utils'

const header_fields = {
	/*
	order_number: {
		id: "order_number", name: "order_number", label: window.getLabelLiteralWithColon("PO_NUMBER"),
		class: documentnumber, component: document_number, mandatory: false
		//, initialValue : "ABC1234"
	},
	*/
	order_number: {
		id: "order_number", name: "order_number", label: window.getLabelLiteralWithColon("PO_NUMBER"),
		class: searchselect, component: search_select, mandatory: false
		//, initialValue : "ABC1234"
	},

	order_issue_date: {
		id: "order_issue_date", name: "order_issue_date",
		label: window.getLabelLiteralWithColon("PO_ISSUE_DATE"), class: inputdate, component: input_date, mandatory: false
		//, initialValue : "2023-10-06"
	},

	seller_org_id: {
		id: "seller_org_id", name: "seller_org_id", label: window.getLabelLiteralWithColon("SELLER_ORG"),
		class: filteredselect, component: filtered_select, mandatory: true
	},

	seller_org_name: {
		id: "seller_org_name", name: "seller_org_name", label: window.getLabelLiteralWithColon("SELLER_ORG"),
		class: searchselect, component: search_select, mandatory: true
	},

	place_holder: {
		id: "place_holder", name: "place_holder", label: "",
		class: placeholder, component: place_holder, mandatory: false
	},

	buyer_org_id: {
		id: "buyer_org_id", name: "buyer_org_id", label: window.getLabelLiteralWithColon("BUYER_ORG_ID"),
		class: genericstring, component: generic_string, mandatory: false
	},

	buyer_org_name: {
		id: "buyer_org_name", name: "buyer_org_name", label: window.getLabelLiteralWithColon("BUYER_ORG"),
		class: searchselect, component: search_select, mandatory: false
	},

	receipt_note_number: {
		id: "receipt_note_number", name: "receipt_note_number", label: window.getLabelLiteralWithColon("RECEIPT_NOTE_NUMBER"),
		class: documentnumber, component: document_number, mandatory: true
		//, initialValue : "ABC1234"
	},

	receipt_note_date: {
		id: "receipt_note_date", name: "receipt_note_date", label: window.getLabelLiteralWithColon("RECEIPT_NOTE_DATE"),
		class: inputdate, component: input_date, mandatory: true
		//, initialValue : "2023-10-06"
		//, initialValue : (new Date.toJSON().slice(0, 10))
	},

	delivery_note_number: {
		id: "delivery_note_number", name: "delivery_note_number", label: window.getLabelLiteralWithColon("DELIVERY_NOTE_NUMBER"),
		class: documentnumber, component: document_number, mandatory: false
		//, initialValue : "ABC1234"
	},

	delivery_note_date: {
		id: "delivery_note_date", name: "delivery_note_date", label: window.getLabelLiteralWithColon("DELIVERY_NOTE_DATE"),
		class: inputdate, component: input_date, mandatory: false
		//, initialValue : "2023-10-06"
	},

	description: {
		id: "description", name: "description", label: window.getLabelLiteralWithColon("DESCRIPTION"),
		class: genericstring, component: generic_string, mandatory: false
		//, initialValue : "2023-10-06"
	},
	attachments: {
		id: "attachments",
		name: "attachments",
		label: window.getLabelLiteralWithColon("ATTACHMENTS"),
		class: file_set,
		component: FileSet,
		mandatory: false,
		//initialValue: "1234244.00",
	},

}

let header_mapping = [
	["order_number", "order_number"],
	["order_issue_date", "order_issue_date"],
	["seller_org_id", "seller_org_id"],
	["seller_org_name", "seller_org_name"],
	["buyer_org_id", "buyer_org_id"],
	["buyer_org_name", "buyer_org_name"],
	["receipt_note_number", "receipt_note_number"],
	["receipt_note_date", "receipt_note_date"],
	["delivery_note_number", "delivery_note_number"],
	["delivery_note_date", "delivery_note_date"],
	["description", "description"],
	["attachments", "attachments"]
];

const details_fields = {
	seller_item_id: {
		id: "seller_item_id", name: "seller_item_id", label: window.getLabelLiteralWithColon("SELLER_ITEM_ID"),
		class: itemid, component: item_id, mandatory: true
	},

	dispatched_quantity: {
		id: "dispatched_quantity", name: "dispatched_quantity", label: window.getLabelLiteralWithColon("DISPATCHED_QUANTITY"),
		class: genericdecimal, component: generic_decimal, mandatory: false
	},

	accepted_quantity: {
		id: "accepted_quantity", name: "accepted_quantity", label: window.getLabelLiteralWithColon("ACCEPTED_QUANTITY"),
		class: genericdecimal, component: generic_decimal, mandatory: true
	},

	unit_of_measure: {
		id: "unit_of_measure", name: "unit_of_measure", label: window.getLabelLiteralWithColon("UNIT_OF_MEASURE"),
		class: unitmeasure, component: unit_measure, mandatory: true
	},

	delivery_note_remarks: {
		id: "delivery_note_remarks", name: "delivery_note_remarks", label: window.getLabelLiteralWithColon("DELIVERY_NOTE_REMARKS"),
		class: genericstring_256, component: generic_string_256, mandatory: false
	},

	receipt_note_remarks: {
		id: "receipt_note_remarks", name: "receipt_note_remarks", label: window.getLabelLiteralWithColon("RECEIPT_NOTE_REMARKS"),
		class: genericstring_256, component: generic_string_256, mandatory: false
	},

	seller_item_description: {
		id: "seller_item_description", name: "seller_item_description", label: window.getLabelLiteralWithColon("ITEM_DESCRIPTION"),
		class: genericstring, component: generic_string, mandatory: false
	},

	item_delivery_complete: {
		id: "item_delivery_complete", name: "item_delivery_complete", label: window.getLabelLiteralWithColon("DELIVERY_COMPLETE"),
		class: genericcheckbox, component: generic_checkbox, mandatory: false
	},

	deleted: {
		id: "deleted", name: "deleted", label: window.getLabelLiteralWithColon("DELETED"),
		class: genericcheckbox, component: generic_checkbox, mandatory: false
	}

};

let details_mapping = [
	["seller_item_id", "seller_item_id"],
	["dispatched_quantity", "dispatched_quantity"],
	["accepted_quantity", "accepted_quantity"],
	["unit_of_measure", "unit_of_measure"],
	["delivery_note_remarks", "delivery_note_remarks"],
	["receipt_note_remarks", "receipt_note_remarks"],
	["seller_item_description", "seller_item_description"],
	["item_delivery_complete", "item_delivery_complete"],
	["deleted", "deleted"],
];

class AddEditReceiptNote extends GenericComponent {
	constructor (props) {
		super(props);
		this.state.loading = true;
		this.state.step = 1;

		this.readOnly = false;
		this.subscriberServerInstance = this.session.getServerInstance('SUBSCRIBER');
		this.accessToken = this.session.getAccessToken();
		this.action = props.action;
		this.receiptNoteId = props.receiptNoteId;
		this.receiptNote = {header: {}, details: [{}]};
		this.logged_in_company_id = this.session.getLoggedInCompanyId();
		this.parent_company_id = this.session.getLoggedInParentCompanyId();
		this.is_subscriber = (this.logged_in_company_id === this.parent_company_id);
		this.page_title = "";
		this.page_title += (this.action === "DELETE")?window.getLabelLiteral("DELETING"):window.getLabelLiteral(this.action);
		if (this.action === "ADD") {
			this.state.data.receipt_note_date = this.session.getCurrentDate();
		}
		else if (this.action === "EDIT" || this.action === "AMEND") {
			this.state.step = 2;
		}
		else if (this.action === "APPROVE" || this.action === "CANCEL" || this.action === "ACCEPT" || this.action === 'REJECT') {
			this.state.step = 2;
			this.readOnly = true;
		}
		else if (this.action === "VIEW") {
			this.readOnly = true;
			this.state.step = 2;
		}
		else if (this.action === "DELETE") {
			this.readOnly = true;
		}
		this.page_title += " " + window.getLabelLiteral("RECEIPT_NOTE_TITLE");
	}

	initialState = getInitialState(header_fields);

	state = {
		data: deepCopy(this.initialState),
	}

	reset = () => {
		let data = deepCopy(this.initialState);
		this.setState({ data: data, error: undefined });
	}

	backHandler = (retFrom, error) => {
		if (this.isChildMode())
			this.props.returnToParent({ retFrom, arrayIndex: this.props.arrayIndex,
								selectData: this.receiptNote, error });
		else console.log("LOADED AS A PARENT");
	}

	fetch = async (receiptNoteId) => {
		return await receiptNoteServiceRoutines.fetch(this.subscriberServerInstance, this.accessToken, receiptNoteId);
	}

	fetchAmended = async (receiptNoteId) => {
		return await receiptNoteServiceRoutines.fetchAmended(this.subscriberServerInstance, this.accessToken, receiptNoteId);
	}

	ownedDocument = () => {
		return (!valuePresent(this.receiptNote.header.document_owner_org_id) ||
			this.receiptNote.header.document_owner_org_id === this.session.getLoggedInCompanyId());
	}

	delete = async (receiptNote) => {
		let retData;
		try {
			await receiptNoteServiceRoutines.delete(this.subscriberServerInstance,
										this.accessToken, receiptNote);
		}
		catch (e) {
			//this.setError(raiseError(e.error_message));
			throw e;
		}

		return retData
	}

	componentDidMount = async () => {
		let newData_1, newData_2, newData;
		try {
			switch (this.action) {
				case 'ADD':
					newData = deepCopy(this.state.data);
					newData = copyDataArrayToMultiRecFormFields(details_mapping, this.receiptNote.details, newData);
					this.receiptNote.header.buyer_org_id = this.logged_in_company_id;
					newData.buyer_org_id = this.logged_in_company_id;
					this.setState({loading: false, data : newData});
					break;
				case 'AMEND':
				case 'CANCEL':
				case 'ACCEPT':
				case 'REJECT':
					this.receiptNote = await this.fetchAmended(this.receiptNoteId);
					newData_1 = deepCopy(this.state.data);
					copyDataFieldsToFormFields(header_mapping, this.receiptNote.header, newData_1);
					newData_2 = copyDataArrayToMultiRecFormFields(details_mapping, this.receiptNote.details, newData_1);
					if (newData_2.order_issue_date !== "" && newData_2.order_number !== "")
						await this.fetchOrder(newData_2.buyer_org_id, newData_2.order_issue_date, newData_2.order_number);
					this.setState({loading: false, data: newData_2});
					break;
				case 'EDIT':
				case 'VIEW':
				case 'APPROVE':
					this.receiptNote = await this.fetch(this.receiptNoteId);
					newData_1 = deepCopy(this.state.data);
					copyDataFieldsToFormFields(header_mapping, this.receiptNote.header, newData_1);
					newData_2 = copyDataArrayToMultiRecFormFields(details_mapping, this.receiptNote.details, newData_1);
					if (newData_2.order_issue_date !== "" && newData_2.order_number !== "")
						await this.fetchOrder(newData_2.buyer_org_id, newData_2.order_issue_date, newData_2.order_number);
					this.setState({loading: false, data: newData_2});
					break;
				case 'DELETE':
					this.receiptNote = await this.fetch(this.receiptNoteId);
					newData_1 = deepCopy(this.state.data);
					copyDataFieldsToFormFields(header_mapping, this.receiptNote.header, newData_1);
					newData_2 = copyDataArrayToMultiRecFormFields(details_mapping, this.receiptNote.details, newData_1);
					this.setState({data: newData_2});
					if (this.receiptNote.deleted === true) {
						this.setError(raiseError(window.getLabelLiteral("RECORD_DELETED") + " ["+this.receiptNoteId+"]"));
						this.backHandler(this.action);
					}
					await this.delete(this.receiptNote);
					this.backHandler(this.action);
					break;
				default:
					this.setError(raiseError("AddEditReceiptNote: Invalid action ["+this.action+"]"));
					this.backHandler(this.action, this.error);
					break;
			}
		}
		catch (e) {
			console.log(this.action);
			console.log(e);
			this.setError(raiseError((e.error_message)?e.error_message:e.message));
			this.backHandler(this.action, this.error);
		}
	}

	partnerSelectOption = (org_id, org_name) => {
		let data = deepCopy(this.state.data)
		data.seller_org_name = org_name;
		data.seller_org_id = org_id;
		this.setState({ data });
	}

	poSelectOption = (order_number, order_issue_date, buyer_org_id, seller_org_id, seller_org_name) => {
		let data = deepCopy(this.state.data)
		data.order_number = order_number;
		data.order_issue_date = order_issue_date;
		if (isSubscriber(this.session) && valuePresent(seller_org_id) && seller_org_id !== "") {
			data.seller_org_id = seller_org_id;
			data.seller_org_name = seller_org_name;
		}
		this.setState({ data });
	}

	poOnChange = (event) => {
		let data = deepCopy(this.state.data)
		data.order_number = event.target.value;
		this.setState( { data } );

	}

	addNewRow = () => {
		let data = this.state.data;
		this.resetError();
		let [ newData, new_list]  = addMultiRecRow(details_mapping, data, 
			this.receiptNote.details, undefined, undefined, [], details_fields);
		this.receiptNote.details = new_list;
		this.setState({data: newData});
	}

	button_inputs = [
		{
			literal: "Add",
			func: () => this.addNewRow()
		}
	]

	deleteRow = (data, i) => {
		if (this.receiptNote.details[i].version === null || this.receiptNote.details[i].version === undefined) {
			this.resetError();
			let [ newData, updated_list ] = deleteMultiRecRow(details_mapping,
								this.state.data, this.receiptNote.details, i, undefined, [], details_fields);
			this.receiptNote.details = updated_list;
			this.setState({data: newData});
		}
		else {
			this.receiptNote.details[i].deleted = true;
		}
	}

	getHeader = () => {
		let key = 0;
		return (
			<tr key={++key}>
				<th key={++key}>{window.getLabelLiteral("SELLER_ITEM_ID")}</th>
				<th key={++key}>{window.getLabelLiteral("UNIT_OF_MEASURE")}</th>
				<th key={++key}>{window.getLabelLiteral("DISPATCHED_QUANTITY")}</th>
				<th key={++key}>{window.getLabelLiteral("ACCEPTED_QUANTITY")}</th>
				<th key={++key}>{window.getLabelLiteral("DELIVERY_NOTE_REMARKS")}</th>
				<th key={++key}>{window.getLabelLiteral("RECEIPT_NOTE_REMARKS")}</th>
				<th key={++key}>{window.getLabelLiteral("SELLER_ITEM_DESCRIPTION")}</th>
				<th key={++key}>{window.getLabelLiteral("DELIVERY_COMPLETE")}</th>
				{(!this.readOnly) &&
					<th key={++key}>{window.getLabelLiteral("DELETE")}</th>
				}
			</tr>
		);
	}

	fetchOrder = async (buyerOrgId, orderIssueDate, orderNumber) => {
		let retData;
		try {
			retData = await receiptNoteServiceRoutines.fetch_order(this.subscriberServerInstance,
													this.accessToken, buyerOrgId, orderIssueDate, orderNumber);
			this.order = retData;
		}
		catch (e) {
			//this.setError(raiseError(e.error_message));
			//console.log(e.error_message);
			this.order = null;
		}

	}

	getRow = (data, index) => {
		//console.log(++(this.i)) 
		const i = index-1;

		let delete_button = 1;
		if (data.version >=0) 
			delete_button = 2;
		
		let view_delete_option = 0;
		if (!this.readOnly && delete_button===1) view_delete_option = 1;
		else if (!this.readOnly && delete_button===2) view_delete_option = 2;

		let select = false;
		let selectList = [];
		if (this.order) {
			select = true;
			selectList = deepCopy(this.order.order_line_item);
		}

		selectList.forEach(item => {
			if (item.hasOwnProperty('seller_item_id')) {
				item['item_id'] = item['seller_item_id'];
				item['item_description'] = item['seller_item_description'];
				delete item['seller_item_id'];
				delete item['seller_item_description'];
			}
		});

		let itemIdChange = (event, i) => {
			let data = deepCopy(this.state.data);
			/*
			 * We have to set item id also because the state change done in 
			 * genericcomponent will get overwritten by the following change
			 */
			let selected_i = event.target.selectedIndex;
			let selected_rec = selectList[selected_i-1];
			data[`seller_item_id_${i}`] = selected_rec.item_id;
			data[`seller_item_description_${i}`] = selected_rec.item_description;
			data[`unit_of_measure_${i}`] = selected_rec.unit_of_measure;
			data[`accepted_quantity_${i}`] = selected_rec.quantity;
			data[`dispatched_quantity_${i}`] = selected_rec.quantity;
			this.setState({ data });
		}

		let r = 
			<tr key={index}>
				<td>
					{(select) &&
						<MultiRecFormField field={details_fields.seller_item_id}
							controlProps={this.controlProps} index={i}
							readOnly={((this.readOnly) || (data.version!==undefined && data.version!==null))}
							select={select} selectList={selectList}
							style={{width:"75%"}}
							onChange={(event) => { return itemIdChange(event, i); }} />
					}
					{!(select) &&
						<MultiRecFormField field={details_fields.seller_item_id}
							style={{width:"75%"}}
							controlProps={this.controlProps} index={i}
							readOnly={((this.readOnly) || (data.version!==undefined && data.version!==null))}
						/>
					}
				</td>
				<td>
					<MultiRecFormField field={details_fields.unit_of_measure}
						controlProps={this.controlProps} index={i}
						style={{width: "90%"}}
						disabled={this.readOnly || select===true}/>
				</td>
				<td>
					<MultiRecFormField field={details_fields.dispatched_quantity}
						controlProps={this.controlProps}
						index={i}
						style={{width:"80%", textAlign:"right"}}
						readOnly={this.readOnly && ((this.action !== "ACCEPT" && this.action !== "REJECT") || this.ownedDocument())}
						/>
				</td>
				<td>
					<MultiRecFormField field={details_fields.accepted_quantity}
						controlProps={this.controlProps}
						index={i}
						style={{width:"80%", textAlign:"right"}}
						readOnly={this.readOnly && ((this.action !== "ACCEPT" && this.action !== "REJECT") || this.ownedDocument())}
						/>
				</td>
				<td>
					<MultiRecFormField field={details_fields.delivery_note_remarks} controlProps={this.controlProps} index={i}
						style={{width: "75%"}}
						readOnly={true}
						/>
				</td>
				<td>
					<MultiRecFormField field={details_fields.receipt_note_remarks} controlProps={this.controlProps} index={i}
						style={{width: "75%"}}
						readOnly={this.readOnly && ((this.action !== "ACCEPT" && this.action !== "REJECT") || this.ownedDocument())}
						/>
				</td>
				<td>
						<MultiRecFormField
							field={details_fields.seller_item_description}
							controlProps={this.controlProps}
							index={i}
							style={{ width: "100%", textAlign: "left" }}
							readOnly={this.readOnly} />
				</td>
				<td>
					<div className="ml-2" style={{paddingLeft: "70px"}}>
						<MultiRecFormField field={details_fields.item_delivery_complete} controlProps={this.controlProps}
							style={{padding:"0.1rem"}} index={i}
							disabled={this.readOnly && (this.action !== "ACCEPT" || this.ownedDocument())}/>
					</div>
				</td>
				{ (view_delete_option===1) &&
					<td>
						<div style={{cursor: "pointer"}}>
							<span onClick={() => {return this.deleteRow(data, i);}}>
								<i className="bi bi-trash3" style={{ fontSize: 18 }}></i>
							</span>
						</div>
					</td>
				}
				{ (view_delete_option===2) &&
					<td>
						<div className="ml-2" style={{paddingLeft: "30px"}}>
							<MultiRecFormField field={details_fields.deleted} controlProps={this.controlProps}
								style={{padding:"0.1rem"}} index={i} disabled={this.readOnly}/>
						</div>
					</td>
				}
			</tr>
		return r;
	}

	partnerListOnChange = (event) => {
		console.log(event.target.value);
	}

	proceed = async () => {
		this.resetError();
		let r = fieldValidations(header_fields, this.state.data);
		if (!r.status) {
			this.setError(r);
			return false;
		}

		let data = deepCopy(this.state.data);
		if (this.state.data.order_issue_date !== "" && this.state.data.order_number !== "") {
			await this.fetchOrder(this.state.data.buyer_org_id, this.state.data.order_issue_date, this.state.data.order_number);
			if (this.order) {
				data.seller_org_id = this.order.order_header.seller_org_id;
				data.seller_org_name = this.order.order_header.seller_org_name;
			}
		}
		else {
			this.order = null
		}
		this.setState({step: 2, data});
	}

	data_render = () => {
		let buttons = [];
		let key = 0;

		let datablk = <div className="container-fluid pt-0 m-0 pe-2">
			<div className="row  p-0 m-0 g-0 " >
				<div className="col-sm-12 h6 text-left" >{this.getBreadcrumb([window.getLabelLiteral(this.action)])}</div>

				<div className="col-sm-6" >
					<PartnerList
						selectOption={this.partnerSelectOption}
						field={ header_fields.seller_org_id }
						controlProps={ this.controlProps }
						relationship='S'
						readOnly={((this.readOnly) || (this.state.step>1) || (this.action !== "ADD"))}
					/>
				</div>

				<div className="col-sm-6" >
					<FormField field={header_fields.place_holder} controlProps={this.controlProps} readOnly={this.readOnly}/>
				</div>
				<div className="col-sm-6" >
					<FormField field={header_fields.order_issue_date} controlProps={this.controlProps}
								readOnly={((this.readOnly) || (this.state.step>1) || (this.action !== "ADD"))}/>
				</div>
				<div className="col-sm-6" >
					<PurchaseOrderList
						selectOption={this.poSelectOption}
						field={ header_fields.order_number }
						issueDateFieldName="order_issue_date"
						controlProps={ this.controlProps }
						onChange={this.poOnChange}
						readOnly={((this.readOnly) || (this.state.step>1) || (this.action !== "ADD"))}
					/>
				</div>
				<div className="col-sm-6" >
					<FormField field={header_fields.receipt_note_date} controlProps={this.controlProps}
						readOnly={((this.readOnly) || (this.state.step>1) || (this.action !== "ADD"))}
						/>
				</div>
				<div className="col-sm-6" >
					<FormField field={header_fields.receipt_note_number} controlProps={this.controlProps}
						readOnly={((this.readOnly) || (this.state.step>1) || (this.action !== "ADD"))}
						/>
				</div>
				<div className="col-sm-6" >
					<FormField field={header_fields.delivery_note_date} controlProps={this.controlProps} readOnly={this.readOnly}/>
				</div>
				<div className="col-sm-6" >
					<FormField field={header_fields.delivery_note_number} controlProps={this.controlProps}
						readOnly={this.readOnly}
						/>
				</div>
				<div className="col-sm-6" >
					<FormField field={header_fields.description} controlProps={this.controlProps}
						readOnly={this.readOnly && ((this.action !== "ACCEPT" && this.action !== "REJECT") || this.ownedDocument())}
						/>
				</div>

				<div className="col-sm-6 mt-2 mb-3 ">
						<Attachments
							field={header_fields.attachments}
							controlProps={this.controlProps}
							readOnly={this.readOnly}
						/>
				</div>

				{ (this.state.step === 1) &&
					<div className="col-sm-6 mt-4 d-flex justify-content-right ">
						<NarrowButton value="Proceed" onClick={this.proceed}  />
						<NarrowButton value="Back" onClick={() => {return this.backHandler("BACK");}} key={(++key).toString()} />
					</div>
				}

				{ (this.state.step === 2) &&
					<>
						<div style={{ margin:0, padding:0, height:"25px" }}></div>
						<MultiRec getRow={this.getRow} getHeader={this.getHeader}
								data={this.receiptNote.details} buttons={(this.readOnly) ? null : this.button_inputs}/>
					</>
				}

			</div>

			{ (this.state.step === 2) &&
				<div className="row  p-0 m-0 g-0 " >
					<div className="col-sm-6 mt-4 d-flex justify-content-right ">
						{!(this.readOnly) &&
							<NarrowButton value="Submit" onClick={this.submitForm} key={(++key).toString()} />
						}
						{(this.readOnly && (this.action === "APPROVE")) &&
							<NarrowButton value="Approve" onClick={this.submitForm} key={(++key).toString()} />
						}
						{(this.readOnly && (this.action === "CANCEL")) &&
							<NarrowButton value="Cancel" onClick={this.submitForm} key={(++key).toString()} />
						}
						{(this.readOnly && (this.action === "ACCEPT")) &&
							<NarrowButton value="Accept" onClick={this.submitForm} key={(++key).toString()} />
						}
						{(this.readOnly && (this.action === "REJECT")) &&
							<NarrowButton value="Reject" onClick={this.submitForm} key={(++key).toString()} />
						}
						<NarrowButton value="Back" onClick={() => {return this.backHandler("BACK");}} key={(++key).toString()} />
					</div>
				</div>
			}
		</div>

		let form = <GenericForm datablk={datablk} buttons={buttons} controlProps={this.controlProps} { ...this.props} />
		return form;
	}

	loading_render = () => {
		let buttons = [];

		let datablk = <div className="container pt-0 m-0 ">
			<div className="row  p-0 m-0 g-0 " >
				{/*<div className="col-sm-12 h4 text-center" >{this.page_title}</div>*/}
				<div className="d-flex justify-content-evenly">
					<div className="spinner-border text-dark" role="status">
						<span className="sr-only">Loading...</span>
					</div>
				</div>
			</div>

		</div>

		let form = <GenericForm datablk={datablk} buttons={buttons} controlProps={this.controlProps} { ...this.props} />
		return form;
	}

	render = () => {
		if (this.state.loading) {
			return this.loading_render();
		}
		else {
			return this.data_render();
		}
	}

	validateForm = () => {
		let r = fieldValidations(header_fields, this.state.data);
		if (!r.status) {
			this.setError(r);
			return false;
		}
		r = multiRecFieldValidations(details_fields, this.state.data);
		if (!r.status) {
			this.setState({error : r} );
			return false;
		}

		return true;
	}

	submitForm = async () => {
		this.resetError();

		if (!this.validateForm()) {
			return;
		}

		let data = deepCopy(this.state.data);
		copyFormFieldsToDataFields(header_mapping, data, this.receiptNote.header, header_fields);
		copyMultiRecFormFieldsToDataArray(details_mapping, this.state.data, this.receiptNote.details, null, [], details_fields);
		try {
			switch (this.action) {
				case "ADD":
					await receiptNoteServiceRoutines.add(this.subscriberServerInstance, this.accessToken, this.receiptNote);
					this.receiptNote =
						await receiptNoteServiceRoutines.fetchUsingNumberAndDate(this.subscriberServerInstance, this.accessToken,
														this.receiptNote.header.receipt_note_number,
														this.receiptNote.header.receipt_note_date,
														this.receiptNote.header.buyer_org_id);

					break;
				case "EDIT":
					await receiptNoteServiceRoutines.modify(this.subscriberServerInstance, this.accessToken, this.receiptNote);
					this.receiptNote = await this.fetch(this.receiptNoteId);
					break;
				case "AMEND":
					await receiptNoteServiceRoutines.amend(this.subscriberServerInstance, this.accessToken, this.receiptNote);
					this.receiptNote = await this.fetch(this.receiptNoteId);
					console.log(this.receiptNote);
					break;
				case "CANCEL":
					await receiptNoteServiceRoutines.cancelAmendMent(this.subscriberServerInstance, this.accessToken, this.receiptNote);
					this.receiptNote = await this.fetch(this.receiptNoteId);
					break;
				case "ACCEPT":
					await receiptNoteServiceRoutines.accept(this.subscriberServerInstance, this.accessToken, this.receiptNote);
					this.receiptNote = await this.fetch(this.receiptNoteId);
					break;
				case "REJECT":
					await receiptNoteServiceRoutines.reject(this.subscriberServerInstance, this.accessToken, this.receiptNote);
					this.receiptNote = await this.fetch(this.receiptNoteId);
					break;
				case "APPROVE":
					await receiptNoteServiceRoutines.approve(this.subscriberServerInstance, this.accessToken, this.receiptNote);
					this.receiptNote = await this.fetch(this.receiptNoteId);
					break;
				default:
					break;
			}

			this.backHandler(this.action);
		}
		catch (e) {
			console.log(e);
			this.setError(raiseError(e.error_message));
		}
	}

}

export default AddEditReceiptNote;
