import { GenericComponent,
	address_line,
	addressline,
	City,
	city,
	State,
	state,
	Country,
	country,
	zip_code,
	zipcode,
	GenericForm,
	NarrowButton,
	FormField,
	fieldValidations,
	getInitialState,
	copyDataFieldsToFormFields,
	copyFormFieldsToDataFields,
	deepCopy,
	Modal,
	Backdrop,
	raiseError
} from 'WebUI_Framework';

import serviceRoutines from './AddressServiceRoutines';

let selectedAddressData = 0;
const fields = {
	address_1 : { id: "address_1", name : "address_1", label: "Address:",
	class : addressline, component: address_line, mandatory: true},

	address_2 : { id: "address_2", name : "address_2", label: "Address 2:",
	class : addressline, component: address_line, mandatory: false},

	zip_code : { id: "zip_code", name : "zip_code", label: "Zip Code:",
	class : zipcode, component: zip_code, mandatory: false},

	city : { id: "city", name : "city", label: "City:",
	class : city, component: City, mandatory: true},

	state : { id: "state", name : "state", label: "State:",
	class : state, component: State, mandatory: false},

	cntry: { id: "cntry", name : "cntry", label: "Country:",
	class : country, component: Country, mandatory: false},

}

let mapping = [
	["address_1", "address_line_1"],
	["address_2", "address_line_2"],
	["zip_code", "zip_code"],
	["city", "city"],
	["state", "state"],
	["cntry", "cntry"],
	["org_id", "org_id"]
];

class EditAddress extends GenericComponent {

	constructor(props) {
		super(props);
		this.serverInstance = this.session.getServerInstance('ROC');
		this.accessToken = this.session.getAccessToken();
		this.readOnly = this.props.readOnly;
	}

	initialState = getInitialState(fields);

	state = {
		data : deepCopy(this.initialState),
	}

	componentDidMount() {
		this.getSelectedAddressData();
	}

	async getSelectedAddressData() {
		try {
			const retData = await serviceRoutines.addressdata(this.serverInstance, this.accessToken, this.props.addressId)
			if (retData !== undefined && retData !== null) {
				selectedAddressData  = retData;
				let tempObj={};
				copyDataFieldsToFormFields(mapping, selectedAddressData, tempObj);
				this.setState({data:tempObj});
			}
		}
		catch(e) {
			this.setError(raiseError(e.error_message));
		}
	}

	backHandler = (retFrom) => {
		if (retFrom !== "edit") {
			retFrom = "back";
		}

		if (this.isChildMode())
			this.props.returnToParent({retFrom:retFrom,
						arrayIndex:this.props.arrayIndex, selectData:selectedAddressData});
		else console.log("LOADED AS A PARENT");
	}

	closeModal= () => {
		this.setState({showModal:false});
	}

	render() {

		let buttons = [];
		let key = 0;
		if (this.props.readOnly) {
			this.readOnly = true;
			this.disabled = true;
		}
		let datablk = <div className="container-fluid pt-0 m-0 pe-2">
			<div className="row  p-0 m-0 g-0 " >
			{
				!this.readOnly ? (
					<div className="col-sm-12 h6 text-left">
						{this.getBreadcrumb([window.getLabelLiteral("EDIT")])}
					</div>
				):
					<div className="col-sm-12 h6 text-left">
						{this.getBreadcrumb([window.getLabelLiteral("VIEW")])}
					</div>
			}

			<div className="col-sm-6" >
				<FormField field={fields.address_1} controlProps={this.controlProps} readOnly={this.readOnly}/>
			</div>

			<div className="col-sm-6" >
				<FormField field={fields.address_2} controlProps={this.controlProps} readOnly={this.readOnly}/>
			</div>

			<div className="col-sm-6" >
				<FormField field={fields.zip_code} controlProps={this.controlProps}   readOnly={this.readOnly} />
			</div>

			<div className="col-sm-6" >
				<FormField field={fields.city } controlProps={this.controlProps}   disabled={this.disabled} />
			</div>

			<div className="col-sm-6" >
				<FormField field={fields.cntry} controlProps={this.controlProps}  disabled={this.disabled} />
			</div>

			<div className="col-sm-6" >
				<FormField
					field={fields.state}
					controlProps={this.controlProps}
					disabled={this.disabled}
					country={this.state.data.cntry}
				/>
			</div>

			</div>
				<div className="row  p-0 m-0 g-0 " >
				<div className="col-sm-6 mt-4 d-flex justify-content-right ">
                    { ( !this.readOnly) &&
					<NarrowButton value="Update" onClick={this.submitForm} key={(++key).toString()} />
                    }
					<NarrowButton value="Back" onClick={this.backHandler} key={(++key).toString()} />
				</div>
				</div>
			{(this.state.showModal) && <>
					<Modal
						title="Update Confirmation"
						msg="Are you sure, you want to update this Address?"
						btn1 = "Confirm"
						btn2 = "Cancel"
						onClick = {this.closeModal}
						onConfirm = {this.onConfirm}
					/>
					<Backdrop />
				</>
			}
		</div>

		let form = <GenericForm datablk={datablk} buttons={buttons} controlProps={this.controlProps} {...this.props} />
		return form;
	}

	validateForm = () => {
		let r = fieldValidations(fields, this.state.data);
		if (!r.status) {
			this.setError(r);
			return false;
		}
		return true;
	}

	submitForm = () => {
		this.resetError();
		if (!this.validateForm()) {
			return;
		}
		console.log(this.state.data);
		this.setState({showModal:true});
	}

	onConfirm = async(control) => {
		copyFormFieldsToDataFields(mapping, this.state.data, selectedAddressData, fields);
		try {
			const retData = await serviceRoutines.linked_Addressmodify(this.serverInstance, this.accessToken, selectedAddressData);
			if(retData !== undefined && retData !== null) {
				this.backHandler("edit") ;
			}
			this.setState({showModal:false});
		}
		catch(e) {
			control.error = true;
			control.errorMsg = e.error_message;
		}
	}
}

export default EditAddress;
