import { getServiceDetails } from '../common/utils'
import { handleAxiosHTTPException } from "WebUI_Framework";

let purchaseorderserviceroutines = {};

purchaseorderserviceroutines.list = async (session, obj) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(
			`biop/subscriber/order/list?buyer_org_id=${obj.buyer_org_id}`,
			{
				headers: {
					"X-Auth": accessToken,
				},
				params : obj
			});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

purchaseorderserviceroutines.fetch = async (session, selectedOrderId) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(`biop/subscriber/order/fetch?order_id=${selectedOrderId}`,
			{
				headers: {
					"X-Auth": accessToken,
				},
			}
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

purchaseorderserviceroutines.fetchAmended = async (session, selectedOrderId) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(`biop/subscriber/order/fetch_for_amendment?order_id=${selectedOrderId}`,
			{
				headers: {
					"X-Auth": accessToken,
				},
			}
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

purchaseorderserviceroutines.fetchUsingNumberAndDate = async (session, orderNumber, orderDate, orgId) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
    let response;
	let params = {
		order_number : orderNumber,
		order_issue_date : orderDate,
		buyer_org_id : orgId
	};
	try {
		response = await serverInstance.get(
			`biop/subscriber/order/fetch_using_order_num_and_date`,
			{
				params,
				headers: {
					"X-Auth": accessToken,
				},
			}
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};


purchaseorderserviceroutines.delete = async (session, retData) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.put(`biop/subscriber/order/delete`, retData, {
			headers: {
				"X-Auth": accessToken,
			},
		});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

purchaseorderserviceroutines.partnerlist = async (session, parentOrgId, searchText) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(
			`/biop/subscriber/masters/partnerlist?parent_org_id=${parentOrgId}&org_name=${searchText}`,
			{
				headers: {
					"X-Auth": accessToken,
				},
			}
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

purchaseorderserviceroutines.modify = async (session, retObj_modify) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post("biop/subscriber/order/modify", retObj_modify, {
			headers: {
				"X-Auth": accessToken,
			},
		});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

purchaseorderserviceroutines.add = async (session, retObj) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post("biop/subscriber/order/add", retObj, {
			headers: {
				"X-Auth": accessToken,
			},
		});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

purchaseorderserviceroutines.approve = async (session, order) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post('/biop/subscriber/order/approve',
			order,
			{
				headers: {
					"X-Auth": accessToken
				}
			}
		);
		return response.data;
	}
	catch (e) {
		return handleAxiosHTTPException(e);
	}
}

purchaseorderserviceroutines.approveAmendment = async (session, order) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post('/biop/subscriber/order/approve_amendment',
			order,
			{
				headers: {
					"X-Auth": accessToken
				}
			}
		);
		return response.data;
	}
	catch (e) {
		return handleAxiosHTTPException(e);
	}
}

purchaseorderserviceroutines.amend = async (session, order) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post('/biop/subscriber/order/amend',
			order,
			{
				headers: {
					"X-Auth": accessToken
				}
			}
		);
		return response.data;
	}
	catch (e) {
		return handleAxiosHTTPException(e);
	}
}

purchaseorderserviceroutines.approveAmend = async (session, order) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post('/biop/subscriber/order/approve_amendment',
			order,
			{
				headers: {
					"X-Auth": accessToken
				}
			}
		);
		return response.data;
	}
	catch (e) {
		return handleAxiosHTTPException(e);
	}
}

export default purchaseorderserviceroutines;
