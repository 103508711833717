import React from 'react';
import {
    GenericComponent,
    NarrowButton,
    GenericForm,
    FormField,
    MultiRecFormField,
    MultiRec,
    MultiRecHandler,
    fieldValidations,
    getInitialState,
    copyFormFieldsToDataFields,
    copyDataFieldsToFormFields,
    deepCopy,
    raiseError,
    extractNumberStr,
    valuePresent,
    filteredselect,
    filtered_select,
    searchselect,
    search_select,
    document_number,
    documentnumber,
    input_date,
    inputdate,
    genericstring,
    generic_string,
    genericcheckbox,
    generic_checkbox,
    itemid,
    item_id,
    genericdecimal,
    generic_decimal,
    genericgenrefcode,
    generic_genrefcode,
    //genericselect,
    //generic_select,
    amounttextbox,
    amount_textbox,
    unit_measure,
    unitmeasure,
    taxtype,
    tax_type,
    FileSet,
    file_set,
    place_holder,
    placeholder,
    Currency,
    currency,
} from 'WebUI_Framework';

import drCrNoteServiceRoutines from "./DrCrNoteServiceRoutines";
import commonServiceRoutines from "../common/CommonServiceRoutines";
import PartnerList from '../common/PartnerList'
import Attachments from '../common/Attachments'

const LINE_ITEMS_MREC = "LINE_ITEMS";
const TAX_MREC = "TAX";

const header_fields = {
    document_number: {
        //id: "document_number", name: "document_number", label: window.getLabelLiteralWithColon("DOCUMENT_NUMBER"),
        id: "document_number", name: "document_number", 
        class: documentnumber, component: document_number, mandatory: true
    },
    document_date: {
        //id: "document_date", name: "document_date", label: window.getLabelLiteralWithColon("DOCUMENT_DATE"),
        id: "document_date", name: "document_date",
        class: inputdate, component: input_date, mandatory: true
    },
    dr_org_id: {
        id: "dr_org_id", name: "dr_org_id", label: window.getLabelLiteralWithColon("DEBIT_ORG_ID"),
        class: filteredselect, component: filtered_select, mandatory: false
    },
    cr_org_id: {
        id: "cr_org_id", name: "cr_org_id", label: window.getLabelLiteralWithColon("CREDIT_ORG_ID"),
        class: filteredselect, component: filtered_select, mandatory: false
    },
    dr_org_name: {
        id: "dr_org_name", name: "dr_org_name", label: window.getLabelLiteralWithColon("DEBIT_ORG"),
        class: searchselect, component: search_select, mandatory: false
    },
    cr_org_name: {
        id: "cr_org_name", name: "cr_org_name", label: window.getLabelLiteralWithColon("CREDIT_ORG"),
        class: searchselect, component: search_select, mandatory: false
    },
    invoice_number: {
        id: "invoice_number", name: "invoice_number", label: window.getLabelLiteralWithColon("INVOICE_NUMBER"),
        class: genericstring, component: generic_string, mandatory: false
    },
    invoice_date: {
        id: "invoice_date", name: "invoice_date",
        label: window.getLabelLiteralWithColon("INVOICE_DATE"), class: inputdate, component: input_date, mandatory: false
    },
    seller_org_id: {
        id: "seller_org_id", name: "seller_org_id", label: window.getLabelLiteralWithColon("SELLER_ORG_ID"),
        class: genericgenrefcode, component: generic_genrefcode, mandatory: false
    },
    seller_org_name: {
        id: "seller_org_name", name: "seller_org_name", label: window.getLabelLiteralWithColon("SELLER_ORG"),
        class: searchselect, component: search_select, mandatory: false
    },
    reason: {
        id: "reason", name: "reason", label: window.getLabelLiteralWithColon("REASON"),
        class: genericstring, component: generic_string, mandatory: false
    },
    currency: {
        id: "currency", name: "currency", label: window.getLabelLiteralWithColon("CURRENCY"),
        class: Currency, component: currency, mandatory: true,
    },
    place_holder: {
        id: "place_holder", name: "place_holder", label: "",
        class: placeholder, component: place_holder, mandatory: false
    },
    attachments: {
        id: "attachments", name: "attachments", label: window.getLabelLiteralWithColon("ATTACHMENTS"),
        class: file_set, component: FileSet, mandatory: false,
    },
    total_tax_amount: {
        id: "total_tax_amount", name: "total_tax_amount", label: "", class: amounttextbox,
        component: amount_textbox, mandatory: false,
    },
    total_amount: {
        id: "total_amount", name: "total_amount", label: "", class: amounttextbox,
        component: amount_textbox, mandatory: false,
    },
    amount: {
        id: "amount", name: "amount", label: "", class: amounttextbox,
        component: amount_textbox, mandatory: false,
    },
}

let header_mapping = [
    ["document_number", "document_number"],
    ["document_date", "document_date"],
    ["dr_org_id", "dr_org_id"],
    ["cr_org_id", "cr_org_id"],
    ["dr_org_name", "dr_org_name"],
    ["cr_org_name", "cr_org_name"],
    ["invoice_number", "invoice_number"],
    ["invoice_date", "invoice_date"],
    ["seller_org_id", "seller_org_id"],
    ["reason", "reason"],
    ["currency", "currency"],
    ["attachments", "attachments"],
];

const details_fields = {
    seller_item_id: {
        id: "seller_item_id", name: "seller_item_id", label: window.getLabelLiteralWithColon("SELLER_ITEM_ID"),
        class: itemid, component: item_id, mandatory: true
    },
    unit_of_measure: {
        id: "unit_of_measure", name: "unit_of_measure", label: window.getLabelLiteralWithColon("UNIT_OF_MEASURE"),
        class: unitmeasure, component: unit_measure, mandatory: true
    },
    rate_per_unit: {
        id: "rate_per_unit", name: "rate_per_unit", label: window.getLabelLiteralWithColon("RATE_PER_UNIT"), class: amounttextbox,
        component: amount_textbox, mandatory: true,
    },
    quantity: {
        id: "quantity", name: "quantity", label: window.getLabelLiteralWithColon("QUANTITY"),
        class: genericdecimal, component: generic_decimal, mandatory: true
    },
    seller_item_description: {
        id: "seller_item_description", name: "seller_item_description", label: window.getLabelLiteralWithColon("DESCRIPTION"),
        class: genericstring, component: generic_string, mandatory: false
    },
    reason: {
        id: "reason", name: "reason", label: window.getLabelLiteralWithColon("DELIVERY_NOTE_REMARKS"),
        class: genericstring, component: generic_string, mandatory: false
    },
    amount: {
        id: "amount", name: "amount", label: window.getLabelLiteralWithColon("AMOUNT"), class: amounttextbox,
        component: amount_textbox, mandatory: false,
    },
    deleted: {
        id: "deleted", name: "deleted", label: window.getLabelLiteralWithColon("DELETED"),
        class: genericcheckbox, component: generic_checkbox, mandatory: false
    }

};

let details_mapping = [
    ["rate_per_unit", "rate_per_unit"],
    ["seller_item_id", "seller_item_id"],
    ["quantity", "quantity"],
    ["unit_of_measure", "unit_of_measure"],
    ["seller_item_description", "seller_item_description"],
    ["currency", "currency"],
    ["reason", "reason"],
    ["deleted", "deleted"],
];

const tax_fields = {
    tax_type: {
        id: "tax_type", name: "tax_type", label: window.getLabelLiteralWithColon("TAX_TYPE"),
        class: taxtype, component: tax_type, mandatory: false,
    },

    tax_percentage: {
        id: "tax_percentage", name: "tax_percentage", label: window.getLabelLiteralWithColon("TAX_PERCENTAGE"),
        class: genericdecimal, component: generic_decimal, mandatory: false,
    },

    tax_amount: {
        id: "tax_amount", name: "tax_amount", label: window.getLabelLiteralWithColon("TAX_AMOUNT"),
        class: amounttextbox, component: amount_textbox, mandatory: false,
    },
};

let tax_mapping = [
    ["tax_type", "tax_type"]
    , ["tax_percentage", "tax_percentage"]
    , ["tax_amount", null]
];

const TotalAmountContext = React.createContext({ tta: 0, tia: 0, ta: 0 });

class TotalAmount extends React.Component {

    render() {
        let total = this.context;

        const { atype, ...rest } = this.props;

        return <FormField valueSuperseed={total[atype].toString()}  {...rest} />
    }
}

TotalAmount.contextType = TotalAmountContext;

class AddEditDrCrNote extends GenericComponent {
    constructor(props) {
        super(props);
        this.state.loading = true;
        this.state.step = 1;
        this.state.invoiceDetailsPresent = false;
        this.readOnly = false;
        this.subscriberServerInstance = this.session.getServerInstance('SUBSCRIBER');
        this.accessToken = this.session.getAccessToken();
        this.action = props.action;
        this.documentId = props.documentId;
        this.document_owner_org_id = props.document_owner_org_id;
        this.drCrNote = { header: {}, details: [{}] };
        this.logged_in_company_id = this.session.getLoggedInCompanyId();
        this.logged_in_company_name = this.session.getLoggedInCompanyName();
        this.parent_company_id = this.session.getLoggedInParentCompanyId();
        this.parent_company_name = this.session.getLoggedInParentCompanyName();
        this.is_subscriber = (this.logged_in_company_id === this.parent_company_id);
        this.page_title = "";
        this.page_title += (this.action === "DELETE") ? window.getLabelLiteral("DELETING") : window.getLabelLiteral(this.action);
        if (this.action === "ADD") {
            this.state.data.document_date = this.session.getCurrentDate();
        }
        else if (this.action === "EDIT") {
            this.state.step = 2;
        }
        else if (this.action === "APPROVE" || this.action === "ACCEPT" || this.action === 'REJECT') {
            this.state.step = 2;
            this.readOnly = true;
        }
        else if (this.action === "VIEW") {
            this.readOnly = true;
            this.state.step = 2;
        }
        else if (this.action === "DELETE") {
            this.readOnly = true;
        }
        if (props.type === "D") {
            this.page_title += " " + window.getLabelLiteral("DEBIT_NOTE_TITLE");
        }
        else if (props.type === "C") {
            this.page_title += " " + window.getLabelLiteral("CREDIT_NOTE_TITLE");
        }

        this.seller_orgs = {};

        this.lineItemsHandler = new MultiRecHandler(this.session, details_fields, details_mapping, LINE_ITEMS_MREC);

        this.tax_mrecs = {};
        this.seller_orgs_c = 0;

    }

    initialState = getInitialState(header_fields);

    state = {
        data: deepCopy(this.initialState),
    }

    backHandler = (retFrom, error) => {
        if (this.isChildMode())
            this.props.returnToParent({
                retFrom, arrayIndex: this.props.arrayIndex,
                selectData: this.drCrNote, error
            });
        else console.log("LOADED AS A PARENT");
    }

    fetch = async (documentId) => {
        return await drCrNoteServiceRoutines.fetch(this.session, documentId);
    }

    fetchRcvd = async (documentId) => {
        return await drCrNoteServiceRoutines.fetchRcvd(this.session, documentId);
    }

    delete = async (dr_cr_note) => {
        let retData;
        try {
            await drCrNoteServiceRoutines.delete(this.session, dr_cr_note.header);
        }
        catch (e) {
            //this.setError(raiseError(e.error_message));
            throw e;
        }

        return retData
    }

    componentDidMount = async () => {
        let newData_1, newData_2, newData;
        try {
            /*eslint no-fallthrough: 0*/
            switch (this.action) {
                case 'ADD':
                    newData = deepCopy(this.state.data);
                    newData = this.lineItemsHandler.dataArrayToFormFields(this.drCrNote.details, newData);
                    this.drCrNote.header.document_owner_org_id = this.logged_in_company_id;
                    newData.document_owner_org_id = this.logged_in_company_id;
                    if (this.props.type === "D") {
                        this.drCrNote.header.cr_org_id = this.logged_in_company_id;
                        newData.cr_org_id = this.logged_in_company_id;
                        newData.cr_org_name = this.logged_in_company_name;
                    }
                    else if (this.props.type === "C") {
                        this.drCrNote.header.dr_org_id = this.logged_in_company_id;
                        newData.dr_org_id = this.logged_in_company_id;
                        newData.dr_org_name = this.logged_in_company_name;
                    }
                    this.setState({ loading: false, data: newData });
                    break;
                case 'EDIT':
                case 'VIEW':
                case 'APPROVE':
                case 'ACCEPT':
                case 'REJECT':
                    console.log(this.document_owner_org_id);
                    if (this.document_owner_org_id === this.session.getLoggedInCompanyId()) {
                        this.drCrNote = await this.fetch(this.documentId);
                    }
                    else {
                        this.drCrNote = await this.fetchRcvd(this.documentId)
                    }

                    newData_1 = deepCopy(this.state.data);
                    copyDataFieldsToFormFields(header_mapping, this.drCrNote.header, newData_1);
                    newData_2 = this.lineItemsHandler.dataArrayToFormFields(this.drCrNote.details, newData_1);
                    for (let i = 0; i < this.drCrNote.details.length; i++) {
                        if (valuePresent(this.drCrNote.details[i].tax_details)) {
                            this.tax_mrecs[TAX_MREC + i] = new MultiRecHandler(this.session, tax_fields, tax_mapping, TAX_MREC + i);
                            newData_2 = this.tax_mrecs[TAX_MREC + i].dataArrayToFormFields(this.drCrNote.details[i].tax_details, newData_2);
                        }
                    }
                    this.setState({ loading: false, data: newData_2 });
                    break;
                case 'DELETE':
                    this.drCrNote = await this.fetch(this.documentId);
                    newData_1 = deepCopy(this.state.data);
                    copyDataFieldsToFormFields(header_mapping, this.drCrNote.header, newData_1);
                    newData_2 = this.lineItemsHandler.dataArrayToFormFields(this.drCrNote.details, newData_1);
                    this.setState({ data: newData_2 });
                    if (this.drCrNote.deleted === true) {
                        this.setError(raiseError(window.getLabelLiteral("RECORD_DELETED") + " [" + this.documentId + "]"));
                        this.backHandler(this.action);
                    }
                    await this.delete(this.drCrNote);
                    this.backHandler(this.action);
                    break;
                default:
                    this.setError(raiseError("DrCrNote: Invalid action [" + this.action + "]"));
                    this.backHandler(this.action, this.error);
                    break;
            }
        }
        catch (e) {
            console.log(this.action);
            console.log(e);
            this.setError(raiseError((e.error_message) ? e.error_message : e.message));
            this.backHandler(this.action, this.error);
        }
    }

    low_computeAmount = (data, i) => {
        let ras = data[this.lineItemsHandler.getFieldIdentifier("rate_per_unit", i)];
        if (!ras) ras = "";
        const rate = Number(extractNumberStr(ras));

        let qs = data[this.lineItemsHandler.getFieldIdentifier("quantity", i)];
        if (!qs) qs = "";
        const quantity = Number(extractNumberStr(qs));

        return rate * quantity;
    }

    low_computeTaxAmount = (ia, data, i, detail_i) => {
        let tps = data[this.tax_mrecs[TAX_MREC + detail_i].getFieldIdentifier("tax_percentage", i)];
        if (!tps) tps = "";
        const tp = Number(extractNumberStr(tps));
        const ta = (ia * tp) / 100;

        return ta;
    }

    computeTotalAmount = () => {
        let ta = 0;
        let tia = 0;
        let tta = 0;

        try {
            const dc = this.lineItemsHandler.getCount(this.state.data);
            for (let i = 0; i < dc; i++) {
                const ia = this.low_computeAmount(this.state.data, i);
                ta += ia;
                tia += ia;
                if (this.tax_mrecs[TAX_MREC + i]) {
                    const tc = this.tax_mrecs[TAX_MREC + i].getCount(this.state.data);
                    for (let j = 0; j < tc; j++) {
                        const xa = (this.low_computeTaxAmount(ia, this.state.data, j, i));
                        ta += xa;
                        tta += xa;
                    }
                }
            }
        } catch (e) {
            console.log(e);
            ta = 0;
            tta = 0;
            tia = 0;
        }

        return { ta, tta, tia };
    }

    addNewRow = () => {
        let data = this.state.data;
        this.resetError();
        let [newData, new_list] = this.lineItemsHandler.addMultiRecRow(data, this.drCrNote.details);
        this.drCrNote.details = new_list;
        this.setState({ data: newData });
    }

    button_inputs = [
        {
            literal: "Add",
            func: () => this.addNewRow()
        }
    ]

    deleteRow = (rowData, i) => {
        if (this.drCrNote.details[i].version === null ||
            this.drCrNote.details[i].version === undefined) {
            this.resetError();
            let [newData, updated_list] = this.lineItemsHandler.deleteMultiRecRow(this.state.data, this.drCrNote.details, i);
            this.drCrNote.details = updated_list;
            this.setState({ data: newData });
        }
        else {
            this.drCrNote.details[i].deleted = true;
        }
    }

    computeAmount = (data, i) => {
        let rate;
        try {
            rate = Number(extractNumberStr(data[this.lineItemsHandler.getFieldIdentifier("rate_per_unit", i)].trim(), true));
        } catch (e) {
            console.log(i);
            console.log(data);
            console.log(e);
            return "";
        }
        let quantity = Number(data[this.lineItemsHandler.getFieldIdentifier("quantity", i)]);
        if (isNaN(quantity)) return "";

        return (rate * quantity).toString();
    }

    deleteTaxRow = (rowData, i, detail_i) => {
        this.resetError();
        let [newData, updated_list] =
            this.tax_mrecs[TAX_MREC + detail_i].deleteMultiRecRow(this.state.data, this.drCrNote.details[detail_i].tax_details, i);
        this.drCrNote.details[detail_i].tax_details = updated_list;
        if (this.drCrNote.details[detail_i].tax_details.length === 0) {
            this.drCrNote.details[detail_i].tax_details = undefined;
            delete this.tax_mrecs[TAX_MREC + i];
        }

        this.setState({ data: newData });
    }

    getTaxHeader = () => {
        let key = 0;
        return (
            <tr key={++key}>
                <th key={++key}>{window.getLabelLiteral("TAX_TYPE")}</th>
                <th key={++key}>{window.getLabelLiteral("TAX_PERCENTAGE")}</th>
                <th key={++key}>{window.getLabelLiteral("TAX_AMOUNT")}</th>
                {(!this.readOnly) &&
                    <th key={++key}>{window.getLabelLiteral("DELETE")}</th>
                }
            </tr>
        );
    }

    computeTaxAmount = (drCrNoteAmount, data, i, detail_i) => {
        let ta = 0
        try {
            const ia = Number(extractNumberStr(drCrNoteAmount));
            const tp = Number(extractNumberStr(data[this.tax_mrecs[TAX_MREC + detail_i].getFieldIdentifier("tax_percentage", i)]));
            ta = (ia * tp) / 100;
        } catch (e) {
            /* We ignore any errors here */
            console.log(e);
        }

        return ta;
    }

    getTaxRow = (rowData, index, detail_i, drCrNoteAmount) => {
        const i = index - 1;
        let data = deepCopy(this.state.data);
        let key = 0;
        let style = { borderBottom: "0px solid" };

        const ca = (this.computeTaxAmount(drCrNoteAmount, data, i, detail_i)).toString();

        const taMarginLeft = (this.readOnly) ? "" : "0.75rem";

        let r = <React.Fragment key={index}>
            <tr>
                <td key={++key} style={style}>
                    <MultiRecFormField field={tax_fields.tax_type} controlProps={this.controlProps} index={i}
                        multiRecId={TAX_MREC + detail_i}
                        w_pcnt="w-100"
                        style={{ width: "100%" }}
                        readOnly={this.readOnly} />
                </td>
                <td key={++key} style={style}>
                    <MultiRecFormField field={tax_fields.tax_percentage} controlProps={this.controlProps} index={i}
                        formattingInputs={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
                        multiRecId={TAX_MREC + detail_i}
                        style={{ width: "100%", textAlign: "right", marginLeft: "1rem" }}
                        readOnly={this.readOnly} />
                </td>
                <td key={++key} style={style}>
                    <MultiRecFormField field={tax_fields.tax_amount} controlProps={this.controlProps} index={i}
                        currency={data.currency}
                        valueSuperseed={ca}
                        multiRecId={TAX_MREC + detail_i}
                        style={{ width: "100%", textAlign: "right", marginLeft: taMarginLeft }}
                        readOnly={true} />
                </td>
                {!(this.readOnly) &&
                    <td key={++key} style={style}>
                        <div style={{ paddingLeft: "20px", cursor: "pointer" }}>
                            <span onClick={() => { return this.deleteTaxRow(rowData, i, detail_i); }}>
                                <i className="bi bi-trash3" style={{ fontSize: 20 }}></i>
                            </span>
                        </div>
                    </td>
                }
            </tr>
        </React.Fragment>;

        return r;
    }

    addTax = (i) => {
        let data = deepCopy(this.state.data);
        if (!(this.drCrNote.details[i].tax_details)) {
            this.drCrNote.details[i].tax_details = [];
            this.tax_mrecs[TAX_MREC + i] = new MultiRecHandler(this.session, tax_fields, tax_mapping, TAX_MREC + i);
        }

        let [newData, new_list] = this.tax_mrecs[TAX_MREC + i].addMultiRecRow(data, this.drCrNote.details[i].tax_details);
        this.drCrNote.details[i].tax_details = new_list;
        this.setState({ data: newData });
    }

    getHeader = () => {
        let key = 0;
        return (
            <tr key={++key}>
                <th key={++key}>{window.getLabelLiteral("SELLER_ITEM_ID")}</th>
                <th key={++key}>{window.getLabelLiteral("UNIT_OF_MEASURE")}</th>
                {(this.props.type === 'D') ?
                <th key={++key}>{window.getLabelLiteral("DEBIT_PER_UNIT")}</th>
                    :
                <th key={++key}>{window.getLabelLiteral("CREDIT_PER_UNIT")}</th>
                }
                <th key={++key}>{window.getLabelLiteral("QUANTITY")}</th>
                <th key={++key}>{window.getLabelLiteral("SELLER_ITEM_DESCRIPTION")}</th>
                <th key={++key}>{window.getLabelLiteral("REASON")}</th>
                <th key={++key}>{window.getLabelLiteral("AMOUNT")}</th>
                {(!this.readOnly) &&
                    <>
                        <th key={++key}>{window.getLabelLiteral("DELETE")}</th>
                        <th key={++key}>{window.getLabelLiteral("ACTIONS")}</th>
                    </>
                }
            </tr>
        );
    }

    getRow = (data, index) => {
        const i = index - 1;
        let delete_button = 1;
        if (data.version >= 0)
            delete_button = 2;

        let view_delete_option = 0;
        if (!this.readOnly && delete_button === 1) view_delete_option = 1;
        else if (!this.readOnly && delete_button === 2) view_delete_option = 2;
        let key = 0;

        let data1 = deepCopy(this.state.data);
        const ca = this.computeAmount(data1, i);
        let style = valuePresent(this.drCrNote.details[i].tax_details) ? { borderBottom: "0px solid" } : undefined;

        let r =
            <>
                <tr key={++index}>
                    <td>
                        <MultiRecFormField field={details_fields.seller_item_id}
                            controlProps={this.controlProps} index={i}
                            readOnly={(this.readOnly)}
                            multiRecId={LINE_ITEMS_MREC}
                            style={{ width: "100%" }}
                        />
                    </td>
                    <td>
                        <MultiRecFormField field={details_fields.unit_of_measure}
                            controlProps={this.controlProps} index={i}
                            readOnly={(this.readOnly)}
                            multiRecId={LINE_ITEMS_MREC}
                            style={{ width: "100%" }}
                        />
                    </td>
                    <td>
                        <MultiRecFormField field={details_fields.rate_per_unit}
                            currency={data1.currency}
                            controlProps={this.controlProps} index={i}
                            readOnly={(this.readOnly)}
                            multiRecId={LINE_ITEMS_MREC}
                            style={{ width: "100%" }}
                        />
                    </td>
                    <td key={++key} style={style}>
                        <MultiRecFormField field={details_fields.quantity} controlProps={this.controlProps} index={i}
                            multiRecId={LINE_ITEMS_MREC}
                            formattingInputs={{ minimumFractionDigits: 0, maximumFractionDigits: 6 }}
                            style={{ width: "65%", textAlign: "right" }} readOnly={this.readOnly} />
                    </td>
                    <td>
                        <MultiRecFormField field={details_fields.seller_item_description}
                            controlProps={this.controlProps} index={i}
                            readOnly={(this.readOnly)}
                            multiRecId={LINE_ITEMS_MREC}
                            style={{ width: "100%" }}
                        />
                    </td>
                    <td>
                        <MultiRecFormField field={details_fields.reason}
                            controlProps={this.controlProps} index={i}
                            readOnly={(this.readOnly)}
                            multiRecId={LINE_ITEMS_MREC}
                            style={{ width: "100%" }}
                        />
                    </td>
                    <td key={++key} style={style}>
                        <MultiRecFormField field={details_fields.amount} controlProps={this.controlProps} index={i}
                            currency={data1.currency}
                            value={ca}
                            multiRecId={LINE_ITEMS_MREC}
                            style={{ width: "100%", textAlign: "right" }}
                            readOnly={true} />
                    </td>
                    {(view_delete_option === 1) &&
                        <>
                            <td>
                                <div className="mt-0" style={{ cursor: "pointer" }}>
                                    <span onClick={() => { return this.deleteRow(data, i); }}>
                                        <i className="bi bi-trash3" style={{ padding: "1rem", fontSize: 20 }}></i>
                                    </span>
                                </div>
                            </td>
                        </>
                    }
                    {(view_delete_option === 2) &&
                        <>
                            <td>
                                <div className="ml-0 mt-1" style={{ paddingLeft: "30px" }}>
                                    <MultiRecFormField field={details_fields.deleted} controlProps={this.controlProps}
                                        multiRecId={LINE_ITEMS_MREC}
                                        style={{ paddingLeft: "12px" }} index={i} disabled={this.readOnly} />
                                </div>
                            </td>
                        </>
                    }
                    {(!this.readOnly) &&
                        <>
                            <td key={++key} style={style}>
                                <div style={{ cursor: "pointer" }}>
                                    <span onClick={() => { return this.addTax(i); }} data-toggle="tooltip" title="Tax">
                                        <i className="bi bi-plus-circle" style={{ fontSize: 20 }}></i>
                                    </span>
                                </div>
                            </td>
                        </>

                    }
                </tr>
                {(valuePresent(this.drCrNote.details[i].tax_details) && this.drCrNote.details[i].tax_details.length > 0) &&
                    <tr key={++key}>
                        <td key={++key} colSpan={4}></td>
                        <td key={++key} colSpan={3} className="m-0 p-0">
                            <MultiRec key={++key} tableClass={"table table-condensed"}
                                getRow={(rowdata, index, pn, k, ainfo) => { return this.getTaxRow(rowdata, index, i, ainfo); }}
                                getHeader={this.getTaxHeader}
                                data={this.drCrNote.details[i].tax_details}
                                buttons={null}
                                tbodyStyle={{ borderTop: "2px solid", borderColor: "LightGray" }}
                                additionalInfo={ca}
                            />
                        </td>
                    </tr>
                }
            </>
        return r;
    }

    getFooter = () => {
        const style1 = { borderBottom: "0px", margin: "0px" };
        const style2 = { borderBottom: "0px", textAlign: "right", margin: "0px" };
        let key = 0;
        return <React.Fragment>
            <tr key={++key}>
                <th key={++key} colSpan="6" style={style1}></th>
                <th key={++key} colSpan="1" style={style2}>
                    {window.getLabelLiteral("AMOUNT")}
                </th>
                <th key={++key} colSpan="1" style={style1}>
                    <TotalAmount
                        atype="tia"
                        field={header_fields.amount}
                        style={{ width: "100%", textAlign: "right", fontWeight: "bold" }}
                        currency={this.state.data.currency}
                        controlProps={this.controlProps}
                        readOnly={true}
                    />
                </th>
                <th key={++key} style={style1} />
                <th key={++key} style={style1} />
            </tr>
            <tr key={++key}>
                <th key={++key} colSpan="6" style={style1}></th>
                <th key={++key} colSpan="1" style={{ borderBottom: "1px solid", textAlign: "right" }}>
                    {window.getLabelLiteral("TAX_AMOUNT")}
                </th>
                <th key={++key} colSpan="1" style={{ borderBottom: "1px solid" }}>
                    <TotalAmount
                        atype="tta"
                        field={header_fields.total_tax_amount}
                        style={{ width: "100%", textAlign: "right", fontWeight: "bold" }}
                        currency={this.state.data.currency}
                        controlProps={this.controlProps}
                        readOnly={true}
                    />
                </th>
                <th key={++key} style={style1} />
                <th key={++key} style={style1} />
            </tr>
            <tr key={++key}>
                <th key={++key} colSpan="6" style={style1}></th>
                <th key={++key} colSpan="1" style={style2}>
                    {window.getLabelLiteral("TOTAL_AMOUNT")}
                </th>
                <th key={++key} colSpan="1"
                    style={style1}>
                    <TotalAmount
                        atype="ta"
                        field={header_fields.total_amount}
                        style={{ width: "100%", textAlign: "right", fontWeight: "bold" }}
                        currency={this.state.data.currency}
                        controlProps={this.controlProps}
                        readOnly={true}
                    />
                </th>
                <th key={++key} style={style1} />
                <th key={++key} style={style1} />
            </tr>
        </React.Fragment>;
    }

    partnerSelectOption = (org_id, org_name) => {
        this.seller_orgs_c ++;
        this.seller_orgs = {
            [org_id] : org_name,
            [this.logged_in_company_id] : this.logged_in_company_name
        };
        this.setState(prevState => {
            if (this.props.type === "D") {
                prevState.data.dr_org_name = org_name;
                prevState.data.dr_org_id = org_id;
                if (org_id !== prevState.data.dr_org_id) {
                    prevState.data.seller_org_id = '';
                    prevState.data.seller_org_name = '';
                }
            } else {
                prevState.data.cr_org_name = org_name;
                prevState.data.cr_org_id = org_id;
                if (org_id !== prevState.data.cr_org_id) {
                    prevState.data.seller_org_id = '';
                    prevState.data.seller_org_name = '';
                }
            }
            this.setSellerOrgId(prevState);
            return prevState;
        });
    }

    validateDrCrorganisation = () => {
        let data = deepCopy(this.state.data);
        let r = {};
        r.status = true;
        r.ret = [];
        if (this.props.type === "D") {
            if (data.dr_org_name === '' || data.dr_org_id === '') {
                let err = {}
                err.status = false;
                err.id = header_fields.dr_org_name.id;
                err.msg = err.id + ": must be filled";
                r.ret.push(err);

            }
        }
        else if (this.props.type === "C") {
            if (data.cr_org_name === '' || data.cr_org_id === '') {
                let err = {}
                err.status = false;
                err.id = header_fields.cr_org_name.id;
                err.msg = err.id + ": must be filled";
                r.ret.push(err);
            }
        }

        if (this.state.invoiceDetailsPresent) {
            if (data.invoice_number === '' ) {
                let err = {}
                err.status = false;
                err.id = header_fields.invoice_number.id;
                err.msg = header_fields.invoice_number.label + " must be filled";
                r.ret.push(err);
            }
            if (data.invoice_date === '' ) {
                let err = {}
                err.status = false;
                err.id = header_fields.invoice_date.id;
                err.msg = header_fields.invoice_date.label + " must be filled";
                r.ret.push(err);
            }
            if (data.seller_org_id === '' ) {
                let err = {}
                err.status = false;
                err.id = header_fields.seller_org_id.id;
                err.msg = header_fields.seller_org_id.label + " must be filled";
                r.ret.push(err);
            }
        }
        if (r.ret.length > 0) {
            r.status = false;
        }
        return r;
    }

    proceed = async () => {
        this.resetError();
        let r = fieldValidations(header_fields, this.state.data);
        if (!r.status) {
            this.setError(r);
            return false;
        }
        r = this.validateDrCrorganisation()
        if (!r.status) {
            this.setError(r);
            return false;
        }

        if (this.state.invoiceDetailsPresent) {
            try {
                const r = await commonServiceRoutines.fetchInvoice(this.session, 
                    this.state.data.invoice_number,
                    this.state.data.invoice_date,
                    this.state.data.seller_org_id
                );
                this.invoice = r.invoice;
                this.ownedInvoice = r.owned;
                this.drCrNote.details = [];
                for (let i = 0; i < this.invoice.details.length; i++) {
                    let item = {};
                    for (const name in details_fields) {
                        if (valuePresent(this.invoice.details[i][name])) {
                            item[name] = this.invoice.details[i][name];
                        }
                    }
                    //delete item.quantity;
                    if (valuePresent(this.invoice.details[i].tax_details)) {
                        for (let j = 0; j < this.invoice.details[i].tax_details.length; j++) {
                            let taxItem = {};
                            taxItem.tax_type = this.invoice.details[i].tax_details.length[j].tax_type;
                            taxItem.tax_percentage = this.invoice.details[i].tax_details.length[j].tax_percentage;
                            item.tax_details[j] = taxItem;
                        }
                    }
                    this.drCrNote.details.push(item);
                }
                let newData_1 = deepCopy(this.state.data);
                let newData_2 = this.lineItemsHandler.dataArrayToFormFields(this.drCrNote.details, newData_1);
                for (let i = 0; i < this.drCrNote.details.length; i++) {
                    if (valuePresent(this.drCrNote.details[i].tax_details)) {
                        this.tax_mrecs[TAX_MREC + i] = new MultiRecHandler(this.session, tax_fields, tax_mapping, TAX_MREC + i);
                        newData_2 =
                            this.tax_mrecs[TAX_MREC + i].dataArrayToFormFields(this.drCrNote.details[i].tax_details, newData_2);
                    }
                }
                this.setState({ data: newData_2 });
            } catch (e) {
            }
        }
        this.setState({ step: 2 });
    }

    setSellerOrgId = (state) => {
        if ((state.data.invoice_number) && state.data.invoice_number !== '') {
            state.invoiceDetailsPresent = true;
            if (this.props.type === "D") {
                state.data.seller_org_id = state.data.dr_org_id;
            }
            else {
                state.data.seller_org_id = state.data.cr_org_id;
            }
        }
        else {
            state.invoiceDetailsPresent = false;
            state.data.seller_org_id = '';
        }
        return state;
    }

    onBlurInvoiceNumber = () => {
        this.setState((prevState) => {
            this.setSellerOrgId(prevState);
            return prevState;
        });
    }

    data_render = () => {
        const props = this.props;
        let buttons = [];
        let key = 0;
        let datablk = <div className="container-fluid pt-0 m-0 pe-2">
            <div className="row  p-0 m-0 g-0 " >
                <div className="col-sm-12 h6 text-left" >{this.getBreadcrumb([window.getLabelLiteral(this.action)])}</div>

                <div className="col-sm-6" >
                    <PartnerList
                        selectOption={this.partnerSelectOption}
                        field={(this.props.type === "D") ? header_fields.dr_org_id : header_fields.cr_org_id}
                        controlProps={this.controlProps}
                        relationship='B'
                        readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
                    />
                </div>
                <div className="col-sm-6" >
                    <FormField field={header_fields.place_holder} controlProps={this.controlProps} readOnly={this.readOnly} />
                </div>
                <div className="col-sm-6" >
                    <FormField field={header_fields.document_date} controlProps={this.controlProps}
                        label={(props.type === 'D')?window.getLabelLiteral("DR_NOTE_DATE"):window.getLabelLiteral("CR_NOTE_DATE")}
                        readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))} />
                </div>
                <div className="col-sm-6" >
                    <FormField field={header_fields.document_number} controlProps={this.controlProps}
                        label={(props.type === 'D')?window.getLabelLiteral("DR_NOTE_NUMBER"):window.getLabelLiteral("CR_NOTE_NUMBER")}
                        readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))} />
                </div>
                <div className="col-sm-6" >
                    <FormField field={header_fields.invoice_number} controlProps={this.controlProps}
                        onBlur={this.onBlurInvoiceNumber}
                        readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
                    />
                </div>
                <div className="col-sm-6" >
                    <FormField field={header_fields.invoice_date} controlProps={this.controlProps}
                        readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
                    />
                </div>
                {(this.state.invoiceDetailsPresent) &&
                    <div className="col-sm-6" >
                        <FormField field={header_fields.seller_org_id} controlProps={this.controlProps}
                            readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD") ||
                                (this.state.data.dr_org_id === '' && this.props.type === 'D') ||
                                (this.state.data.cr_org_id === '' && this.props.type === 'C')

                            )}
                            refData={this.seller_orgs}
                            dependency={[this.state.data.buyer_org_id, this.seller_orgs_c]}
                        />
                    </div>
                }
                <div className="col-sm-6" >
                    <FormField field={header_fields.reason} controlProps={this.controlProps}
                        readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
                    />
                </div>
                <div className="col-sm-6" >
                    <FormField field={header_fields.currency} controlProps={this.controlProps}
                        readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
                    />
                </div>

                <div className="col-sm-6 mt-2 mb-3 ">
                    <Attachments
                        field={header_fields.attachments}
                        controlProps={this.controlProps}
                        readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
                    />
                </div>
                {(!this.state.invoiceDetailsPresent) &&
                    <div className="col-sm-6" >
                        <FormField field={header_fields.place_holder} controlProps={this.controlProps} readOnly={this.readOnly} />
                    </div>
                }
                {(this.state.step === 1) &&
                    <div className="col-sm-6 mt-4 d-flex justify-content-right ">
                        <NarrowButton value="Proceed" onClick={this.proceed} />
                        <NarrowButton value="Back" onClick={() => { return this.backHandler("BACK"); }} key={(++key).toString()} />
                    </div>
                }

                {(this.state.step === 2) &&
                    <div className='col-sm-12'>
                        <div style={{ margin: 0, padding: 0, height: "25px" }}></div>
                        <MultiRec getRow={this.getRow} getHeader={this.getHeader}
                            data={this.drCrNote.details} buttons={(this.readOnly) ? null : this.button_inputs} />
                    </div>
                }
            </div>

            {(this.state.step === 2) &&
                <div className="row  p-0 m-0 g-0 " >
                    <div className="col-sm-6 mt-4 d-flex justify-content-right ">
                        {!(this.readOnly) &&
                            <NarrowButton value="Submit" onClick={this.submitForm} key={(++key).toString()} />
                        }
                        {(this.readOnly && (this.action === "APPROVE")) &&
                            <NarrowButton value="Approve" onClick={this.submitForm} key={(++key).toString()} />
                        }
                        {(this.readOnly && (this.action === "ACCEPT")) &&
                            <NarrowButton value="Accept" onClick={this.submitForm} key={(++key).toString()} />
                        }
                        {(this.readOnly && (this.action === "REJECT")) &&
                            <NarrowButton value="Reject" onClick={this.submitForm} key={(++key).toString()} />
                        }
                        <NarrowButton value="Back" onClick={() => { return this.backHandler("BACK"); }} key={(++key).toString()} />
                    </div>
                </div>
            }
        </div>

        let form = <GenericForm datablk={datablk} buttons={buttons} controlProps={this.controlProps} {...this.props} />
        return form;
    }

    loading_render = () => {
        let buttons = [];

        let datablk = <div className="container pt-0 m-0 ">
            <div className="row  p-0 m-0 g-0 " >
                {/*<div className="col-sm-12 h4 text-center" >{this.page_title}</div>*/}
                <div className="d-flex justify-content-evenly">
                    <div className="spinner-border text-dark" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>

        </div>

        let form = <GenericForm datablk={datablk} buttons={buttons} controlProps={this.controlProps} {...this.props} />
        return form;
    }

    render = () => {
        if (this.state.loading) {
            return this.loading_render();
        }
        else {
            return this.data_render();
        }
    }

    validateForm = () => {
        let r = fieldValidations(header_fields, this.state.data);
        if (!r.status) {
            console.log(r);
            this.setError(r);
            return false;
        }
        r = this.lineItemsHandler.multiRecFieldValidations(this.state.data);
        if (!r.status) {
            console.log(r);
            this.setState({ error: r });
            return false;
        }

        return true;
    }

    submitForm = async () => {
        this.resetError();
        if (!this.validateForm()) {
            return;
        }


        let data = deepCopy(this.state.data);
        copyFormFieldsToDataFields(header_mapping, data, this.drCrNote.header, header_fields);
        this.drCrNote.header.dr_cr_ind = this.props.type;
        this.lineItemsHandler.formFieldsToDataArray(this.state.data, this.drCrNote.details);
        for (let i = 0; i < this.drCrNote.details.length; i++) {
            if (this.drCrNote.details[i].tax_details) {
                this.tax_mrecs[TAX_MREC + i].formFieldsToDataArray(data, this.drCrNote.details[i].tax_details);
            }
        }


        try {
            switch (this.action) {
                case "ADD":
                    await drCrNoteServiceRoutines.add(this.session, this.drCrNote);
                    this.drCrNote =
                        await drCrNoteServiceRoutines.fetchUsingNumberAndDate(this.session,
                            this.drCrNote.header.document_number,
                            this.drCrNote.header.document_date,
                            this.session.getLoggedInCompanyId());

                    break;
                case "EDIT":
                    await drCrNoteServiceRoutines.modify(this.session, this.drCrNote);
                    this.drCrNote = await this.fetch(this.documentId);
                    break;
                case "ACCEPT":
                    await drCrNoteServiceRoutines.accept(this.session, this.drCrNote.header);
                    this.drCrNote = await this.fetchRcvd(this.documentId);
                    break;
                case "REJECT":
                    await drCrNoteServiceRoutines.reject(this.session, this.drCrNote.header);
                    this.drCrNote = await this.fetchRcvd(this.documentId);
                    break;
                case "APPROVE":
                    await drCrNoteServiceRoutines.approve(this.session, this.drCrNote);
                    this.drCrNote = await this.fetch(this.documentId);
                    break;
                default:
                    break;
            }

            this.backHandler(this.action);
        }
        catch (e) {
            console.log(e);
            this.setError(raiseError(e.error_message));
        }
    }

}


export default AddEditDrCrNote;
