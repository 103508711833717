import { handleAxiosHTTPException } from "WebUI_Framework";

let partnershiprequestsserviceroutine = {};

partnershiprequestsserviceroutine.list = async(serverInstance, accessToken, offset, recordsPerFrame, receiverOrgId) =>{
    let response;
    try {
        response = await serverInstance.get('/biop/subscriber/relationship_request/list',
            {
                headers: {
                    "X-Auth": accessToken
                },
                params : {
                    offset: offset,
                    num_recs: recordsPerFrame,
                    receiver_org_id : receiverOrgId,
                    type : '2',
                    status: '2'
                }
            }
        );

        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}

partnershiprequestsserviceroutine.fetch = async(serverInstance, accessToken, request_id) =>{
    let response;
    try {
        response = await serverInstance.get('/biop/subscriber/relationship_request/fetch',
            {
                headers: {
                    "X-Auth": accessToken
                },
                params : {
                    request_id : request_id
                }
            }
        );

        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}

partnershiprequestsserviceroutine.accept = async(serverInstance, accessToken, obj) =>{
    let response;
    try {
        response = await serverInstance.put('/biop/subscriber/relationship_request/accept',
        obj,
            {
                headers: {
                    "X-Auth": accessToken
                },
            }
        );

        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}

partnershiprequestsserviceroutine.reject = async(serverInstance, accessToken, obj) =>{
    let response;
    try {
        response = await serverInstance.put('/biop/subscriber/relationship_request/reject',
        obj,
            {
                headers: {
                    "X-Auth": accessToken
                },
            }
        );

        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}

export default partnershiprequestsserviceroutine;