import React from 'react';
import {
	GenericComponent,
	NarrowButton,
	GenericForm,
	FormField,

	MultiRecFormField,
	MultiRecHandler,
	file_set,
	FileSet,

	fieldValidations,
	getInitialState,
	copyFormFieldsToDataFields,
	copyDataFieldsToFormFields,
	deepCopy,
	raiseError,
	extractNumberStr,

	documentnumber,
	document_number,
	inputdate,
	input_date,
	user_name,
	username,
	emailid,
	email_id,
	contactphonenumber,
	contact_phone_number,
	generic_string,
	genericstring,
	filteredselect,
	filtered_select,
	searchselect,
	search_select,
	generic_integer,
	genericinteger,
	genericcheckbox,
	generic_checkbox,
	genericgenrefcode,
	generic_genrefcode,
	currency,
	Currency,
	item_id,
	itemid,
	amounttextbox,
	amount_textbox,
	genericdecimal,
	generic_decimal,
	unit_measure,
	unitmeasure,
	MultiRec,
	place_holder,
	placeholder,

} from 'WebUI_Framework';

import './AddEditPurchaseOrder.css';

import purchaseOrderServiceRoutines from "./purchaseOrderServiceRoutines";
import commonServiceRoutines from "../common/CommonServiceRoutines";
import PartnerList from '../common/PartnerList'
//import AddressList from '../common/AddressList'
import AddressFilter from '../common/AddressFilter'
import Attachments from '../common/Attachments'
import { formatAddress } from '../common/utils'

const LINE_ITEMS_MREC = "LINE_ITEMS";

const header_fields = {
	order_number: {
		id: "order_number", name: "order_number", label: "PO Number:",
		class: documentnumber, component: document_number, mandatory: true
	},

	order_issue_date: {
		id: "order_issue_date", name: "order_issue_date",
		label: "PO Issue Date:", class: inputdate, component: input_date, mandatory: true
	},

	order_contact_user_name: {
		id: "order_contact_user_name", name: "order_contact_user_name",
		label: "Contact Person:", class: username, component: user_name, mandatory: true
	},

	order_contact_email_id: {
		id: "order_contact_email_id", name: "order_contact_email_id",
		label: "Contact Email:", class: emailid, component: email_id, mandatory: true
	},

	order_contact_phone_num: {
		id: "order_contact_phone_num", name: "order_contact_phone_num",
		label: "Contact Phone:", class: contactphonenumber, component: contact_phone_number, mandatory: true
	},

	order_valid_from_date: {
		id: "order_valid_from_date", name: "order_valid_from_date",
		label: "PO Valid From:", class: inputdate, component: input_date, mandatory: true
	},

	order_valid_to_date: {
		id: "order_valid_to_date", name: "order_valid_to_date",
		label: "PO Valid Till:", class: inputdate, component: input_date, mandatory: true
	},

	description: {
		id: "description", name: "description", label: "Description:",
		class: genericstring, component: generic_string, mandatory: true
	},

	seller_org_id: {
		id: "seller_org_id", name: "seller_org_id", label: "Seller Id:",
		class: filteredselect, component: filtered_select, mandatory: true
	},

	seller_org_name: {
		id: "seller_org_name", name: "seller_org_name", label: "Company Name:",
		class: genericstring, component: generic_string, mandatory: false
	},

	seller_address_id: {
		id: "seller_address_id", name: "seller_address_id", label: "Seller Address Id:",
		class: filteredselect, component: filtered_select, mandatory: true
	},

	seller_address: {
		id: "seller_address", name: "seller_address",
		label: "Address:", class: searchselect, component: search_select, mandatory: false
	},

	seller_contact_user_name: {
		id: "seller_contact_user_name", name: "seller_contact_user_name",
		label: "Contact Person:", class: username, component: user_name, mandatory: true
	},

	seller_contact_email_id: {
		id: "seller_contact_email_id", name: "seller_contact_email_id",
		label: "Contact Email:", class: emailid, component: email_id, mandatory: true
	},

	seller_contact_phone_num: {
		id: "seller_contact_phone_num", name: "seller_contact_phone_num",
		label: "Contact Phone:", class: contactphonenumber, component: contact_phone_number, mandatory: true
	},

	notes: {
		id: "notes", name: "notes", label: "Notes:",
		class: genericstring, component: generic_string, mandatory: false
	},

	billing_address_id: {
		id: "billing_address_id", name: "billing_address_id", label: "Billing Address Id:",
		//class: genericstring, component: generic_string, mandatory: false
		class: filteredselect, component: filtered_select, mandatory: true
	},

	billing_address: {
		id: "billing_address", name: "billing_address",
		label: "Address:", class: searchselect, component: search_select, mandatory: false
	},

	billing_contact_user_name: {
		id: "billing_contact_user_name", name: "billing_contact_user_name",
		label: "Contact Person:", class: username, component: user_name, mandatory: false
	},

	billing_contact_email_id: {
		id: "billing_contact_email_id", name: "billing_contact_email_id",
		label: "Contact Email:", class: emailid, component: email_id, mandatory: false
	},

	billing_contact_phone_num: {
		id: "billing_contact_phone_num", name: "billing_contact_phone_num",
		label: "Contact Phone:", class: contactphonenumber, component: contact_phone_number, mandatory: false
	},


	delivery_address_id: {
		id: "delivery_address_id", name: "delivery_address_id", label: "Delivery Address Id:",
		//class: genericstring, component: generic_string, mandatory: false
		class: filteredselect, component: filtered_select, mandatory: true
	},

	delivery_address: {
		id: "delivery_address", name: "delivery_address",
		label: "Address:", class: searchselect, component: search_select, mandatory: false
	},

	delivery_contact_user_name: {
		id: "delivery_contact_user_name", name: "delivery_contact_user_name",
		label: "Contact Person:", class: username, component: user_name, mandatory: false
	},

	delivery_contact_email_id: {
		id: "delivery_contact_email_id", name: "delivery_contact_email_id",
		label: "Contact Email:", class: emailid, component: email_id, mandatory: false
	},

	delivery_contact_phone_num: {
		id: "delivery_contact_phone_num", name: "delivery_contact_phone_num",
		label: "Contact Phone:", class: contactphonenumber, component: contact_phone_number, mandatory: false
	},

	payment_period: {
		id: "payment_period", name: "payment_period", label: "Payment period:",
		class: genericinteger, component: generic_integer, mandatory: false,
		comandatory_with: ["payment_period_unit"]
	},

	payment_period_unit: {
		id: "payment_period_unit", name: "payment_period_unit", label: "Payment period unit:",
		class: genericgenrefcode, component: generic_genrefcode, mandatory: false,
		comandatory_with: ["payment_period"]
	},

	currency: {
		id: "currency", name: "currency", label: "Currency:",
		class: Currency, component: currency, mandatory: true
	},

	attachments: {
		id: "attachments",
		name: "attachments",
		label: window.getLabelLiteralWithColon("ATTACHMENTS"),
		class: file_set,
		component: FileSet,
		mandatory: false,
		//initialValue: "1234244.00",
	},

	place_holder: {
		id: "place_holder", name: "place_holder", label: "",
		class: placeholder, component: place_holder, mandatory: false
	},

}

let header_mapping = [
	["order_number", "order_number"],
	["order_issue_date", "order_issue_date"],
	["order_contact_user_name", "order_contact_user_name"],
	["order_contact_email_id", "order_contact_email_id"],
	["order_contact_phone_num", "order_contact_phone_num"],
	["order_valid_from_date", "order_valid_from_date"],
	["order_valid_to_date", "order_valid_to_date"],
	["seller_org_id", "seller_org_id"],
	["buyer_org_id", "buyer_org_id"],
	["seller_org_name", "seller_org_name"],
	["seller_address_id", "seller_address_id"],
	["seller_contact_user_name", "seller_contact_user_name"],
	["seller_contact_email_id", "seller_contact_email_id"],
	["seller_contact_phone_num", "seller_contact_phone_num"],
	["delivery_address_id", "delivery_address_id"],
	["delivery_contact_user_name", "delivery_contact_user_name"],
	["delivery_contact_email_id", "delivery_contact_email_id"],
	["delivery_contact_phone_num", "delivery_contact_phone_num"],
	["description", "description"],
	["notes", "notes"],
	["billing_address_id", "billing_address_id"],
	["billing_contact_user_name", "billing_contact_user_name"],
	["billing_contact_email_id", "billing_contact_email_id"],
	["billing_contact_phone_num", "billing_contact_phone_num"],
	["payment_period", "payment_period"],
	["payment_period_unit", "payment_period_unit"],
	["currency", "currency"],
	["attachments", "attachments"]
];

const details_fields = {
	seller_item_id: {
		id: "seller_item_id", name: "seller_item_id", label: "Item Id:",
		class: itemid, component: item_id, mandatory: true
	},
	seller_item_description: {
		id: "seller_item_description", name: "seller_item_description", label: "Seller Id Description:",
		class: genericstring, component: generic_string, mandatory: true
	},
	quantity: {
		id: "quantity", name: "quantity", label: "Quantity:",
		class: genericdecimal, component: generic_decimal, mandatory: true
	},

	amount: {
		id: "amount", name: "amount", label: "Amount:",
		class: amounttextbox, component: amount_textbox, mandatory: false
	},

	rate_per_unit: {
		id: "rate_per_unit", name: "rate_per_unit", label: "Rate per Unit:",
		class: amounttextbox, component: amount_textbox, mandatory: true
	},

	unit_of_measure: {
		id: "unit_of_measure", name: "unit_of_measure", label: "Unit:",
		class: unitmeasure, component: unit_measure, mandatory: true
	},

	item_delivery_from_date: {
		id: "item_delivery_from_date", name: "item_delivery_from_date", label: "Delivery From Date:",
		class: inputdate, component: input_date, mandatory: true
	},

	item_delivery_to_date: {
		id: "item_delivery_to_date", name: "item_delivery_to_date", label: "Delivery To Date:",
		class: inputdate, component: input_date, mandatory: true
	},

	notes: {
		id: "notes", name: "notes", label: "Notes:",
		class: genericstring, component: generic_string, mandatory: false
	},

	order_serial_id: {
		id: "order_serial_id",
		name: "order_serial_id",
		label: "Order Serial Id",
		class: genericinteger,
		component: generic_integer,
		mandatory: false,
	},
	
	deleted: {
		id: "deleted", 
		name: "deleted", 
		label: window.getLabelLiteralWithColon("DELETED"),
		class: genericcheckbox, 
		component: generic_checkbox, 
		mandatory: false
	},


}

let details_mapping = [
	["seller_item_id", "seller_item_id"]
	,["seller_item_description", "seller_item_description"]
	,["quantity", "quantity"]
	,["rate_per_unit", "rate_per_unit"]
	,["unit_of_measure", "unit_of_measure"]
	,["notes", "notes"]
	,["item_delivery_from_date", "item_delivery_from_date"]
	,["item_delivery_to_date", "item_delivery_to_date"]
	,["order_serial_id", "order_serial_id"]
	,["deleted", "deleted"]
	,["amount", null]
];

class AddEditPurchaseOrder extends GenericComponent {
	constructor(props) {
		super(props);
		this.state.loading = true;
		this.state.step = 1;

		this.readOnly = false;
		this.subscriberServerInstance = this.session.getServerInstance('SUBSCRIBER');
		this.accessToken = this.session.getAccessToken();
		this.action = props.action;
		this.orderId = props.orderId;
		this.order = { order_header: {}, order_line_item: [{}] };
		this.logged_in_company_id = this.session.getLoggedInCompanyId();
		this.parent_company_id = this.session.getLoggedInParentCompanyId();
		this.is_subscriber = (this.logged_in_company_id === this.parent_company_id);
		this.page_title = "";
		this.page_title += (this.action === "DELETE") ? window.getLabelLiteral("DELETING") : window.getLabelLiteral(this.action);
		this.expandVendorDetails(false);
		this.expandBillingDetails(false);
		this.expandDeliveryDetails(false);
		if (this.action === "ADD") {
			this.state.data.order_issue_date = this.session.getCurrentDate();
			this.expandVendorDetails(true);
			this.expandBillingDetails(true);
			this.expandDeliveryDetails(true);
		}
		else if (this.action === "EDITORDER" || this.action === "EDIT" || this.action === "AMEND") {
			this.state.step = 2;
		}
		else if (this.action === "ACCEPTQUOTATION") {
			this.expandVendorDetails(true);
			this.expandBillingDetails(true);
			this.expandDeliveryDetails(true);
			this.order = props.order
			this.order.order_header.order_issue_date = this.session.getCurrentDate();
			this.state.step = 1;
		}
		else if (this.action === "APPROVE" || this.action === "CANCEL" ||
			this.action === "ACCEPT" || this.action === 'REJECT' ||
			this.action === 'APPROVEAMENDMENT') {
			this.expandVendorDetails(true);
			this.expandBillingDetails(true);
			this.expandDeliveryDetails(true);
			this.state.step = 2;
			this.readOnly = true;
		}
		else if (this.action === "VIEW" || this.action === "VIEWORDER") {
			this.expandVendorDetails(true);
			this.expandBillingDetails(true);
			this.expandDeliveryDetails(true);
			this.readOnly = true;
			this.state.step = 2;
		}
		else if (this.action === "DELETE") {
			this.readOnly = true;
		}
		if (this.action !== "EDITORDER" && this.action !== "VIEWORDER")
			this.page_title += " " + window.getLabelLiteral("ORDER_TITLE");

		this.lineItemsHandler = new MultiRecHandler(this.session, details_fields, details_mapping, LINE_ITEMS_MREC);

	}

	expandVendorDetails = (flg) => {
		this.aria_expanded_vendor = flg;
		this.show_vendor = (this.aria_expanded_vendor) ? "show" : "";
		this.aria_collapsed_vendor = (this.aria_expanded_vendor) ? "expanded" : "collapsed";
	}

	expandBillingDetails = (flg) => {
		this.aria_expanded_billing = flg;
		this.show_billing = (this.aria_expanded_billing) ? "show" : "";
		this.aria_collapsed_billing = (this.aria_expanded_billing) ? "expanded" : "collapsed";
	}

	expandDeliveryDetails = (flg) => {
		this.aria_expanded_delivery = flg;
		this.show_delivery = (this.aria_expanded_delivery) ? "show" : "";
		this.aria_collapsed_delivery = (this.aria_expanded_delivery) ? "expanded" : "collapsed";
	}

	initialState = getInitialState(header_fields);

	state = {
		data: deepCopy(this.initialState),
	}

	backHandler = (retFrom, error) => {
		if (this.isChildMode())
			this.props.returnToParent({
				retFrom, arrayIndex: this.props.arrayIndex,
				selectData: this.order, error
			});
		else console.log("LOADED AS A PARENT");
	}

	fetch = async (orderId) => {
		return await purchaseOrderServiceRoutines.fetch(this.session, orderId);
	}

	fetchAmended = async (orderId) => {
		return await purchaseOrderServiceRoutines.fetchAmended(this.session, orderId);
	}

	delete = async (order) => {
		let retData;
        console.log(order);
		try {
			await purchaseOrderServiceRoutines.delete(this.session, order.order_header);
		}
		catch (e) {
			//this.setError(raiseError(e.error_message));
			throw e;
		}

		return retData
	}

	loadVendorAddress = (data) => {
		data.seller_address = formatAddress(this.order.order_header.seller_address);
	}

	loadBillingAddress = (data) => {
		data.billing_address = formatAddress(this.order.order_header.billing_address);
	}

	loadDeliveryAddress = (data) => {
		data.delivery_address = formatAddress(this.order.order_header.delivery_address);
	}

	populateDefaultInformation = async (data) => {
		try {
			const buyerDetails = await commonServiceRoutines.fetchCompany(this.session, this.session.getLoggedInCompanyId());


			if (data.billing_contact_user_name === "")
				data.billing_contact_user_name = buyerDetails.contact_name;
			if (data.billing_contact_email_id === "")
				data.billing_contact_email_id = buyerDetails.contact_email_id;
			if (data.billing_contact_phone_num === "")
				data.billing_contact_phone_num = buyerDetails.contact_phone_number;
			if (data.delivery_contact_user_name === "")
				data.delivery_contact_user_name = buyerDetails.contact_name;
			if (data.delivery_contact_email_id === "")
				data.delivery_contact_email_id = buyerDetails.contact_email_id;
			if (data.delivery_contact_phone_num === "")
				data.delivery_contact_phone_num = buyerDetails.contact_phone_number;
			if (data.order_contact_user_name === "")
				data.order_contact_user_name = buyerDetails.contact_name;
			if (data.order_contact_email_id === "")
				data.order_contact_email_id = buyerDetails.contact_email_id;
			if (data.order_contact_phone_num === "")
				data.order_contact_phone_num = buyerDetails.contact_phone_number;

			const buyerAddress = await commonServiceRoutines.fetchDefaultAddress(this.session, this.session.getLoggedInCompanyId());

			if (data.billing_address_id === "")
				data.billing_address_id = buyerAddress.address_id;
			if (data.billing_address === "")
				data.billing_address = formatAddress(buyerAddress);
			if (data.delivery_address_id === "")
				data.delivery_address_id = buyerAddress.address_id;
			if (data.delivery_address === "")
				data.delivery_address = formatAddress(buyerAddress);

		} catch (e) {
			console.log(this.action);
			console.log(e);
			this.setError(raiseError((e.error_message) ? e.error_message : e.message));
			this.backHandler(this.action, this.error);
		}
		return;
	}

	componentDidMount = async () => {
		let newData_1, newData_2, newData;
		try {
			switch (this.action) {
				case 'ACCEPTQUOTATION':
					newData = deepCopy(this.state.data);
					copyDataFieldsToFormFields(header_mapping, this.order.order_header, newData);
					newData = this.lineItemsHandler.dataArrayToFormFields(this.order.order_line_item, newData);

					this.loadVendorAddress(newData);
					this.loadBillingAddress(newData);
					this.loadDeliveryAddress(newData);

					this.setState({ loading: false, data: newData });
					break;
				case 'ADD':
					newData = deepCopy(this.state.data);
					newData = this.lineItemsHandler.dataArrayToFormFields(this.order.order_line_item, newData);
					this.order.order_header.buyer_org_id = this.logged_in_company_id;
					newData.buyer_org_id = this.logged_in_company_id;
					await this.populateDefaultInformation(newData);
					this.setState({ loading: false, data: newData });
					break;
				/*eslint no-fallthrough: 0*/
				case 'AMEND':
				case 'CANCEL':
				case 'ACCEPT':
				case 'REJECT':
				case 'VIEW':
				case 'VIEWORDER':
				case 'APPROVE':
				case 'EDIT':
				case 'EDITORDER':
				case 'DELETE':
				case 'APPROVEAMENDMENT':
					switch (this.action) {
						/*eslint no-fallthrough: 0*/
						case 'AMEND':
						case 'CANCEL':
						case 'ACCEPT':
						case 'REJECT':
						case 'APPROVEAMENDMENT':
							this.order = await this.fetchAmended(this.orderId);
							break;
						default:
							this.order = await this.fetch(this.orderId);
							break;
					}

					newData_1 = deepCopy(this.state.data);
					copyDataFieldsToFormFields(header_mapping, this.order.order_header, newData_1);
					newData_2 = this.lineItemsHandler.dataArrayToFormFields(this.order.order_line_item, newData_1);
					switch (this.action) {
						case 'DELETE':
							this.setState({ data: newData_2 });
							if (this.order.order_header.deleted === true) {
								this.setError(raiseError(window.getLabelLiteral("RECORD_DELETED") + " [" + this.orderId + "]"));
								this.backHandler(this.action);
							}
							await this.delete(this.order);
							this.backHandler(this.action);
							break;
						case 'EDIT' : 
						case 'EDITORDER' : 
						//issue
						default:
							this.loadVendorAddress(newData_2);
							this.loadBillingAddress(newData_2);
							this.loadDeliveryAddress(newData_2);

							this.setState({ loading: false, data: newData_2 });
							break;
					}

					break;
				default:
					this.setError(raiseError("AddEditPurchaseOrder: Invalid action [" + this.action + "]"));
					this.backHandler(this.action, this.error);
					break;
			}
		}
		catch (e) {
			console.log(this.action);
			console.log(e);
			this.setError(raiseError((e.error_message) ? e.error_message : e.message));
			this.backHandler(this.action, this.error);
		}
	}

	addNewRow = () => {
		let data = this.state.data;
		this.resetError();
		let [newData, new_list] = this.lineItemsHandler.addMultiRecRow(data, this.order.order_line_item, {
			item_delivery_from_date: data.order_valid_from_date,
			item_delivery_to_date: data.order_valid_to_date
		});
		this.order.order_line_item = new_list;
		this.setState({ data: newData });
	}

	button_inputs = [
		{
			literal: "Add",
			func: () => this.addNewRow()
		}
	]

	deleteRow = (rowData, i) => {
		if (this.order.order_line_item[i].version === null ||
			this.order.order_line_item[i].version === undefined) {
			this.resetError();
			let [newData, updated_list] = this.lineItemsHandler.deleteMultiRecRow(this.state.data, this.order.order_line_item, i);
			this.order.order_line_item = updated_list;
			this.setState({ data: newData });
		}
		else {
			this.order.order_line_item[i].deleted = true;
		}
	}

	getHeader = () => {
		let key = 0;
		return (
			<tr key={++key}>
				<th key={++key}>{window.getLabelLiteral("SELLER_ITEM_ID")}</th>
				<th key={++key}>{window.getLabelLiteral("SELLER_ITEM_DESCRIPTION")}</th>
				<th key={++key}>{window.getLabelLiteral("FROM_DATE")}</th>
				<th key={++key}>{window.getLabelLiteral("TO_DATE")}</th>
				<th key={++key}>{window.getLabelLiteral("QUANTITY")}</th>
				<th key={++key}>{window.getLabelLiteral("UNIT_OF_MEASURE")}</th>
				<th key={++key}>{window.getLabelLiteral("RATE")}</th>
				<th key={++key}>{window.getLabelLiteral("NET_AMOUNT")}</th>
			</tr>
		);
	}

	computeAmount = (data, i) => {
		let rate;
		try {
			rate = Number(
				extractNumberStr(data[this.lineItemsHandler.getFieldIdentifier("rate_per_unit", i)].trim(), true));
		} catch (e) {
			console.log(e);
			return "";
		}
		let quantity = Number(data[this.lineItemsHandler.getFieldIdentifier("quantity", i)]);
		if (isNaN(quantity)) return "";

		return (rate * quantity).toString();
	}

	getRow = (rowData, index) => {
		const i = index - 1;

		let delete_button = 1;
		if (rowData.version >= 0)
			delete_button = 2;

		let view_delete_option = 0;
		if (!this.readOnly && delete_button === 1) view_delete_option = 1;
		else if (!this.readOnly && delete_button === 2) view_delete_option = 2;

		let data = deepCopy(this.state.data);
		const ca = this.computeAmount(data, i);

		let key = 0;
		let r = <React.Fragment key={index}>
			<tr key={++key}>
				<td key={++key}>
					<MultiRecFormField field={details_fields.seller_item_id} controlProps={this.controlProps} index={i}
						multiRecId={LINE_ITEMS_MREC}
						style={{ width: "75%" }}
						readOnly={this.readOnly} />
				</td>
				<td key={++key}>
					<MultiRecFormField field={details_fields.seller_item_description} controlProps={this.controlProps} index={i}
						multiRecId={LINE_ITEMS_MREC}
						style={{width:"95%"}}
						readOnly={this.readOnly} />
				</td>
				<td key={++key}>
					<MultiRecFormField field={details_fields.item_delivery_from_date} controlProps={this.controlProps} index={i}
						multiRecId={LINE_ITEMS_MREC}
						style={{ width: "85%" }} disabled={this.readOnly} />
				</td>
				<td key={++key}>
					<MultiRecFormField field={details_fields.item_delivery_to_date} controlProps={this.controlProps} index={i}
						multiRecId={LINE_ITEMS_MREC}
						style={{ width: "85%" }} disabled={this.readOnly} />
				</td>
				<td key={++key}>
					<MultiRecFormField field={details_fields.quantity} controlProps={this.controlProps} index={i}
						multiRecId={LINE_ITEMS_MREC}
						formattingInputs={{ minimumFractionDigits: 0, maximumFractionDigits: 6 }}
						style={{ width: "60%", textAlign:"right"}} readOnly={this.readOnly} />
				</td>
				<td key={++key}>
					<MultiRecFormField field={details_fields.unit_of_measure} controlProps={this.controlProps} index={i}
						multiRecId={LINE_ITEMS_MREC}
						style={{width:"100%"}}
						disabled={this.readOnly} />
				</td>
				<td key={++key}>
					<MultiRecFormField field={details_fields.rate_per_unit} controlProps={this.controlProps} index={i}
						multiRecId={LINE_ITEMS_MREC}
						currency={this.state.data.currency}
						readOnly={this.readOnly} />
				</td>
				<td key={++key}>
					<MultiRecFormField field={details_fields.amount} controlProps={this.controlProps} index={i}
						valueSuperseed={ca}
						multiRecId={LINE_ITEMS_MREC}
						currency={this.state.data.currency}
						readOnly={true} />
				</td>
				<td key={++key}>
					{(view_delete_option === 1) &&
						<div style={{ paddingLeft: "20px", cursor: "pointer" }}>
							<span onClick={() => { return this.deleteRow(rowData, i); }} data-toggle="tooltip" title="Delete">
								<i className="bi bi-trash3" style={{ fontSize: 20 }}></i>
							</span>
						</div>
					}
					{(view_delete_option === 2) &&
						<div className="ml-2" style={{ paddingTop: "4px", paddingLeft: "20px" }}>
							<MultiRecFormField field={details_fields.deleted} controlProps={this.controlProps}
								multiRecId={LINE_ITEMS_MREC}
								style={{}} index={i} disabled={this.readOnly} />
						</div>
					}
				</td>
			</tr>
		</React.Fragment>
		return r;
	}

	populateSellerOrgInfo = async (data) => {
		const sellerDetails = await commonServiceRoutines.fetchCompany(this.session, data.seller_org_id);

		data.seller_org_name = sellerDetails.org_name;
		data.seller_contact_user_name = sellerDetails.contact_name;
		data.seller_contact_email_id = sellerDetails.contact_email_id;
		data.seller_contact_phone_num = sellerDetails.contact_phone_number;

		const sellerAddress = await commonServiceRoutines.fetchDefaultAddress(this.session, data.seller_org_id);

		data.seller_address_id = sellerAddress.address_id;
		data.seller_address = formatAddress(sellerAddress);
	}


	partnerSelectOption = async (org_id, org_name) => {
		let data = deepCopy(this.state.data)
		data.seller_org_name = org_name;
		data.seller_org_id = org_id;

		if (org_id !== undefined && org_id !== null && org_id !== '')
			await this.populateSellerOrgInfo(data);
		this.setState({ data });
	}

	selectAddressIdSeller = (address_id, formatted_address, address) => {
		let data = deepCopy(this.state.data);
		data.seller_address = formatted_address;
		data.seller_address_id = address_id;
		this.setState({ data });
	}

	clearSelectionSellerAddress = () => {
		let data = deepCopy(this.state.data);
		data.seller_address = ''
		data.seller_address_id = ''
		this.setState({ data });
	}

	selectAddressIdBilling = (address_id, formatted_address, address) => {
		let data = deepCopy(this.state.data);
		data.billing_address = formatted_address;
		data.billing_address_id = address_id;
		this.setState({ data });
	};

	clearSelectionBillingAddress = () => {
		let data = deepCopy(this.state.data);
		data.billing_address = ''
		data.billing_address_id = ''
		this.setState({ data });
	}

	selectAddressIdDelivery = (address_id, formatted_address, address) => {
		let data = deepCopy(this.state.data);
		data.delivery_address = formatted_address;
		data.delivery_address_id = address_id;
		this.setState({ data });
	};

	clearSelectionDeliveryAddress = () => {
		let data = deepCopy(this.state.data);
		data.delivery_address = ''
		data.delivery_address_id = ''
		this.setState({ data });
	}


	proceed = async () => {
		this.resetError();
		let r = fieldValidations(header_fields, this.state.data);
		if (!r.status) {
			console.log(r)
			this.setError(r);
			return false;
		}

		let data = deepCopy(this.state.data);
		this.expandVendorDetails(false);
		this.expandBillingDetails(false);
		this.expandDeliveryDetails(false);

		this.order.order_line_item[0].item_delivery_from_date = data.order_valid_from_date
		this.order.order_line_item[0].item_delivery_to_date = data.order_valid_to_date
		data = this.lineItemsHandler.dataArrayToFormFields(this.order.order_line_item, data);

		this.setState({ step: 2, data });
	}

	data_render = () => {
		let key = 0;

		let datablk = (
			<div className="container-fluid border border-0 pt-0 m-0 ">
				<div className="row	p-0 m-0 g-0 ">
					<div className="col-sm-12 h6 text-left">
						{this.getBreadcrumb([window.getLabelLiteral(this.action)])}
					</div>
					<hr></hr>
					<div className="col-sm-6">
						<FormField
							field={header_fields.order_number}
							controlProps={this.controlProps}
							readOnly={((this.readOnly) || (this.state.step > 1) ||
								(this.action !== "ADD" && this.action !== "ACCEPTQUOTATION"))}
						/>
					</div>
					<div className="col-sm-6">
						<FormField
							field={header_fields.order_issue_date}
							controlProps={this.controlProps}
							readOnly={((this.readOnly) || (this.state.step > 1) ||
								(this.action !== "ADD" && this.action !== "ACCEPTQUOTATION"))}
						/>
					</div>
					<div className="col-sm-6">
						<FormField
							field={header_fields.description}
							controlProps={this.controlProps}
							readOnly={this.readOnly}
						/>
					</div>
					<div className="col-sm-6">
						<FormField
							field={header_fields.currency}
							controlProps={this.controlProps}
							readOnly={((this.readOnly) || (this.state.step > 1) ||
								(this.action !== "ADD" && this.action !== "ACCEPTQUOTATION"))}
						/>
					</div>
					<div className="col-sm-6">
						<FormField
							field={header_fields.order_valid_from_date}
							controlProps={this.controlProps}
							readOnly={((this.readOnly) || (this.state.step > 1) ||
								(this.action !== "ADD" && this.action !== "ACCEPTQUOTATION"))}
						/>
					</div>
					<div className="col-sm-6">
						<FormField
							field={header_fields.order_valid_to_date}
							style={{}}
							controlProps={this.controlProps}
							readOnly={this.readOnly}
						/>
					</div>
					<div className="col-sm-6">
						<FormField
							field={header_fields.order_contact_user_name}
							controlProps={this.controlProps}
							readOnly={((this.readOnly) || (this.state.step > 1) ||
								(this.action !== "ADD" && this.action !== "ACCEPTQUOTATION"))}
						/>
					</div>
					<div className="col-sm-6">
						&nbsp;
					</div>
					<div className="col-sm-6">
						<FormField
							field={header_fields.order_contact_email_id}
							controlProps={this.controlProps}
							readOnly={((this.readOnly) || (this.state.step > 1) ||
								(this.action !== "ADD" && this.action !== "ACCEPTQUOTATION"))}
						/>
					</div>
					<div className="col-sm-6">
						<FormField
							field={header_fields.order_contact_phone_num}
							controlProps={this.controlProps}
							readOnly={((this.readOnly) || (this.state.step > 1) ||
								(this.action !== "ADD" && this.action !== "ACCEPTQUOTATION"))}
						/>
					</div>
					<div className="col-sm-6">
						<FormField
							field={header_fields.payment_period}
							style={{textAlign:"left"}}
							controlProps={this.controlProps}
							readOnly={this.readOnly}
						/>
					</div>
					<div className="col-sm-6">
						<FormField
							field={header_fields.payment_period_unit}
							controlProps={this.controlProps}
							readOnly={this.readOnly}
							refType={"PeriodUnitType"}
						/>
					</div>

					<div className="col-sm-6 mt-3 mb-3 ">
						<Attachments
							field={header_fields.attachments}
							controlProps={this.controlProps}
							readOnly={this.readOnly}
						/>
					</div>

					<div className="accordion accordion-flush" id="sellerdetails">
						<div className="accordion-item">
							<h2 className="accordion-header " id="sellerdetailsdata">
								<div className="col-sm-12 h4 text-left">
									<div className="col-sm-2">
										<button className=
											{`accordion-button btn link-dark my-0 py-0 mx-0 gx-0 px-0 ${this.aria_collapsed_vendor}`}
											id="collapseOne-button"
											style={{
												color: "black",
												backgroundColor: "white",
											}}
											type="button" data-bs-toggle="collapse"
											data-bs-target="#collapseOne" aria-expanded={this.aria_expanded_vendor}
											aria-controls="collapseOne">
											<h6>Vendor Details</h6>
										</button>
									</div>
								</div>
							</h2>

							<div id="collapseOne" className={`accordion-collapse collapse ${this.show_vendor}`}
								aria-labelledby="sellerdetailsdata" data-bs-parent="#sellerdetails">
								<div className={`accordion-body mx-0 gx-0 px-0`} id="sellerdetails-1">
									<div className="row	p-0 m-0 g-0 ">
										<div className="col-sm-6" >
											<PartnerList
												field={header_fields.seller_org_id}
												selectOption={this.partnerSelectOption}
												controlProps={this.controlProps}
												relationship='S'
												readOnly={((this.readOnly) || (this.state.step > 1) ||
													(this.action !== "ADD" && this.action !== "ACCEPTQUOTATION"))}
											/>
										</div>
										<div className="col-sm-6">
											<AddressFilter
												field={header_fields.seller_address_id}
												controlProps={this.controlProps}
												orgId={this.state.data.seller_org_id}
												selectOption={this.selectAddressIdSeller}
												clearSelection={this.clearSelectionSellerAddress}
												readOnly={this.readOnly}
                                                dependency={[this.state.data.seller_org_id]}
											/>
										</div>
										<div className="col-sm-6">
											<FormField
												field={header_fields.seller_contact_user_name}
												controlProps={this.controlProps}
												readOnly={this.readOnly}
											/>
										</div>
										<div className="col-sm-6">
											<FormField
												field={header_fields.seller_contact_email_id}
												controlProps={this.controlProps}
												readOnly={this.readOnly}
											/>
										</div>
										<div className="col-sm-6">
											<FormField
												field={header_fields.seller_contact_phone_num}
												controlProps={this.controlProps}
												readOnly={this.readOnly}
											/>
										</div>
									</div>
									<div className="col-sm-12">
										&nbsp;
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="accordion accordion-flush" id="billingdetails">
						<div className="accordion-item">
							<h2 className="accordion-header " id="billingdetailsdata">
								<div className="col-sm-12 h4 text-left">
									<div className="col-sm-2">
										<button className=
											{`accordion-button btn link-dark my-0 py-0 mx-0 gx-0 px-0 ${this.aria_collapsed_billing}`}
											id="collapseTwo-button"
											style={{
												color: "black",
												backgroundColor: "white",
											}}
											type="button" data-bs-toggle="collapse"
											data-bs-target="#collapseTwo" aria-expanded={this.aria_expanded_billing}
											aria-controls="collapseTwo">
											<h6>Billing Details</h6>
										</button>
									</div>
								</div>
							</h2>
							<div id="collapseTwo" className={`accordion-collapse collapse ${this.show_billing}`}
								aria-labelledby="billingdetailsdata" data-bs-parent="#billingdetails">
								<div className={`accordion-body mx-0 gx-0 px-0`} id="billingdetails-1">
									<div className="row	p-0 m-0 g-0 ">
										<div className="col-sm-6">
											<AddressFilter
												field={header_fields.billing_address_id}
												controlProps={this.controlProps}
												orgId={this.state.data.buyer_org_id}
												selectOption={this.selectAddressIdBilling}
												clearSelection={this.clearSelectionBillingAddress}
												readOnly={this.readOnly}
                                                dependency={[this.state.data.buyer_org_id]}
											/>
										</div>
										<div className="col-sm-6">
											<FormField
												field={header_fields.billing_contact_user_name}
												controlProps={this.controlProps}
												readOnly={this.readOnly}
											/>
										</div>
										<div className="col-sm-6">
											<FormField
												field={header_fields.billing_contact_email_id}
												controlProps={this.controlProps}
												readOnly={this.readOnly}
											/>
										</div>
										<div className="col-sm-6">
											<FormField
												field={header_fields.billing_contact_phone_num}
												controlProps={this.controlProps}
												readOnly={this.readOnly}
											/>
										</div>
									</div>
									<div className="col-sm-12">
										&nbsp;
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="accordion accordion-flush" id="deliverydetails">
						<div className="accordion-item">
							<h2 className="accordion-header " id="deliverydetailsdata">
								<div className="col-sm-12 h4 text-left">
									<div className="col-sm-2">
										<button className=
											{`accordion-button btn link-dark my-0 py-0 mx-0 gx-0 px-0 ${this.aria_collapsed_delivery}`}
											id="collapseThree-button"
											style={{
												color: "black",
												backgroundColor: "white",
											}}
											type="button" data-bs-toggle="collapse"
											data-bs-target="#collapseThree" aria-expanded={this.aria_expanded_delivery}
											aria-controls="collapseThree">
											<h6>Delivery Details</h6>
										</button>
									</div>
								</div>
							</h2>
							<div id="collapseThree" className={`accordion-collapse collapse ${this.show_delivery}`}
								aria-labelledby="deliverydetailsdata" data-bs-parent="#deliverydetails">
								<div className={`accordion-body mx-0 gx-0 px-0`} id="deliverydetails-1">
									<div className="row	p-0 m-0 g-0 ">
										<div className="col-sm-6">
											<AddressFilter
												field={header_fields.delivery_address_id}
												controlProps={this.controlProps}
												orgId={this.state.data.buyer_org_id}
												selectOption={this.selectAddressIdDelivery}
												clearSelection={this.clearSelectionDeliveryAddress}
												readOnly={this.readOnly}
                                                dependency={[this.state.data.buyer_org_id]}
											/>
										</div>
										<div className="col-sm-6">
											<FormField
												field={header_fields.delivery_contact_user_name}
												controlProps={this.controlProps}
												readOnly={this.readOnly}
											/>
										</div>
										<div className="col-sm-6">
											<FormField
												field={header_fields.delivery_contact_email_id}
												controlProps={this.controlProps}
												readOnly={this.readOnly}
											/>
										</div>
										<div className="col-sm-6">
											<FormField
												field={header_fields.delivery_contact_phone_num}
												controlProps={this.controlProps}
												readOnly={this.readOnly}
											/>
										</div>
									</div>
									<div className="col-sm-12">
										&nbsp;
									</div>
								</div>
							</div>
						</div>
					</div>



					{(this.state.step === 1) &&
						<div className="col-sm-6 mt-4 d-flex justify-content-right ">
							<NarrowButton value="Proceed" onClick={this.proceed} />
							<NarrowButton value="Back" onClick={() => { return this.backHandler("BACK"); }} key={(++key).toString()} />
						</div>
					}
					{(this.state.step === 2) &&
						<div>
							<MultiRec
								key={++key}
								getRow={this.getRow}
								getHeader={this.getHeader}
								// getFooter={this.getFooter}
								data={this.order.order_line_item}
								buttons={(this.readOnly) ? null : this.button_inputs}
								tfootStyle={{ borderTop: "0.1rem solid" }}
								tbodyStyle={{ borderTop: "2.5px solid" }}
							/>
						</div>
					}
				</div>

				{(this.state.step === 2) &&
					<div className="row  p-0 m-0 g-0 " >
						<div className="col-sm-6 mt-4 d-flex justify-content-right ">
							{!(this.readOnly) &&
								<NarrowButton value="Submit" onClick={this.submitForm} key={(++key).toString()} />
							}
							{(this.readOnly && (this.action === "APPROVE")) &&
								<NarrowButton value="Approve" onClick={this.submitForm} key={(++key).toString()} />
							}
							{(this.readOnly && (this.action === "APPROVEAMENDMENT")) &&
								<NarrowButton value="Approve" onClick={this.submitForm} key={(++key).toString()} />
							}
							{(this.readOnly && (this.action === "CANCEL")) &&
								<NarrowButton value="Cancel" onClick={this.submitForm} key={(++key).toString()} />
							}
							{(this.readOnly && (this.action === "ACCEPT")) &&
								<NarrowButton value="Accept" onClick={this.submitForm} key={(++key).toString()} />
							}
							{(this.readOnly && (this.action === "REJECT")) &&
								<NarrowButton value="Reject" onClick={this.submitForm} key={(++key).toString()} />
							}
							<NarrowButton value="Back" onClick={() => { return this.backHandler("BACK"); }} key={(++key).toString()} />
						</div>
					</div>
				}
			</div>
		);

		let form = <GenericForm datablk={datablk} buttons={[]} controlProps={this.controlProps} {...this.props} />
		return form;
	}

	loading_render = () => {
		let buttons = [];

		let datablk = <div className="container border border-0 pt-0 m-0 ">
			<div className="row  p-0 m-0 g-0 " >
				<div className="col-sm-12 h4 text-center" >{this.page_title}</div>
				<div className="d-flex justify-content-evenly">
					<div className="spinner-border text-dark" role="status">
						<span className="sr-only">Loading...</span>
					</div>
				</div>
			</div>

		</div>

		let form = <GenericForm datablk={datablk} buttons={buttons} controlProps={this.controlProps} {...this.props} />
		return form;
	}

	render = () => {
		if (this.state.loading) {
			return this.loading_render();
		}
		else {
			return this.data_render();
		}
	}

	validateForm = () => {
		let r = fieldValidations(header_fields, this.state.data);
		if (!r.status) {
			console.log(r);
			this.expandVendorDetails(true);
			this.expandBillingDetails(true);
			this.expandDeliveryDetails(true);
			this.setError(r);
			return false;
		}
		r = this.lineItemsHandler.multiRecFieldValidations(this.state.data);
		if (!r.status) {
			console.log(r);
			this.setState({ error: r });
			return false;
		}

		return true;
	}

	submitForm = async () => {
		this.resetError();
		if (!this.validateForm()) {
			return;
		}

		let data = deepCopy(this.state.data);
		copyFormFieldsToDataFields(header_mapping, data, this.order.order_header, header_fields);
		this.lineItemsHandler.formFieldsToDataArray(this.state.data, this.order.order_line_item);

		try {
			switch (this.action) {
				case "ACCEPTQUOTATION":
				case "ADD":
					await purchaseOrderServiceRoutines.add(this.session, this.order);
					this.order =
						await purchaseOrderServiceRoutines.fetchUsingNumberAndDate(this.session,
							this.order.order_header.order_number,
							this.order.order_header.order_issue_date,
							this.order.order_header.buyer_org_id);
					break;
				case "EDIT":
				case "EDITORDER":
					await purchaseOrderServiceRoutines.modify(this.session, this.order);
					this.order = await this.fetch(this.orderId);
					break;
				case "AMEND":
					await purchaseOrderServiceRoutines.amend(this.session, this.order);
					this.order = await this.fetch(this.orderId);
					break;
				case "CANCEL":
					await purchaseOrderServiceRoutines.cancelAmendMent(this.session, this.order);
					this.order = await this.fetch(this.orderId);
					break;
				case "ACCEPT":
					await purchaseOrderServiceRoutines.accept(this.session, this.order);
					this.order = await this.fetch(this.orderId);
					break;
				case "REJECT":
					await purchaseOrderServiceRoutines.reject(this.session, this.order);
					this.order = await this.fetch(this.orderId);
					break;
				case "APPROVE":
					await purchaseOrderServiceRoutines.approve(this.session, this.order);
					this.order = await this.fetch(this.orderId);
					break;
				case "APPROVEAMENDMENT":
					await purchaseOrderServiceRoutines.approveAmendment(this.session, this.order);
					this.order = await this.fetch(this.orderId);
					break;
				default:
					break;
			}

			this.backHandler(this.action);
		}
		catch (e) {
			console.log(this.order);
			console.log(e);
			this.setError(raiseError(e.error_message));
		}
	}

}


export default AddEditPurchaseOrder;
