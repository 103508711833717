import React from 'react';
import {
	GenericComponent,
	NarrowButton,
	GenericForm,
	FormField,
	valuePresent,

	MultiRecFormField,
	MultiRecHandler,
	file_set,
	FileSet,

	fieldValidations,
	getInitialState,
	copyFormFieldsToDataFields,
	copyDataFieldsToFormFields,
	deepCopy,
	raiseError,

	documentnumber,
	document_number,
	inputdate,
	input_date,
	user_name,
	username,
	emailid,
	email_id,
	contactphonenumber,
	contact_phone_number,
	generic_string,
	genericstring,
	filteredselect,
	filtered_select,
	searchselect,
	search_select,
	generic_integer,
	genericinteger,
	genericcheckbox,
	generic_checkbox,
	currency,
	Currency,
	item_id,
	itemid,
	genericdecimal,
	generic_decimal,
	unit_measure,
	unitmeasure,
	MultiRec,

} from 'WebUI_Framework';

import './AddEditRFQ.css';

import rfqServiceRoutines from "./rfqServiceRoutines";
import commonServiceRoutines from "../common/CommonServiceRoutines";
import PartnerList from '../common/PartnerList'
//import AddressList from '../common/AddressList'
import AddressFilter from '../common/AddressFilter'
import Attachments from '../common/Attachments'
import { formatAddress, copyAddressElements } from '../common/utils'

const LINE_ITEMS_MREC = "LINE_ITEMS";
const SELLERS_MREC = "SELLERS";

const header_fields = {
	rfq_number: {
		id: "rfq_number", name: "rfq_number", label: "RFQ Number:",
		class: documentnumber, component: document_number, mandatory: true
	},

	rfq_issue_date: {
		id: "rfq_issue_date", name: "rfq_issue_date",
		label: "RFQ Issue Date:", class: inputdate, component: input_date, mandatory: true
	},

	rfq_contact_user_name: {
		id: "rfq_contact_user_name", name: "rfq_contact_user_name",
		label: "Contact Person:", class: username, component: user_name, mandatory: true
	},

	rfq_contact_email_id: {
		id: "rfq_contact_email_id", name: "rfq_contact_email_id",
		label: "Contact Email:", class: emailid, component: email_id, mandatory: true
	},

	rfq_contact_phone_num: {
		id: "rfq_contact_phone_num", name: "rfq_contact_phone_num",
		label: "Contact Phone:", class: contactphonenumber, component: contact_phone_number, mandatory: true
	},

	rfq_valid_from_date: {
		id: "rfq_valid_from_date", name: "rfq_valid_from_date",
		label: "RFQ Valid From:", class: inputdate, component: input_date, mandatory: true
	},

	rfq_valid_to_date: {
		id: "rfq_valid_to_date", name: "rfq_valid_to_date",
		label: "RFQ Valid Till:", class: inputdate, component: input_date, mandatory: true
	},

	currency: {
		id: "currency", name: "currency", label: "Currency:",
		class: Currency, component: currency, mandatory: true
	},

	description: {
		id: "description", name: "description", label: "Description:",
		class: genericstring, component: generic_string, mandatory: true
	},

	notes: {
		id: "notes", name: "notes", label: "Notes:",
		class: genericstring, component: generic_string, mandatory: false
	},

	billing_address_id: {
		id: "billing_address_id", name: "billing_address_id", label: "Billing Address:",
		class: filteredselect, component: filtered_select, mandatory: true
	},

	billing_address: {
		id: "billing_address", name: "billing_address",
		label: "Address:", class: searchselect, component: search_select, mandatory: true
	},

	billing_contact_user_name: {
		id: "billing_contact_user_name", name: "billing_contact_user_name",
		label: "Contact Person:", class: username, component: user_name, mandatory: false
	},

	billing_contact_email_id: {
		id: "billing_contact_email_id", name: "billing_contact_email_id",
		label: "Contact Email:", class: emailid, component: email_id, mandatory: false
	},

	billing_contact_phone_num: {
		id: "billing_contact_phone_num", name: "billing_contact_phone_num",
		label: "Contact Phone:", class: contactphonenumber, component: contact_phone_number, mandatory: false
	},


	delivery_address_id: {
		id: "delivery_address_id", name: "delivery_address_id", label: "Delivery Address:",
		class: filteredselect, component: filtered_select, mandatory: true
	},

	delivery_address: {
		id: "delivery_address", name: "delivery_address",
		label: "Address:", class: searchselect, component: search_select, mandatory: true
	},

	delivery_contact_user_name: {
		id: "delivery_contact_user_name", name: "delivery_contact_user_name",
		label: "Contact Person:", class: username, component: user_name, mandatory: false
	},

	delivery_contact_email_id: {
		id: "delivery_contact_email_id", name: "delivery_contact_email_id",
		label: "Contact Email:", class: emailid, component: email_id, mandatory: false
	},

	delivery_contact_phone_num: {
		id: "delivery_contact_phone_num", name: "delivery_contact_phone_num",
		label: "Contact Phone:", class: contactphonenumber, component: contact_phone_number, mandatory: false
	},

	attachments: {
		id: "attachments",
		name: "attachments",
		label: window.getLabelLiteralWithColon("ATTACHMENTS"),
		class: file_set,
		component: FileSet,
		mandatory: false,
	},

}

let header_mapping = [
	["rfq_number", "rfq_number"],
	["rfq_issue_date", "rfq_issue_date"],
	["rfq_contact_user_name", "rfq_contact_user_name"],
	["rfq_contact_email_id", "rfq_contact_email_id"],
	["rfq_contact_phone_num", "rfq_contact_phone_num"],
	["rfq_valid_from_date", "rfq_valid_from_date"],
	["rfq_valid_to_date", "rfq_valid_to_date"],
	["buyer_org_id", "buyer_org_id"],
	["delivery_address_id", "delivery_address_id"],
	["delivery_contact_user_name", "delivery_contact_user_name"],
	["delivery_contact_email_id", "delivery_contact_email_id"],
	["delivery_contact_phone_num", "delivery_contact_phone_num"],
	["description", "description"],
	["notes", "notes"],
	["billing_address_id", "billing_address_id"],
	["billing_contact_user_name", "billing_contact_user_name"],
	["billing_contact_email_id", "billing_contact_email_id"],
	["billing_contact_phone_num", "billing_contact_phone_num"],
	["payment_period", "payment_period"],
	["payment_period_unit", "payment_period_unit"],
	["currency", "currency"],
	["attachments", "attachments"]
];

const details_fields = {
	seller_item_id: {
		id: "seller_item_id", name: "seller_item_id", label: "Item Id:",
		class: itemid, component: item_id, mandatory: true
	},

	seller_item_description: {
		id: "seller_item_description", name: "seller_item_description", label: "Description:",
		class: genericstring, component: generic_string, mandatory: true
	},

	requested_quantity: {
		id: "requested_quantity", name: "requested_quantity", label: "requested_quantity:",
		class: genericdecimal, component: generic_decimal, mandatory: true
	},

	unit_of_measure: {
		id: "unit_of_measure", name: "unit_of_measure", label: "Unit:",
		class: unitmeasure, component: unit_measure, mandatory: true
	},

	item_delivery_from_date: {
		id: "item_delivery_from_date", name: "item_delivery_from_date", label: "Delivery From Date:",
		class: inputdate, component: input_date, mandatory: true
	},

	item_delivery_to_date: {
		id: "item_delivery_to_date", name: "item_delivery_to_date", label: "Delivery To Date:",
		class: inputdate, component: input_date, mandatory: true
	},

	notes: {
		id: "notes", name: "notes", label: "Notes:",
		class: genericstring, component: generic_string, mandatory: false
	},

	rfq_serial_id: {
		id: "rfq_serial_id", name: "rfq_serial_id", label: "RFQ Serial Id",
		class: genericinteger, component: generic_integer, mandatory: false,
	},
	
	deleted: {
		id: "deleted", name: "deleted", label: window.getLabelLiteralWithColon("DELETED"),
		class: genericcheckbox, component: generic_checkbox, mandatory: false
	},

}

let details_mapping = [
	["seller_item_id", "seller_item_id"]
	,["seller_item_description", "seller_item_description"]
	,["requested_quantity", "requested_quantity"]
	,["unit_of_measure", "unit_of_measure"]
	,["notes", "notes"]
	,["item_delivery_from_date", "item_delivery_from_date"]
	,["item_delivery_to_date", "item_delivery_to_date"]
	,["rfq_serial_id", "rfq_serial_id"]
	,["deleted", "deleted"]
];

const sellers_fields = {
	seller_org_id: {
		id: "seller_org_id", name: "seller_org_id", label: "Seller Id:",
		class: filteredselect, component: filtered_select, mandatory: true
	},

	seller_org_name: {
		id: "seller_org_name", name: "seller_org_name", label: "Company Name:",
		class: searchselect, component: search_select, mandatory: true
	},

	seller_address_id: {
		id: "seller_address_id", name: "seller_address_id", label: "Seller Address:",
		class: filteredselect, component: filtered_select, mandatory: true
	},

	seller_address: {
		id: "seller_address", name: "seller_address",
		label: "Address:", class: searchselect, component: search_select, mandatory: false,
		initialValue : {}
	},

	seller_contact_user_name: {
		id: "seller_contact_user_name", name: "seller_contact_user_name", label: "Contact Person:",
		class: username, component: user_name, mandatory: true
	},

	seller_contact_email_id: {
		id: "seller_contact_email_id", name: "seller_contact_email_id", label: "Contact Email:",
		class: emailid, component: email_id, mandatory: true
	},

	seller_contact_phone_num: {
		id: "seller_contact_phone_num", name: "seller_contact_phone_num", label: "Contact Phone:",
		class: contactphonenumber, component: contact_phone_number, mandatory: true
	},

	deleted: {
		id: "deleted", name: "deleted", label: window.getLabelLiteralWithColon("DELETED"),
		class: genericcheckbox, component: generic_checkbox, mandatory: false
	},

}

let sellers_mapping = [
	["seller_org_id", "seller_org_id"],
	["seller_org_name", "seller_org_name"],
	["seller_address_id", "seller_address_id"],
	["seller_contact_user_name", "seller_contact_user_name"],
	["seller_contact_email_id", "seller_contact_email_id"],
	["seller_contact_phone_num", "seller_contact_phone_num"],
	["seller_address", "seller_address"],
	["deleted", "deleted"],
];

class AddEditRFQ extends GenericComponent {
	constructor(props) {
		super(props);
		this.state.loading = true;
		this.state.step = 1;

		this.readOnly = false;
		this.subscriberServerInstance = this.session.getServerInstance('SUBSCRIBER');
		this.accessToken = this.session.getAccessToken();

		this.action = props.action;
		this.rfqId = props.rfqId;
		this.rfqNumber = props.rfqNumber;
		this.rfqIssueDate = props.rfqIssueDate;
		this.buyerOrgId = props.buyerOrgId;

		this.rfq = { header: {}, details: [{}], sellers: [{}] };
		this.logged_in_company_id = this.session.getLoggedInCompanyId();
		this.parent_company_id = this.session.getLoggedInParentCompanyId();
		this.is_subscriber = (this.logged_in_company_id === this.parent_company_id);
		this.page_title = "";
		this.page_title += (this.action === "DELETE") ? window.getLabelLiteral("DELETING") : window.getLabelLiteral(this.action);
		this.expandBillingDetails(false);
		this.expandDeliveryDetails(false);
		if (this.action === "ADD") {
			this.state.data.rfq_issue_date = this.session.getCurrentDate();
			this.expandBillingDetails(true);
			this.expandDeliveryDetails(true);
		}
		else if (this.action === "EDIT") {
			this.state.step = 2;
		}
		else if (this.action === "APPROVE") {
			this.expandBillingDetails(true);
			this.expandDeliveryDetails(true);
			this.state.step = 2;
			this.readOnly = true;
		}
		else if (this.action === "VIEW" || this.action === "VIEWRFQ") {
			this.expandBillingDetails(true);
			this.expandDeliveryDetails(true);
			this.readOnly = true;
			this.state.step = 2;
		}
		else if (this.action === "DELETE") {
			this.readOnly = true;
		}
		this.page_title += " " + window.getLabelLiteral("RFQ_TITLE");

		this.lineItemsHandler = new MultiRecHandler(this.session, details_fields, details_mapping, LINE_ITEMS_MREC);
		this.sellersHandler = new MultiRecHandler(this.session, sellers_fields, sellers_mapping, SELLERS_MREC);

	}

	expandBillingDetails = (flg) => {
		this.aria_expanded_billing = flg;
		this.show_billing = (this.aria_expanded_billing) ? "show" : "";
		this.aria_collapsed_billing = (this.aria_expanded_billing) ? "expanded" : "collapsed";
	}

	expandDeliveryDetails = (flg) => {
		this.aria_expanded_delivery = flg;
		this.show_delivery = (this.aria_expanded_delivery) ? "show" : "";
		this.aria_collapsed_delivery = (this.aria_expanded_delivery) ? "expanded" : "collapsed";
	}

	initialState = getInitialState(header_fields);

	state = {
		data: deepCopy(this.initialState),
	}

	backHandler = (retFrom, error) => {
		if (this.isChildMode())
			this.props.returnToParent({
				retFrom, arrayIndex: this.props.arrayIndex,
				selectData: this.rfq, error
			});
		else console.log("LOADED AS A PARENT");
	}

	fetch = async (rfqId) => {
		return await rfqServiceRoutines.fetch(this.session, rfqId);
	}

	fetchUsingNumberAndDate = async (rfqNumber, rfqIssueDate, buyerOrgId) => {
		return await rfqServiceRoutines.fetchUsingNumberAndDate(this.session, rfqNumber, rfqIssueDate, buyerOrgId);
	}

	delete = async (rfq) => {
		let retData;
		try {
			await rfqServiceRoutines.delete(this.session, rfq);
		}
		catch (e) {
			//this.setError(raiseError(e.error_message));
			throw e;
		}

		return retData
	}

	loadBillingAddress = (data) => {
		data.billing_address = formatAddress(this.rfq.header.billing_address);
	}

	loadDeliveryAddress = (data) => {
		data.delivery_address = formatAddress(this.rfq.header.delivery_address);
	}

	populateDefaultInformation = async (data) => {
		try {
			const buyerDetails = await commonServiceRoutines.fetchCompany(this.session, this.session.getLoggedInCompanyId());

			if (data.billing_contact_user_name === "")
				data.billing_contact_user_name = buyerDetails.contact_name;

			if (data.billing_contact_email_id === "")
				data.billing_contact_email_id = buyerDetails.contact_email_id;

			if (data.billing_contact_phone_num === "")
				data.billing_contact_phone_num = buyerDetails.contact_phone_number;

			if (data.delivery_contact_user_name === "")
				data.delivery_contact_user_name = buyerDetails.contact_name;

			if (data.delivery_contact_email_id === "")
				data.delivery_contact_email_id = buyerDetails.contact_email_id;

			if (data.delivery_contact_phone_num === "")
				data.delivery_contact_phone_num = buyerDetails.contact_phone_number;

			if (data.rfq_contact_user_name === "")
				data.rfq_contact_user_name = buyerDetails.contact_name;

			if (data.rfq_contact_email_id === "")
				data.rfq_contact_email_id = buyerDetails.contact_email_id;

			if (data.rfq_contact_phone_num === "")
				data.rfq_contact_phone_num = buyerDetails.contact_phone_number;

			const buyerAddress = await commonServiceRoutines.fetchDefaultAddress(this.session, this.session.getLoggedInCompanyId());

			if (data.billing_address_id === "")
				data.billing_address_id = buyerAddress.address_id;
			if (data.billing_address === "")
				data.billing_address = formatAddress(buyerAddress);

			if (data.delivery_address_id === "")
				data.delivery_address_id = buyerAddress.address_id;
			if (data.delivery_address === "")
				data.delivery_address = formatAddress(buyerAddress);

		} catch (e) {
			console.log(this.action);
			console.log(e);
			this.setError(raiseError((e.error_message) ? e.error_message : e.message));
			this.backHandler(this.action, this.error);
		}
		return;
	}

	componentDidMount = async () => {
		let newData;
		try {
			switch (this.action) {
				case 'ADD':
					newData = deepCopy(this.state.data);
					newData = this.lineItemsHandler.dataArrayToFormFields(this.rfq.details, newData);
					newData = this.sellersHandler.dataArrayToFormFields(this.rfq.sellers, newData);
					this.rfq.header.buyer_org_id = this.logged_in_company_id;
					newData.buyer_org_id = this.logged_in_company_id;
					await this.populateDefaultInformation(newData);
					this.setState({ loading: false, data: newData });
					break;
				case 'VIEWRFQ':
					this.rfq = await this.fetchUsingNumberAndDate(this.rfqNumber, this.rfqIssueDate, this.buyerOrgId);

					newData = deepCopy(this.state.data);
					copyDataFieldsToFormFields(header_mapping, this.rfq.header, newData);
					newData = this.lineItemsHandler.dataArrayToFormFields(this.rfq.details, newData);
					newData = this.sellersHandler.dataArrayToFormFields(this.rfq.sellers, newData);

					this.loadBillingAddress(newData);
					this.loadDeliveryAddress(newData);

					this.setState({ loading: false, data: newData });

					break;
				/*eslint no-fallthrough: 0*/
				case 'VIEW':
				case 'APPROVE':
				case 'EDIT':
				case 'DELETE':
					this.rfq = await this.fetch(this.rfqId);

					newData = deepCopy(this.state.data);
					copyDataFieldsToFormFields(header_mapping, this.rfq.header, newData);
					newData = this.lineItemsHandler.dataArrayToFormFields(this.rfq.details, newData);
					newData = this.sellersHandler.dataArrayToFormFields(this.rfq.sellers, newData);
					switch (this.action) {
						case 'DELETE':
							this.setState({ data: newData });
							if (this.rfq.header.deleted === true) {
								this.setError(raiseError(window.getLabelLiteral("RECORD_DELETED") + " [" + this.rfqId + "]"));
								this.backHandler(this.action);
							}
							await this.delete(this.rfq);
							this.backHandler(this.action);
							break;
						case 'EDIT' : 
						//issue
						default:
							this.loadBillingAddress(newData);
							this.loadDeliveryAddress(newData);

							this.setState({ loading: false, data: newData });
							break;
					}

					break;
				default:
					this.setError(raiseError("AddEditRFQ: Invalid action [" + this.action + "]"));
					this.backHandler(this.action, this.error);
					break;
			}
		}
		catch (e) {
			console.log(this.action);
			console.log(e);
			this.setError(raiseError((e.error_message) ? e.error_message : e.message));
			this.backHandler(this.action, this.error);
		}
	}

	addNewSellerRow = () => {
		let data = this.state.data;
		this.resetError();
		let [newData, new_list] = this.sellersHandler.addMultiRecRow(data, this.rfq.sellers, { });
		this.rfq.sellers = new_list;
		this.setState({ data: newData });
	}

	seller_button_inputs = [
		{
			literal: "Add Seller",
			func: () => this.addNewSellerRow()
		}
	]

	deleteSellerRow = (rowData, i) => {
		if (this.rfq.sellers[i].version === null ||
			this.rfq.sellers[i].version === undefined) {
			this.resetError();
			let [newData, updated_list] = this.sellersHandler.deleteMultiRecRow(this.state.data, this.rfq.sellers, i);
			this.rfq.sellers = updated_list;
			this.setState({ data: newData });
		}
		else {
			this.rfq.sellers[i].deleted = true;
		}
	}

	populateSellerOrgInfo = async (data, org_id, i) => {
		const sellerDetails = await commonServiceRoutines.fetchCompany(this.session, org_id);

		data[this.sellersHandler.getFieldIdentifier("seller_org_name", i)] = sellerDetails.org_name;
		data[this.sellersHandler.getFieldIdentifier("seller_contact_user_name", i)] = sellerDetails.contact_name;
		data[this.sellersHandler.getFieldIdentifier("seller_contact_email_id", i)] = sellerDetails.contact_email_id;
		data[this.sellersHandler.getFieldIdentifier("seller_contact_phone_num", i)] = sellerDetails.contact_phone_number;

		const sellerAddress = await commonServiceRoutines.fetchDefaultAddress(this.session, org_id);

		data[this.sellersHandler.getFieldIdentifier("seller_address_id", i)] = sellerAddress.address_id;
		data[this.sellersHandler.getFieldIdentifier("seller_address", i)] = copyAddressElements(sellerAddress);
	}

	partnerSelectOption = async (org_id, org_name, i) => {
		let c_data = {};
		if (org_id !== undefined && org_id !== null && org_id !== '')
			await this.populateSellerOrgInfo(c_data, org_id, i);

		let data = deepCopy(this.state.data)
		for (const key in c_data) {
			data[key] = c_data[key];
		}
		data[this.sellersHandler.getFieldIdentifier("seller_org_name", i)] = org_name;
		data[this.sellersHandler.getFieldIdentifier("seller_org_id", i)] = org_id;

		this.setState({ data });
	}

	selectAddressIdSeller = (address_id, formatted_address, address, i) => {
		let data = deepCopy(this.state.data);
		data[this.sellersHandler.getFieldIdentifier("seller_address", i)] = address;
		data[this.sellersHandler.getFieldIdentifier("seller_address_id", i)] = address_id;
		this.setState({ data });
	}

	clearSelectionSellerAddress = (i) => {
		let data = deepCopy(this.state.data);
		data[this.sellersHandler.getFieldIdentifier("seller_address", i)] = {}
		data[this.sellersHandler.getFieldIdentifier("seller_address_id", i)] = '';
		this.setState({ data });
	}

	getSellerHeader = () => {
		let key = 0;
		return (
			<tr key={++key}>
				<th key={++key}>{window.getLabelLiteral("SELLER_COMPANY_NAME")}</th>
				<th key={++key}>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				</th>
				<th key={++key}>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				</th>
				<th key={++key}>{window.getLabelLiteral("SELLER_ADDRESS")}</th>
				<th key={++key}>{window.getLabelLiteral("SELLER_CONTACT_PERSON")}</th>
				<th key={++key}>{window.getLabelLiteral("SELLER_CONTACT_EMAIL")}</th>
				<th key={++key}>{window.getLabelLiteral("SELLER_CONTACT_PHONE")}</th>
				{ !this.readOnly &&
				<th key={++key}>{window.getLabelLiteral("DELETE")}</th>
				}
			</tr>
		);
	}

	getSellerRow = (rowData, index) => {
		const i = index - 1;

		let delete_button = 1;
		if (rowData.version >= 0)
			delete_button = 2;

		let view_delete_option = 0;
		if (!this.readOnly && delete_button === 1) view_delete_option = 1;
		else if (!this.readOnly && delete_button === 2) view_delete_option = 2;

		let key = 0;
		let r = <React.Fragment key={index}>
			<tr key={++key}>
				<td colSpan="3" key={++key}>
					<PartnerList
						field={sellers_fields.seller_org_id}
						selectOption={(org_id, org_name) => { return this.partnerSelectOption(org_id, org_name, i)}}
						controlProps={this.controlProps}
						relationship='S'
						readOnly={this.readOnly || valuePresent(this.rfq.sellers[i]?.version)}
						inMultiRec={true}
						style={{width:"100%"}}
						i={i}
						multiRecId={SELLERS_MREC}
					/>
				</td>
				<td key={++key}>
					<AddressFilter
						field={sellers_fields.seller_address_id}
						controlProps={this.controlProps}
						orgId={this.state.data[this.sellersHandler.getFieldIdentifier("seller_org_id", i)]}
						selectOption={(address_id, formatted_address, address) => {
							return this.selectAddressIdSeller(address_id, formatted_address, address, i);
						} }
						clearSelection={() => { return this.clearSelectionSellerAddress(i)}}
						readOnly={this.readOnly || this.state.data[this.sellersHandler.getFieldIdentifier("seller_org_id", i)] === ""}
						inMultiRec={true}
						style={{width:"100%"}}
						i={i}
						multiRecId={SELLERS_MREC}
                        dependency={[this.state.data[this.sellersHandler.getFieldIdentifier("seller_org_id", i)]]}
					/>
				</td>
				<td>
					<MultiRecFormField field={sellers_fields.seller_contact_user_name} controlProps={this.controlProps}
						multiRecId={SELLERS_MREC}
						style={{}} index={i} disabled={this.readOnly} />
				</td>
				<td>
					<MultiRecFormField field={sellers_fields.seller_contact_email_id} controlProps={this.controlProps}
						multiRecId={SELLERS_MREC}
						style={{}} index={i} disabled={this.readOnly} />
				</td>
				<td>
					<MultiRecFormField field={sellers_fields.seller_contact_phone_num} controlProps={this.controlProps}
						multiRecId={SELLERS_MREC}
						style={{width:"70%"}} index={i} disabled={this.readOnly} />
				</td>
				<td key={++key}>
					{(view_delete_option === 1) &&
						<div style={{ paddingLeft: "20px", cursor: "pointer" }}>
							<span onClick={() => { return this.deleteSellerRow(rowData, i); }} data-toggle="tooltip" title="Delete">
								<i className="bi bi-trash3" style={{ fontSize: 20 }}></i>
							</span>
						</div>
					}
					{(view_delete_option === 2) &&
						<div className="ml-2" style={{ paddingTop: "4px", paddingLeft: "20px" }}>
							<MultiRecFormField field={sellers_fields.deleted} controlProps={this.controlProps}
								multiRecId={SELLERS_MREC}
								style={{}} index={i} disabled={this.readOnly} />
						</div>
					}
				</td>
			</tr>
		</React.Fragment>
		return r;
	}

	addNewDetailsRow = () => {
		let data = this.state.data;
		this.resetError();
		let [newData, new_list] = this.lineItemsHandler.addMultiRecRow(data, this.rfq.details, {
			item_delivery_from_date: data.rfq_valid_from_date,
			item_delivery_to_date: data.rfq_valid_to_date
		});
		this.rfq.details = new_list;
		this.setState({ data: newData });
	}

	details_button_inputs = [
		{
			literal: "Add item",
			func: () => this.addNewDetailsRow()
		}
	]

	deleteDetailsRow = (rowData, i) => {
		if (this.rfq.details[i].version === null ||
			this.rfq.details[i].version === undefined) {
			this.resetError();
			let [newData, updated_list] = this.lineItemsHandler.deleteMultiRecRow(this.state.data, this.rfq.details, i);
			this.rfq.details = updated_list;
			this.setState({ data: newData });
		}
		else {
			this.rfq.details[i].deleted = true;
		}
	}

	getDetailsHeader = () => {
		let key = 0;
		return (
			<tr key={++key}>
				<th key={++key}>{window.getLabelLiteral("SELLER_ITEM_ID")}</th>
				<th key={++key}>{window.getLabelLiteral("SELLER_ITEM_DESCRIPTION")}</th>
				<th key={++key}>{window.getLabelLiteral("FROM_DATE")}</th>
				<th key={++key}>{window.getLabelLiteral("TO_DATE")}</th>
				<th key={++key}>{window.getLabelLiteral("QUANTITY")}</th>
				<th key={++key}>{window.getLabelLiteral("UNIT_OF_MEASURE")}</th>
				{ !this.readOnly &&
				<th key={++key}>{window.getLabelLiteral("DELETE")}</th>
				}
			</tr>
		);
	}

	getDetailsRow = (rowData, index) => {
		const i = index - 1;

		let delete_button = 1;
		if (rowData.version >= 0)
			delete_button = 2;

		let view_delete_option = 0;
		if (!this.readOnly && delete_button === 1) view_delete_option = 1;
		else if (!this.readOnly && delete_button === 2) view_delete_option = 2;

		let key = 0;
		let r = <React.Fragment key={index}>
			<tr key={++key}>
				<td key={++key}>
					<MultiRecFormField field={details_fields.seller_item_id} controlProps={this.controlProps} index={i}
						multiRecId={LINE_ITEMS_MREC}
						style={{ width: "75%" }}
						readOnly={this.readOnly} />
				</td>
				<td key={++key}>
					<MultiRecFormField field={details_fields.seller_item_description} controlProps={this.controlProps} index={i}
						multiRecId={LINE_ITEMS_MREC}
						style={{width:"95%"}}
						readOnly={this.readOnly} />
				</td>
				<td key={++key}>
					<MultiRecFormField field={details_fields.item_delivery_from_date} controlProps={this.controlProps} index={i}
						multiRecId={LINE_ITEMS_MREC}
						style={{ width: "85%" }} disabled={this.readOnly} />
				</td>
				<td key={++key}>
					<MultiRecFormField field={details_fields.item_delivery_to_date} controlProps={this.controlProps} index={i}
						multiRecId={LINE_ITEMS_MREC}
						style={{ width: "85%" }} disabled={this.readOnly} />
				</td>
				<td key={++key}>
					<MultiRecFormField field={details_fields.requested_quantity} controlProps={this.controlProps} index={i}
						multiRecId={LINE_ITEMS_MREC}
						formattingInputs={{ minimumFractionDigits: 0, maximumFractionDigits: 6 }}
						style={{ width: "60%", textAlign:"right"}} readOnly={this.readOnly} />
				</td>
				<td key={++key}>
					<MultiRecFormField field={details_fields.unit_of_measure} controlProps={this.controlProps} index={i}
						multiRecId={LINE_ITEMS_MREC}
						style={{width:"100%"}}
						disabled={this.readOnly} />
				</td>
				<td key={++key}>
					{(view_delete_option === 1) &&
						<div style={{ paddingLeft: "20px", cursor: "pointer" }}>
							<span onClick={() => { return this.deleteDetailsRow(rowData, i); }} data-toggle="tooltip" title="Delete">
								<i className="bi bi-trash3" style={{ fontSize: 20 }}></i>
							</span>
						</div>
					}
					{(view_delete_option === 2) &&
						<div className="ml-2" style={{ paddingTop: "4px", paddingLeft: "20px" }}>
							<MultiRecFormField field={details_fields.deleted} controlProps={this.controlProps}
								multiRecId={LINE_ITEMS_MREC}
								style={{}} index={i} disabled={this.readOnly} />
						</div>
					}
				</td>
			</tr>
		</React.Fragment>
		return r;
	}

	selectAddressIdBilling = (address_id, formatted_address, address) => {
		let data = deepCopy(this.state.data);
		data.billing_address = formatted_address;
		data.billing_address_id = address_id;
		this.setState({ data });
	};

	clearSelectionBillingAddress = () => {
		let data = deepCopy(this.state.data);
		data.billing_address = ''
		data.billing_address_id = ''
		this.setState({ data });
	}

	selectAddressIdDelivery = (address_id, formatted_address, address) => {
		let data = deepCopy(this.state.data);
		data.delivery_address = formatted_address;
		data.delivery_address_id = address_id;
		this.setState({ data });
	};

	clearSelectionDeliveryAddress = () => {
		let data = deepCopy(this.state.data);
		data.delivery_address = ''
		data.delivery_address_id = ''
		this.setState({ data });
	}


	proceed = async () => {
		this.resetError();
		let r = fieldValidations(header_fields, this.state.data);
		if (!r.status) {
			console.log(r)
			this.setError(r);
			return false;
		}

		let data = deepCopy(this.state.data);
		this.expandBillingDetails(false);
		this.expandDeliveryDetails(false);

		this.rfq.details[0].item_delivery_from_date = data.rfq_valid_from_date
		this.rfq.details[0].item_delivery_to_date = data.rfq_valid_to_date
		data = this.lineItemsHandler.dataArrayToFormFields(this.rfq.details, data);
		data = this.sellersHandler.dataArrayToFormFields(this.rfq.sellers, data);

		this.setState({ step: 2, data });
	}

	data_render = () => {
		let key = 0;

		let datablk = (
			<div className="container-fluid border border-0 pt-0 m-0 ">
				<div className="row	p-0 m-0 g-0 ">
					<div className="col-sm-12 h6 text-left">
						{this.getBreadcrumb([window.getLabelLiteral(this.action)])}
					</div>
					<hr></hr>
					<div className="col-sm-6">
						<FormField
							field={header_fields.rfq_number}
							controlProps={this.controlProps}
							readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
						/>
					</div>
					<div className="col-sm-6">
						<FormField
							field={header_fields.rfq_issue_date}
							controlProps={this.controlProps}
							readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
						/>
					</div>
					<div className="col-sm-6">
						<FormField
							field={header_fields.description}
							controlProps={this.controlProps}
							readOnly={this.readOnly}
						/>
					</div>
					<div className="col-sm-6">
						<FormField
							field={header_fields.currency}
							controlProps={this.controlProps}
							readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
						/>
					</div>
					<div className="col-sm-6">
						<FormField
							field={header_fields.rfq_valid_from_date}
							controlProps={this.controlProps}
							readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
						/>
					</div>
					<div className="col-sm-6">
						<FormField
							field={header_fields.rfq_valid_to_date}
							style={{}}
							controlProps={this.controlProps}
							readOnly={this.readOnly}
						/>
					</div>
					<div className="col-sm-6">
						<FormField
							field={header_fields.rfq_contact_user_name}
							controlProps={this.controlProps}
							readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
						/>
					</div>
					<div className="col-sm-6">
						&nbsp;
					</div>
					<div className="col-sm-6">
						<FormField
							field={header_fields.rfq_contact_email_id}
							controlProps={this.controlProps}
							readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
						/>
					</div>
					<div className="col-sm-6">
						<FormField
							field={header_fields.rfq_contact_phone_num}
							controlProps={this.controlProps}
							readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
						/>
					</div>

					<div className="col-sm-6 mt-3 mb-3 ">
						<Attachments
							field={header_fields.attachments}
							controlProps={this.controlProps}
							readOnly={this.readOnly}
						/>
					</div>

					<div className="accordion accordion-flush" id="billingdetails">
						<div className="accordion-item">
							<h2 className="accordion-header " id="billingdetailsdata">
								<div className="col-sm-12 h4 text-left">
									<div className="col-sm-2">
										<button className=
											{`accordion-button btn link-dark my-0 py-0 mx-0 gx-0 px-0 ${this.aria_collapsed_billing}`}
											id="collapseTwo-button"
											style={{
												color: "black",
												backgroundColor: "white",
											}}
											type="button" data-bs-toggle="collapse"
											data-bs-target="#collapseTwo" aria-expanded={this.aria_expanded_billing}
											aria-controls="collapseTwo">
											<h6>Billing Details</h6>
										</button>
									</div>
								</div>
							</h2>
							<div id="collapseTwo" className={`accordion-collapse collapse ${this.show_billing}`}
								aria-labelledby="billingdetailsdata" data-bs-parent="#billingdetails">
								<div className={`accordion-body mx-0 gx-0 px-0`} id="billingdetails-1">
									<div className="row	p-0 m-0 g-0 ">
										<div className="col-sm-6">
											<AddressFilter
												field={header_fields.billing_address_id}
												controlProps={this.controlProps}
												orgId={this.state.data.buyer_org_id}
												selectOption={this.selectAddressIdBilling}
												clearSelection={this.clearSelectionBillingAddress}
												readOnly={this.readOnly}
											/>
										</div>
										<div className="col-sm-6">
											<FormField
												field={header_fields.billing_contact_user_name}
												controlProps={this.controlProps}
												readOnly={this.readOnly}
											/>
										</div>
										<div className="col-sm-6">
											<FormField
												field={header_fields.billing_contact_email_id}
												controlProps={this.controlProps}
												readOnly={this.readOnly}
											/>
										</div>
										<div className="col-sm-6">
											<FormField
												field={header_fields.billing_contact_phone_num}
												controlProps={this.controlProps}
												readOnly={this.readOnly}
											/>
										</div>
									</div>
									<div className="col-sm-12">
										&nbsp;
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="accordion accordion-flush" id="deliverydetails">
						<div className="accordion-item">
							<h2 className="accordion-header " id="deliverydetailsdata">
								<div className="col-sm-12 h4 text-left">
									<div className="col-sm-2">
										<button className=
											{`accordion-button btn link-dark my-0 py-0 mx-0 gx-0 px-0 ${this.aria_collapsed_delivery}`}
											id="collapseThree-button"
											style={{
												color: "black",
												backgroundColor: "white",
											}}
											type="button" data-bs-toggle="collapse"
											data-bs-target="#collapseThree" aria-expanded={this.aria_expanded_delivery}
											aria-controls="collapseThree">
											<h6>Delivery Details</h6>
										</button>
									</div>
								</div>
							</h2>
							<div id="collapseThree" className={`accordion-collapse collapse ${this.show_delivery}`}
								aria-labelledby="deliverydetailsdata" data-bs-parent="#deliverydetails">
								<div className={`accordion-body mx-0 gx-0 px-0`} id="deliverydetails-1">
									<div className="row	p-0 m-0 g-0 ">
										<div className="col-sm-6">
											<AddressFilter
												field={header_fields.delivery_address_id}
												controlProps={this.controlProps}
												orgId={this.state.data.buyer_org_id}
												selectOption={this.selectAddressIdDelivery}
												clearSelection={this.clearSelectionDeliveryAddress}
												readOnly={this.readOnly}
											/>
										</div>
										<div className="col-sm-6">
											<FormField
												field={header_fields.delivery_contact_user_name}
												controlProps={this.controlProps}
												readOnly={this.readOnly}
											/>
										</div>
										<div className="col-sm-6">
											<FormField
												field={header_fields.delivery_contact_email_id}
												controlProps={this.controlProps}
												readOnly={this.readOnly}
											/>
										</div>
										<div className="col-sm-6">
											<FormField
												field={header_fields.delivery_contact_phone_num}
												controlProps={this.controlProps}
												readOnly={this.readOnly}
											/>
										</div>
									</div>
									<div className="col-sm-12">
										&nbsp;
									</div>
								</div>
							</div>
						</div>
					</div>



					{(this.state.step === 1) &&
						<div className="col-sm-6 mt-4 d-flex justify-content-right ">
							<NarrowButton value="Proceed" onClick={this.proceed} />
							<NarrowButton value="Back" onClick={() => { return this.backHandler("BACK"); }} key={(++key).toString()} />
						</div>
					}
					{(this.state.step === 2) &&
						<React.Fragment>
							<div>
								<MultiRec
									key={++key}
									getRow={this.getSellerRow}
									getHeader={this.getSellerHeader}
									data={this.rfq.sellers}
									buttons={(this.readOnly) ? null : this.seller_button_inputs}
									tfootStyle={{ borderTop: "0.1rem solid" }}
									tbodyStyle={{ borderTop: "2.5px solid" }}
								/>
							</div>
							<div>
								<MultiRec
									key={++key}
									getRow={this.getDetailsRow}
									getHeader={this.getDetailsHeader}
									data={this.rfq.details}
									buttons={(this.readOnly) ? null : this.details_button_inputs}
									tfootStyle={{ borderTop: "0.1rem solid" }}
									tbodyStyle={{ borderTop: "2.5px solid" }}
								/>
							</div>
						</React.Fragment>
					}
				</div>

				{(this.state.step === 2) &&
					<div className="row  p-0 m-0 g-0 " >
						<div className="col-sm-6 mt-4 d-flex justify-content-right ">
							{!(this.readOnly) &&
								<NarrowButton value="Submit" onClick={this.submitForm} key={(++key).toString()} />
							}
							{(this.readOnly && (this.action === "APPROVE")) &&
								<NarrowButton value="Approve" onClick={this.submitForm} key={(++key).toString()} />
							}
							<NarrowButton value="Back" onClick={() => { return this.backHandler("BACK"); }} key={(++key).toString()} />
						</div>
					</div>
				}
			</div>
		);

		let form = <GenericForm datablk={datablk} buttons={[]} controlProps={this.controlProps} {...this.props} />
		return form;
	}

	loading_render = () => {
		let buttons = [];

		let datablk = <div className="container border border-0 pt-0 m-0 ">
			<div className="row  p-0 m-0 g-0 " >
				<div className="col-sm-12 h4 text-center" >{this.page_title}</div>
				<div className="d-flex justify-content-evenly">
					<div className="spinner-border text-dark" role="status">
						<span className="sr-only">Loading...</span>
					</div>
				</div>
			</div>

		</div>

		let form = <GenericForm datablk={datablk} buttons={buttons} controlProps={this.controlProps} {...this.props} />
		return form;
	}

	render = () => {
		if (this.state.loading) {
			return this.loading_render();
		}
		else {
			return this.data_render();
		}
	}

	validateForm = () => {
		let r = fieldValidations(header_fields, this.state.data);
		if (!r.status) {
			console.log(r);
			this.expandBillingDetails(true);
			this.expandDeliveryDetails(true);
			this.setError(r);
			return false;
		}
		r = this.sellersHandler.multiRecFieldValidations(this.state.data);
		if (!r.status) {
			console.log(r);
			this.setState({ error: r });
			return false;
		}
		r = this.lineItemsHandler.multiRecFieldValidations(this.state.data);
		if (!r.status) {
			console.log(r);
			this.setState({ error: r });
			return false;
		}

		return true;
	}

	submitForm = async () => {
		this.resetError();
		if (!this.validateForm()) {
			return;
		}

		let data = deepCopy(this.state.data);
		copyFormFieldsToDataFields(header_mapping, data, this.rfq.header, header_fields);
		this.lineItemsHandler.formFieldsToDataArray(this.state.data, this.rfq.details);
		this.sellersHandler.formFieldsToDataArray(this.state.data, this.rfq.sellers);

		try {
			switch (this.action) {
				case "ADD":
					await rfqServiceRoutines.add(this.session, this.rfq);
					this.rfq =
						await rfqServiceRoutines.fetchUsingNumberAndDate(this.session,
							this.rfq.header.rfq_number,
							this.rfq.header.rfq_issue_date,
							this.rfq.header.buyer_org_id);
					break;
				case "EDIT":
					await rfqServiceRoutines.modify(this.session, this.rfq);
					this.rfq = await this.fetch(this.rfqId);
					break;
				case "APPROVE":
					await rfqServiceRoutines.approve(this.session, this.rfq);
					this.rfq = await this.fetch(this.rfqId);
					break;
				default:
					break;
			}

			this.backHandler(this.action);
		}
		catch (e) {
			console.log(this.rfq);
			console.log(e);
			this.setError(raiseError(e.error_message));
		}
	}

}


export default AddEditRFQ;
