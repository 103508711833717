import { getServiceDetails } from '../common/utils'
import { handleAxiosHTTPException } from "WebUI_Framework";

let ruleSetServiceroutines = {};

ruleSetServiceroutines.list = async (session, obj) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(
			`biop/subscriber/rule_set_manager/list`,
			{
				headers: {
					"X-Auth": accessToken,
				},
				params : obj
			});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

ruleSetServiceroutines.fetchFormattedDocumentation = async (session, obj) => {
    console.log(obj);
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(
			`biop/subscriber/rule_config_view/fetch_formatted_documentation`,
			{
				headers: {
					"X-Auth": accessToken,
				},
				params : obj
			});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
}

ruleSetServiceroutines.fetchConfiguredRules = async (session, obj) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(
			`biop/subscriber/rule_config_view/list`,
			{
				headers: {
					"X-Auth": accessToken,
				},
				params : obj
			});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

ruleSetServiceroutines.fetch = async (session, rule_set_id) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(`biop/subscriber/rule_set_manager/fetch?rule_set_id=${rule_set_id}`,
			{
				headers: {
					"X-Auth": accessToken,
				},
			}
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

ruleSetServiceroutines.fetchAmended = async (session, rule_set_id) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(`biop/subscriber/rule_set_manager/fetch_amended?rule_set_id=${rule_set_id}`,
			{
				headers: {
					"X-Auth": accessToken,
				},
			}
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

ruleSetServiceroutines.delete = async (session, retData) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.put(`biop/subscriber/rule_set_manager/delete`, retData, {
			headers: {
				"X-Auth": accessToken,
			},
		});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

ruleSetServiceroutines.modify = async (session, retObj_modify) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post("biop/subscriber/rule_set_manager/modify", retObj_modify, {
			headers: {
				"X-Auth": accessToken,
			},
		});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

ruleSetServiceroutines.amend = async (session, retObj_amend) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post("biop/subscriber/rule_set_manager/amend", retObj_amend, {
			headers: {
				"X-Auth": accessToken,
			},
		});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

ruleSetServiceroutines.add = async (session, retObj) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post("biop/subscriber/rule_set_manager/add", retObj, {
			headers: {
				"X-Auth": accessToken,
			},
		});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

ruleSetServiceroutines.approve = async (session, rule_set) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post('/biop/subscriber/rule_set_manager/approve',
			rule_set,
			{
				headers: {
					"X-Auth": accessToken
				}
			}
		);
		return response.data;
	}
	catch (e) {
		return handleAxiosHTTPException(e);
	}
}

ruleSetServiceroutines.cancel = async (session, rule_set) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post('/biop/subscriber/rule_set_manager/cancel_amendment',
			rule_set,
			{
				headers: {
					"X-Auth": accessToken
				}
			}
		);
		return response.data;
	}
	catch (e) {
		return handleAxiosHTTPException(e);
	}
}

export default ruleSetServiceroutines;
