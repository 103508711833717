import {
	GenericComponent,
	GenericForm,
	getInitialState,
	GenericTable,
	deepCopy,
	Backdrop,
	raiseError,
	Modal_generic_content,
	Modal,
	search_select,
	searchselect,
	FormField,
} from "WebUI_Framework";

import serviceRoutines from "./SubscriberServiceRoutines";

const fields = {
	company: { id: "company", name: "company", label: "Company: ",
				class: searchselect, component: search_select, mandatory: true }
};

class ManageSubscriber extends GenericComponent {
	constructor(p) {
		super(p);
		this.recordsPerFrame = window.getParam("CURSOR_FRAME_SIZE");
		this.pageNumber = 1;
		this.selectedOrgId = "";
		this.selectedOrgName = "";
		//this.recordsPerPage = 7;
		this.recordsPerPage = window.getParam("TABLE_PAGE_SIZE");
		this.serverInstance = this.session.getServerInstance("SUBSCRIBER");
		this.accessToken = this.session.getAccessToken();

		this.getRow = this.getRow.bind(this);
		this.getFrame = this.getFrame.bind(this);
		this.activateSubscriber = this.activateSubscriber.bind(this);
		this.getCompanyList = this.getCompanyList.bind(this);
		this.selectOption = this.selectOption.bind(this);
		this.handler = this.handler.bind(this);

		this.state.tabledata = []; /* Data of main page */
		this.state.isLoading = true; /* Loading animation for table */
		this.state.showModalCompany = false; /* Whether to show the modal or not */
		this.state.showEnableSubsModal = false; /* Whether to show the modal for enable subscriberor not */
	}

	initialState = getInitialState(fields);

	state = {
		data: deepCopy(this.initialState),
	};

	componentDidMount() {
		this.getFrame(1, this.recordsPerFrame);
	}

	handler = () => {
		this.setState({ showModalCompany: true });
	};

	addButton = {
		literal: "Add",
		func: () => {
			this.handler();
		}
	};

	initiateEnableSubscriber = (row) => {
		this.selectedOrgId = row.org_id;
		this.selectedOrgName = row.org_name;
		this.setState({ showEnableSubsModal : true});
	}

	closeModal = async () => {
		this.selectedOrgId = "";
		this.selectedOrgName = "";
		this.setState({ showEnableSubsModal: false });
		return;
	};

	cancelEnablement = async () => {
		return this.closeModal();
	}

	enableSubscriber = async (modalControl) => {
		this.resetError();
		try {
			await serviceRoutines.add( this.serverInstance, this.accessToken, this.selectedOrgId);
			this.app_rerender();
			this.closeModal();
		}
		catch (e) {
			modalControl.errorMsg = e.error_message;
			modalControl.error = true;
		}
	}

	closeModalCompany = async () => {
		this.selectedOrgId = "";
		this.selectedOrgName = "";
		this.setState({ showModalCompany: false });
		return;
	};

	cancelAddition = async () => {
		this.app_rerender();
		return this.closeModalCompany();
	}

	activateSubscriber = async (modalControl) => {
		this.resetError();
		try {
			await serviceRoutines.add( this.serverInstance, this.accessToken, this.selectedOrgId);
			this.app_rerender();
			this.closeModalCompany();
		}
		catch (e) {
			modalControl.errorMsg = e.error_message;
			modalControl.error = true;
		}
	};

	getCompanyList = async (control, searchText) => {
		this.resetError();
		try {
			const retData = await serviceRoutines.companyList( this.serverInstance,
														this.accessToken, searchText);
			return retData;
		}
		catch (e) {
			control.error = true;
			if (e.error_message !== null && e.error_message !== undefined) {
				control.errorMsg = e.error_message;
			}
			else {
				control.errorMsg = e.message;
			}
		}
	};

	selectOption = (index, list) => {
		let data = deepCopy(this.state.data);
		data.company = list[index].org_name;
		this.setState({data});

		this.selectedOrgId = list[index].org_id;
	};

	clearSelection = () => {
		this.selectedOrgId = '';
	}

	formatDataList(array) {
		let formatted_array = [];
		array.map((value, index) => {
			let s = value.org_name;
			formatted_array.push(s);
			return s;
		});
		return formatted_array;
	}

	async getFrame(offset, recordsPerFrame) {
		this.resetError();
		if (this.state.tabledata.length !== 0) {
			this.setState({ isLoading: false });
			alert(window.getLiterals("NO_MORE_DATA"));
		}
		else {
			try {
				const retData = await serviceRoutines.list( this.serverInstance, this.accessToken, offset, recordsPerFrame);
				this.setState({ isLoading: false });
				if (retData.length > 0) {
					let partnerList = retData;
					this.setState({ tabledata: this.state.tabledata.concat(partnerList) });
				}
			}
			catch (e) {
				console.log(e);
				this.setError(raiseError(e.error_message));
				this.setState({ isLoading: false });
			}
		}
	}

	getRow(tabledata, index, pageNumber) {
		this.pageNumber = pageNumber;
		const today = new Date();
		let ed;
		if (tabledata.key_expiry_date) {
			ed = new Date(tabledata.key_expiry_date);
		}
		return <tr key={index}>
			<td>{tabledata.org_name}</td>
			<td>{tabledata.key_start_date}</td>
			<td>{tabledata.key_expiry_date}</td>
			{(tabledata.key_expiry_date && today < ed) ? (
				<td/>
			) : (
				<td>
					<div className="d-flex justify-content-end g-0 p-0 m-0" >
						<input type="button"
								className="btn btn-secondary shadow-none border btn-sm"
								value="Enable"
								onClick={(() => {return this.initiateEnableSubscriber(tabledata)})}
						/>
					</div>
				</td>
			)}
		</tr>
	}

	getHeader() {
		let key = 0;
		return <>
			<tr key={++key}>
				<th key={++key}>Company Name</th>
				<th key={++key}>Key Start Date</th>
				<th key={++key}>Key End Date</th>
			</tr>
		</>
	}

	render() {
		let buttons = [];
		let ModalGenericContent = <>
			<div className="col-sm-12">
				<FormField
					field={fields.company} controlProps={this.controlProps }
					getDataList={(control, searchtext) => { return this.getCompanyList(control, searchtext)}}
					formatDataList={this.formatDataList}
					selectOption={this.selectOption}
					clearSelection={this.clearSelectionSeller}
				/>
			</div>
		</>

		let datablk = <>
			<div className="container pt-0 m-0 pe-2">
				<div className="row p-0 m-0 g-0">
					<div className="col-sm-11 h3 text-center"> Manage Subscriber </div>
					<div className="col-sm-1 mt-4 d-flex justify-content-end "></div>
				</div>
				<div className="col-sm-12">
					<GenericTable
					data={this.state.tabledata}
					recordsPerPage={this.recordsPerPage}
					getRow={this.getRow}
					getHeader={this.getHeader}
					tableclass="table table-striped border-dark"
					getFrame={this.getFrame}
					recordsPerFrame={this.recordsPerFrame}
					button={this.addButton}
					pageNumber={this.pageNumber}
					isLoading={this.state.isLoading}
					/>
				</div>
				{this.state.showModalCompany && (
					<Modal_generic_content
					title="Add Subscriber :"
					btn1="Subscribe"
					btn2="Cancel"
					onClick={this.cancelAddition}
					onConfirm={(modalControl) => {
						return this.activateSubscriber(modalControl);
					}}
					modalContent={ModalGenericContent}
					/>
				)}
				{this.state.showModalCompany && <Backdrop />}
				{ ( this.state.showEnableSubsModal ) && <Modal
					title="Update Confirmation"
					msg={`Are you sure, you want enable [${this.selectedOrgName}]`}
					btn1="Confirm"
					btn2="Cancel"
					onClick={this.cancelEnablement}
					onConfirm={(modalControl) => {return this.enableSubscriber(modalControl);} }

				/>

				}
				{ ( this.state.showEnableSubsModal ) && <Backdrop /> }
			</div>
		</>

		let frm = <>
			<GenericForm
				datablk={datablk}
				buttons={buttons}
				controlProps={this.controlProps}
				{...this.props}
			/>
		</>
		return frm;
	}
}

export default ManageSubscriber;
