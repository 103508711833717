import React from 'react';
import {
	GenericComponent,
	email_id,
	emailid,
	user_name,
	username,
	org_name,
	orgname,
	contact_phone_number,
	contactphonenumber,
	address_line,
	addressline,
	City,
	city,
	State,
	state,
	Country,
	country,
	zip_code,
	zipcode,
	GenericForm,
	NarrowButton,
	FormField,
	fieldValidations,
	getInitialState,
	copyDataFieldsToFormFields,
	raiseError,
	copyFormFieldsToDataFields,
	deepCopy,
	Modal,
	MultiRec,
	MultiRecHandler,
	MultiRecFormField,
	Backdrop,
	tax_number_type,
	taxnumbertype,
	tinnumber,
	tin_number
} from 'WebUI_Framework';

import serviceRoutines from './PartnerServiceRoutines';

const fields = {
	org_name: {
		id: "org_name", name: "org_name", label: "Company Name:",
		class: orgname, component: org_name, mandatory: true
	},

	contact_phone_number: {
		id: "contact_phone_number", name: "contact_phone_number",
		label: "Contact Number:", class: contactphonenumber, component: contact_phone_number, mandatory: true
	},

	contact_email_id: {
		id: "contact_email_id", name: "contact_email_id", label: "Email Address:",
		class: emailid, component: email_id, mandatory: true
	},

	contact_name: {
		id: "contact_name", name: "contact_name", label: "Contact Person:",
		class: username, component: user_name, mandatory: true
	},

	address_1: {
		id: "address_1", name: "address_1", label: "Address:",
		class: addressline, component: address_line, mandatory: true
	},

	address_2: {
		id: "address_2", name: "address_2", label: "Address 2:",
		class: addressline, component: address_line, mandatory: false
	},

	zip_code: {
		id: "zip_code", name: "zip_code", label: "Zip Code:",
		class: zipcode, component: zip_code, mandatory: false
	},

	city: {
		id: "city", name: "city", label: "City:",
		class: city, component: City, mandatory: true
	},

	state: {
		id: "state", name: "state", label: "State:",
		class: state, component: State, mandatory: false
	},

	cntry: {
		id: "cntry", name: "cntry", label: "Country:",
		class: country, component: Country, mandatory: false
	},
}


let mapping = [
	["org_name", "org_name"],
	["contact_phone_number", "contact_phone_number"],
	["contact_email_id", "contact_email_id"],
	["contact_name", "contact_name"],
	["address_1", "address_line_1"],
	["address_2", "address_line_2"],
	["zip_code", "zip_code"],
	["city", "city"],
	["state", "state"],
	["cntry", "cntry"],
	["version", "version"],
	["org_id", "org_id"],
];

const TAX_MREC = "TAX";
const tax_type = window.getGenericRefCode('TaxType');

const tax_fields = {
	tax_number_type : { id: "tax_number_type", name : "tax_number_type", label: "",
        class : taxnumbertype, component: tax_number_type, mandatory: true},
    tax_number : { id: "tax_number", name : "tax_number", label: "",
        class : tinnumber, component: tin_number, mandatory: true},
}


let tax_mapping = [
	["tax_number", "tax_identifier"],
	["tax_number_type", "tax_identity_type"]
]

class EditPartners extends GenericComponent {
	constructor(props) {
		super(props);
		this.state.showModal = false;
		this.selectedOrgData = {company_tax_dtls : []};
		this.state.mode = "add";
		this.state.arrayIndex = 0;
		this.pageNumber = 1;
		this.tableclass = "t";
		this.serverInstance = this.session.getServerInstance('ROC');
		this.accessToken = this.session.getAccessToken();
		this.readOnly = this.props.readOnly;
		this.taxHandler = new MultiRecHandler(this.session, tax_fields, tax_mapping, TAX_MREC);
	}

	initialState = getInitialState(fields);

	state = {
		data: deepCopy(this.initialState),
	}

	componentDidMount() {
		this.getSelectedOrgData();
	}

	async getSelectedOrgData() {
		let retData;
		this.setState({ showModal: false });
		try {
			retData = await serviceRoutines.fetch(this.serverInstance, this.accessToken, this.props.orgId)
			if (retData !== undefined && retData !== null) {
				this.selectedOrgData = retData;
				if(this.selectedOrgData.company_tax_dtls === undefined){
					this.selectedOrgData.company_tax_dtls = [];
				}
				let newData_1, newData_2
				newData_1 = deepCopy(this.state.data);
                copyDataFieldsToFormFields(mapping, retData, newData_1);
                newData_2 = this.taxHandler.dataArrayToFormFields(this.selectedOrgData.company_tax_dtls, newData_1);
				this.setState({data : newData_2});
			}
		}
		catch (e) {

			this.setError(raiseError(e.error_message));
		}
	}

	backHandler = (retFrom) => {

		if (retFrom !== "edit") {
			retFrom = "back";
		}

		if (this.isChildMode()) this.props.returnToParent({ retFrom: retFrom, arrayIndex: this.props.arrayIndex, selectData: this.selectedOrgData });
		else console.log("LOADED AS A PARENT");

	}

	addTax = () => {
		if (this.selectedOrgData.company_tax_dtls.length === Object.keys(tax_type).length) {
			return;
		}
		let data = this.state.data;
		this.resetError();
		let [newData, new_list] = this.taxHandler.addMultiRecRow(data, this.selectedOrgData.company_tax_dtls);
		this.selectedOrgData.company_tax_dtls = new_list;
		this.setState({ data: newData });
	}

	deleteRow = (rowData, i) => {
		if (this.selectedOrgData.company_tax_dtls[i].version === null ||
			this.selectedOrgData.company_tax_dtls[i].version === undefined) {
			this.resetError();
			let [newData, updated_list] = this.taxHandler.deleteMultiRecRow(this.state.data, this.selectedOrgData.company_tax_dtls, i);
			this.selectedOrgData.company_tax_dtls = updated_list;
			this.setState({ data: newData });
		}
		else {
			this.selectedOrgData.company_tax_dtls[i].deleted = true;
		}
	}


	getRowTax = (rowData, index) => {
		const i = index - 1;

		let key = 0;
		let r = <React.Fragment key={index}>
			<tr key={++key}>
			<	td key={++key}>
                	<MultiRecFormField field={tax_fields.tax_number_type} controlProps={this.controlProps} index={i}
                    multiRecId={TAX_MREC} readOnly={this.readOnly}/>
                </td>
                <td key={++key}>
                    <MultiRecFormField field={tax_fields.tax_number} controlProps={this.controlProps} index={i}
                    multiRecId={TAX_MREC} readOnly={this.readOnly}/>
                </td>
				{ (!this.readOnly) &&
					<td key={++key}>
					<div style={{ paddingLeft: "20px", cursor: "pointer" }}>
						<span onClick={() => { return this.deleteRow(rowData, i); }} data-toggle="tooltip" title="Delete">
							<i className="bi bi-trash3" style={{ fontSize: 20 }}></i>
						</span>
					</div>
				</td>
				}

			</tr>
		</React.Fragment>
		return r;
	}

	getHeaderTax = () => {
		let key = 0;
		return (
			<tr key={++key}>
				<th key={++key}>Tax Type</th>
				<th key={++key}>Tax Number</th>
				{
					(!this.readOnly) &&
					<th key={++key}>Actions</th>
				}
			</tr>
		);
	}

	button_inputs = [
		{
			literal: "Add",
			func: () => this.addTax()
		}
	]

	closeModal = () => {
		this.setState({showModal : false})
	}

	render() {
		let buttons = [];
		let key = 0;
		if (this.props.readOnly) {
			this.readOnly = true;
			this.disabled = true;
		}

		let datablk = <div className="container-fluid pt-0 m-0 pe-2">
			<div className="row  p-0 m-0 g-0 " >

				{!this.readOnly ? (
					<div className="col-sm-12 h6 text-left">
						{this.getBreadcrumb([window.getLabelLiteral("EDIT")])}
					</div>
				)
					:
					<div className="col-sm-12 h6 text-left">
						{this.getBreadcrumb([window.getLabelLiteral("VIEW")])}
					</div>
				}

				<div className="col-sm-6" >
					<FormField field={fields.org_name} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.contact_phone_number} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.contact_email_id} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.contact_name} controlProps={this.controlProps} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.address_1} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.address_2} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.zip_code} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.city} controlProps={this.controlProps} disabled={this.disabled} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.cntry} controlProps={this.controlProps} disabled={this.disabled} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.state}
						controlProps={this.controlProps}
						country={this.state.data.cntry}
						disabled={this.disabled}
					/>
				</div>
			</div>
			<br></br>
			<br></br>
			<br></br>
			<br></br>

            { (!this.readOnly ||
                this.selectedOrgData?.company_tax_dtls?.length > 0) &&
			<div className="col-sm-12" >
				<div className="col-sm-9">
					<MultiRec
						pageHeading={"Tax Details"}
						key={++key}
						getRow={this.getRowTax}
						getHeader={this.getHeaderTax}
						data={this.selectedOrgData.company_tax_dtls}
						buttons={(this.readOnly) ? null : this.button_inputs}
						tfootStyle={{ borderTop: "0.1rem solid" }}
						tbodyStyle={{ borderTop: "2.5px solid" }}
					/>

				</div>
			</div>
            }
			<div className="row  p-0 m-0 g-0 " >

				<div className="col-sm-6 mt-4 d-flex justify-content-right ">
					{(!this.readOnly) && 
					<NarrowButton value="Update" onClick={this.submitForm} key={(++key).toString()} />
					}
					<NarrowButton value="Back" onClick={this.backHandler} key={(++key).toString()} />
				</div>
			</div>
			{(this.state.showModal) && <Modal
				title="Update Confirmation"
				msg="Are you sure, you want to update this Partner?"
				btn1="Confirm"
				btn2="Cancel"
				onClick={this.closeModal}
				onConfirm={this.onConfirm}
			/>

			}
			{(this.state.showModal) && <Backdrop />}

		</div>


		let form = <GenericForm datablk={datablk} buttons={buttons} controlProps={this.controlProps} {...this.props} />
		return form;
	}

	validateForm = () => {
		let tax_details = {};
		this.taxHandler.formFieldsToDataArray(this.state.data, tax_details);
		const taxIdentityTypeSet = new Set();
		// eslint-disable-next-line no-unused-vars
		for(const [k, v] of Object.entries(tax_details)){
			if(taxIdentityTypeSet.has(v.tax_identity_type)){
				this.setError(raiseError(window.getLiterals("REPEATED_TAX_TYPE_NOT_ALLOWED")));
				return false;
			}
			else{
				taxIdentityTypeSet.add(v.tax_identity_type);
			}
		}
		// eslint-disable-next-line no-unused-vars
		for (const [key, value] of Object.entries(tax_details)) {
			const taxIdentityType = value.tax_identity_type;
			const taxIdentifier = value.tax_identifier;
			if (taxIdentityType === 'IP'){
				if (/^([A-Z]{5}[\d]{4}[A-Z])$/.test(taxIdentifier) === false) {
					this.setError(raiseError(window.getLiterals("PAN_NO_INVALID")));
					return false;
				}
			}
			else if(taxIdentityType === 'IT'){
				if (/^([A-Z]{4}[\d]{5}[A-Z])$/.test(taxIdentifier) === false) {
					this.setError(raiseError(window.getLiterals("TAN_NO_INVALID")));
					return false;
				}
			}
		}
		let r = fieldValidations(fields, this.state.data);
		if (!r.status) {
			this.setError(r);
			return false;
		}
		return true;
	}

	submitForm = () => {
		this.resetError();
		if (!this.validateForm()) {
			return;
		}
		this.setState({ showModal: true });
	}

	onConfirm = async (control) => {
		copyFormFieldsToDataFields(mapping, this.state.data, this.selectedOrgData, fields);
		this.taxHandler.formFieldsToDataArray(this.state.data, this.selectedOrgData.company_tax_dtls);
		try {
			const retData = await serviceRoutines.modify(this.serverInstance, this.accessToken, this.selectedOrgData);
			if (retData !== undefined && retData !== null) {
				this.backHandler("edit");
			}
		}
		catch (e) {
			control.error = true;
			control.errorMsg = e.error_message;
		}
	}

}

export default EditPartners;
