import React from 'react';
import {
	GenericComponent,
	NarrowButton,
	GenericForm,
	FormField,
	MultiRecFormField,
	MultiRec,
	MultiRecHandler,
	fieldValidations,
	getInitialState,
	copyFormFieldsToDataFields,
	copyDataFieldsToFormFields,
	deepCopy,
	raiseError,
	extractNumberStr,
	valuePresent,
	filteredselect,
	filtered_select,
	searchselect,
	search_select,
	document_number,
	documentnumber,
	input_date,
	inputdate,
	genericstring,
	generic_string,
	genericcheckbox,
	generic_checkbox,
	itemid,
	item_id,
	genericdecimal,
	generic_decimal,
	amounttextbox,
	amount_textbox,
	unit_measure,
	unitmeasure,
	taxtype,
	tax_type,
	FileSet,
	file_set,
	place_holder,
	placeholder,
	Modal_generic_content,
	Backdrop,
	Currency,
	currency,
	contact_phone_number,
	contactphonenumber,
	email_id,
	emailid,
	user_name,
	username
} from 'WebUI_Framework';


import deliveryChallanServiceRoutines from "./DeliveryChallanServiceRoutines";
import commonServiceRoutines from "../common/CommonServiceRoutines";
import PartnerList from '../common/PartnerList'
import SalesOrderList from '../common/SalesOrderList'
import Attachments from '../common/Attachments'
import { isSubscriber } from '../common/utils';
//import AddressList from '../common/AddressList';
import AddressFilter from '../common/AddressFilter';
import { formatAddress } from '../common/utils'


const LINE_ITEMS_MREC = "LINE_ITEMS";
const TAX_MREC = "TAX";

const header_fields = {
	order_number: {
		id: "order_number", name: "order_number", label: window.getLabelLiteralWithColon("SO_NUMBER"),
		class: searchselect, component: search_select, mandatory: false
	},

	order_issue_date: {
		id: "order_issue_date", name: "order_issue_date",
		label: window.getLabelLiteralWithColon("SO_ISSUE_DATE"), class: inputdate, component: input_date, mandatory: false
	},

	seller_org_id: {
		id: "seller_org_id", name: "seller_org_id", label: window.getLabelLiteralWithColon("SELLER_ORG_ID"),
		class: genericstring, component: generic_string, mandatory: false
	},

	seller_org_name: {
		id: "seller_org_name", name: "seller_org_name", label: window.getLabelLiteralWithColon("SELLER_ORG"),
		class: searchselect, component: search_select, mandatory: false
	},
	place_holder: {
		id: "place_holder", name: "place_holder", label: "",
		class: placeholder, component: place_holder, mandatory: false
	},

	buyer_org_id: {
		id: "buyer_org_id", name: "buyer_org_id", label: window.getLabelLiteralWithColon("BUYER_ORG"),
		class: filteredselect, component: filtered_select, mandatory: false
	},

	buyer_org_name: {
		id: "buyer_org_name", name: "buyer_org_name", label: window.getLabelLiteralWithColon("BUYER_ORG"),
		class: searchselect, component: search_select, mandatory: false
	},

	delivery_note_number: {
		id: "delivery_note_number", name: "delivery_note_number", label: window.getLabelLiteralWithColon("DELIVERY_CHALLAN_NUMBER"),
		class: documentnumber, component: document_number, mandatory: true
	},

	delivery_note_date: {
		id: "delivery_note_date", name: "delivery_note_date", label: window.getLabelLiteralWithColon("DELIVERY_NOTE_DATE"),
		class: inputdate, component: input_date, mandatory: true
		//, initialValue : "2023-10-06"
	},

	description: {
		id: "description", name: "description", label: window.getLabelLiteralWithColon("DESCRIPTION"),
		class: genericstring, component: generic_string, mandatory: false
		//, initialValue : "2023-10-06"
	},

	expiry_date: {
		id: "expiry_date", name: "expiry_date", label: window.getLabelLiteralWithColon("EXPIRY_DATE"),
		class: inputdate, component: input_date, mandatory: false
	},

	manufacture_date: {
		id: "manufacture_date", name: "manufacture_date", label: window.getLabelLiteralWithColon("MANUFACTURE_DATE"),
		class: inputdate, component: input_date, mandatory: false
	},

	lot_identification_number: {
		id: "lot_identification_number", name: "lot_identification_number",
		label: window.getLabelLiteralWithColon("LOT_IDENTIFICATION_NUMBER"), class: genericstring,
		component: generic_string, mandatory: false
	},
	currency: {
		id: "currency", name: "currency", label: window.getLabelLiteralWithColon("CURRENCY"),
		class: Currency, component: currency, mandatory: true,
	},
	buyer_address_id: {
		id: "buyer_address_id", name: "buyer_address_id", label: window.getLabelLiteralWithColon("BUYER_ADDRESS"),
		class: filteredselect, component: filtered_select, mandatory: true,
	},

	buyer_address: {
		id: "buyer_address", name: "buyer_address", label: window.getLabelLiteralWithColon("BUYER_ADDRESS"),
		class: searchselect, component: search_select, mandatory: true,
	},
	seller_address_id: {
		id: "seller_address_id", name: "seller_address_id", label: window.getLabelLiteralWithColon("SELLER_ADDRESS"),
		class: filteredselect, component: filtered_select, mandatory: true,
	},

	seller_address: {
		id: "seller_address", name: "seller_address", label: window.getLabelLiteralWithColon("SELLER_ADDRESS"),
		class: searchselect, component: search_select, mandatory: true,
	},
	seller_contact_user_name: {
		id: "seller_contact_user_name", name: "seller_contact_user_name", label: window.getLabelLiteralWithColon("SELLER_CONTACT_PERSON"),
		class: username, component: user_name, mandatory: true,
	},

	seller_contact_email_id: {
		id: "seller_contact_email_id", name: "seller_contact_email_id", label: window.getLabelLiteralWithColon("SELLER_CONTACT_EMAIL"),
		class: emailid, component: email_id, mandatory: true,
	},

	seller_contact_phone_num: {
		id: "seller_contact_phone_num", name: "seller_contact_phone_num", label: window.getLabelLiteralWithColon("SELLER_CONTACT_PHONE"),
		class: contactphonenumber, component: contact_phone_number, mandatory: true,
	},

	buyer_contact_user_name: {
		id: "buyer_contact_user_name", name: "buyer_contact_user_name", label: window.getLabelLiteralWithColon("BUYER_CONTACT_PERSON"),
		class: username, component: user_name, mandatory: true,
	},

	buyer_contact_email_id: {
		id: "buyer_contact_email_id", name: "buyer_contact_email_id", label: window.getLabelLiteralWithColon("BUYER_CONTACT_EMAIL"),
		class: emailid, component: email_id, mandatory: true,
	},

	buyer_contact_phone_num: {
		id: "buyer_contact_phone_num", name: "buyer_contact_phone_num", label: window.getLabelLiteralWithColon("BUYER_CONTACT_PHONE"),
		class: contactphonenumber, component: contact_phone_number, mandatory: true,
	},

	attachments: {
		id: "attachments", name: "attachments", label: window.getLabelLiteralWithColon("ATTACHMENTS"),
		class: file_set, component: FileSet, mandatory: false,
	},
}

let header_mapping = [
	["order_number", "order_number"],
	["order_issue_date", "order_issue_date"],
	["seller_org_id", "seller_org_id"],
	["seller_org_name", "seller_org_name"],
	["seller_address_id", "seller_address_id"],
	["buyer_org_id", "buyer_org_id"],
	["buyer_org_name", "buyer_org_name"],
	["buyer_address_id", "buyer_address_id"],
	["delivery_note_number", "delivery_note_number"],
	["delivery_note_date", "delivery_note_date"],
	["seller_contact_user_name", "seller_contact_user_name"],
	["seller_contact_email_id", "seller_contact_email_id"],
	["seller_contact_phone_num", "seller_contact_phone_num"],
	["buyer_contact_user_name", "buyer_contact_user_name"],
	["buyer_contact_email_id", "buyer_contact_email_id"],
	["buyer_contact_phone_num", "buyer_contact_phone_num"],
	["description", "description"],
	["currency", "currency"],
	["attachments", "attachments"]
];

const details_fields = {
	seller_item_id: {
		id: "seller_item_id", name: "seller_item_id", label: window.getLabelLiteralWithColon("SELLER_ITEM_ID"),
		class: itemid, component: item_id, mandatory: true
	},

	lookup_seller_item_id: {
		id: "lookup_seller_item_id", name: "lookup_seller_item_id", label: window.getLabelLiteralWithColon("SELLER_ITEM_ID"),
		class: searchselect, component: search_select, mandatory: false
	},

	ordered_quantity: {
		id: "ordered_quantity", name: "ordered_quantity", label: window.getLabelLiteralWithColon("ORDERED_QUANTITY"),
		class: genericdecimal, component: generic_decimal, mandatory: false
	},

	delivered_quantity: {
		id: "delivered_quantity", name: "delivered_quantity", label: window.getLabelLiteralWithColon("DELIVERED_QUANTITY"),
		class: genericdecimal, component: generic_decimal, mandatory: false
	},

	accepted_quantity: {
		id: "accepted_quantity", name: "accepted_quantity", label: window.getLabelLiteralWithColon("ACCEPTED_QUANTITY"),
		class: genericdecimal, component: generic_decimal, mandatory: false
	},

	dispatched_quantity: {
		id: "dispatched_quantity", name: "dispatched_quantity", label: window.getLabelLiteralWithColon("DISPATCHED_QUANTITY"),
		class: genericdecimal, component: generic_decimal, mandatory: true
	},

	unit_of_measure: {
		id: "unit_of_measure", name: "unit_of_measure", label: window.getLabelLiteralWithColon("UNIT_OF_MEASURE"),
		class: unitmeasure, component: unit_measure, mandatory: true
	},

	delivery_note_remarks: {
		id: "delivery_note_remarks", name: "delivery_note_remarks", label: window.getLabelLiteralWithColon("DELIVERY_NOTE_REMARKS"),
		class: genericstring, component: generic_string, mandatory: false
	},
	receipt_note_remarks: {
		id: "receipt_note_remarks", name: "receipt_note_remarks", label: window.getLabelLiteralWithColon("RECEIPT_NOTE_REMARKS"),
		class: genericstring, component: generic_string, mandatory: false
	},
	seller_item_description: {
		id: "seller_item_description", name: "seller_item_description", label: window.getLabelLiteralWithColon("ITEM_DESCRIPTION"),
		class: genericstring, component: generic_string, mandatory: false
	},
	rate_per_unit: {
		id: "rate_per_unit", name: "rate_per_unit", label: window.getLabelLiteralWithColon("RATE_PER_UNIT"), class: amounttextbox,
		component: amount_textbox, mandatory: true,
	},

	amount: {
		id: "amount", name: "amount", label: window.getLabelLiteralWithColon("AMOUNT"), class: amounttextbox,
		component: amount_textbox, mandatory: false,
	},

	deleted: {
		id: "deleted", name: "deleted", label: window.getLabelLiteralWithColon("DELETED"),
		class: genericcheckbox, component: generic_checkbox, mandatory: false
	}

};

let details_mapping = [
	["seller_item_id", "seller_item_id"],
	["ordered_quantity", null],
	["delivered_quantity", null],
	["dispatched_quantity", "dispatched_quantity"],
	["accepted_quantity", "accepted_quantity"],
	["unit_of_measure", "unit_of_measure"],
	["delivery_note_remarks", "delivery_note_remarks"],
	["receipt_note_remarks", "receipt_note_remarks"],
	["seller_item_description", "seller_item_description"],
	["rate_per_unit", "rate_per_unit"],
	["deleted", "deleted"],
];

const tax_fields = {
	tax_type: {
		id: "tax_type",
		name: "tax_type",
		label: window.getLabelLiteralWithColon("TAX_TYPE"),
		class: taxtype,
		component: tax_type,
		mandatory: false,
	},

	tax_percentage: {
		id: "tax_percentage",
		name: "tax_percentage",
		label: window.getLabelLiteralWithColon("TAX_PERCENTAGE"),
		class: genericdecimal,
		component: generic_decimal,
		mandatory: false,
	},

	tax_amount: {
		id: "tax_amount",
		name: "tax_amount",
		label: window.getLabelLiteralWithColon("TAX_AMOUNT"),
		class: amounttextbox,
		component: amount_textbox,
		mandatory: false,
	},


};

let tax_mapping = [
	["tax_type", "tax_type"]
	, ["tax_percentage", "tax_percentage"]
	, ["tax_amount", null]
];

let additional_mapping = [
	["manufacture_date", "manufacture_date"],
	["lot_identification_number", "lot_identification_number"],
	["expiry_date", "expiry_date"]
]

const TotalAmountContext = React.createContext({ tta: 0, tia: 0, ta: 0 });

class TotalAmount extends React.Component {

	render() {
		let total = this.context;

		const { atype, ...rest } = this.props;

		return <FormField valueSuperseed={total[atype].toString()}  {...rest} />
	}
}

TotalAmount.contextType = TotalAmountContext;

class AddEditDeliveryChallan extends GenericComponent {
	constructor(props) {
		super(props);
		this.state.loading = true;
		this.state.step = 1;

		this.readOnly = false;
		this.subscriberServerInstance = this.session.getServerInstance('SUBSCRIBER');
		this.accessToken = this.session.getAccessToken();
		this.action = props.action;
		this.deliveryNoteId = props.deliveryNoteId;
		this.deliveryChallan = { header: {}, details: [{}] };
		this.logged_in_company_id = this.session.getLoggedInCompanyId();
		this.parent_company_id = this.session.getLoggedInParentCompanyId();
		this.is_subscriber = (this.logged_in_company_id === this.parent_company_id);
		this.page_title = "";
		this.page_title += (this.action === "DELETE") ? window.getLabelLiteral("DELETING") : window.getLabelLiteral(this.action);
		this.expandBuyer(false);
		this.expandSeller(false);
		if (this.action === "ADD") {
			this.state.data.delivery_note_date = this.session.getCurrentDate();
			this.expandBuyer(true);
			this.expandSeller(true);
		}
		else if (this.action === "EDIT" || this.action === "AMEND") {
			this.state.step = 2;
		}
		else if (this.action === "APPROVE" || this.action === "CANCEL" ||
			this.action === "ACCEPT" || this.action === 'REJECT' ||
			this.action === 'APPROVEAMENDMENT') {
			this.state.step = 2;
			this.readOnly = true;
			this.expandBuyer(true);
			this.expandSeller(true);
		}
		else if (this.action === "VIEW") {
			this.expandBuyer(true);
			this.expandSeller(true);
			this.readOnly = true;
			this.state.step = 2;
		}
		else if (this.action === "DELETE") {
			this.readOnly = true;
		}
		this.page_title += " " + window.getLabelLiteral("DELIVERY_CHALLAN_TITLE");

		this.lineItemsHandler = new MultiRecHandler(this.session, details_fields, details_mapping, LINE_ITEMS_MREC);

		this.tax_mrecs = {};

	}

	expandBuyer = (flg) => {
		this.aria_expanded_buyer = flg;
		this.show_buyer = (this.aria_expanded_buyer) ? "show" : "";
		this.aria_collapsed_buyer = (this.aria_expanded_buyer) ? "expanded" : "collapsed";
	}

	expandSeller = (flg) => {
		this.aria_expanded_seller = flg;
		this.show_seller = (this.aria_expanded_seller) ? "show" : "";
		this.aria_collapsed_seller = (this.aria_expanded_seller) ? "expanded" : "collapsed";
	}

	initialState = getInitialState(header_fields);

	state = {
		data: deepCopy(this.initialState),
	}

	backHandler = (retFrom, error) => {
		if (this.isChildMode())
			this.props.returnToParent({
				retFrom, arrayIndex: this.props.arrayIndex,
				selectData: this.deliveryChallan, error
			});
		else console.log("LOADED AS A PARENT");
	}

	fetch = async (deliveryNoteId) => {
		return await deliveryChallanServiceRoutines.fetch(this.session, deliveryNoteId);
	}

	fetchAmended = async (deliveryNoteId) => {
		return await deliveryChallanServiceRoutines.fetchAmended(this.session, deliveryNoteId);
	}

	fetchOrder = async (buyerOrgId, orderIssueDate, orderNumber) => {
		let retData;
		try {
			retData = await deliveryChallanServiceRoutines.fetch_sales_order(this.session, buyerOrgId, orderIssueDate, orderNumber);
			this.order = retData;
		}
		catch (e) {
			//this.setError(raiseError(e.error_message));
			// console.log(e.error_message);
			console.log(e);
			this.order = null;
		}
	}

	delete = async (delivery_challan) => {
		let retData;
		try {
			await deliveryChallanServiceRoutines.delete(this.session, delivery_challan.header);
		}
		catch (e) {
			//this.setError(raiseError(e.error_message));
			throw e;
		}

		return retData
	}

	loadSellerAddress = (data) => {
		data.seller_address = formatAddress(this.invoice.header.seller_address);
	}

	loadBuyerAddress = (data) => {
		data.buyer_address = formatAddress(this.invoice.header.buyer_address);
	}

	populateDefaultInformation = async (data) => {
		try {
			const sellerDetails = await commonServiceRoutines.fetchCompany(this.session, data.seller_org_id);

			if (data.seller_org_name === "")
				data.seller_org_name = sellerDetails.org_name;
			if (data.seller_contact_user_name === "")
				data.seller_contact_user_name = sellerDetails.contact_name;
			if (data.seller_contact_email_id === "")
				data.seller_contact_email_id = sellerDetails.contact_email_id;
			if (data.seller_contact_phone_num === "")
				data.seller_contact_phone_num = sellerDetails.contact_phone_number;

			const sellerAddress = await commonServiceRoutines.fetchDefaultAddress(this.session, data.seller_org_id);

			if (data.seller_address_id === "")
				data.seller_address_id = sellerAddress.address_id;
			if (data.seller_address === "")
				data.seller_address = formatAddress(sellerAddress);

		} catch (e) {
			console.log(this.action);
			console.log(e);
			this.setError(raiseError((e.error_message) ? e.error_message : e.message));
			this.backHandler(this.action, this.error);
		}
		return;
	}

	componentDidMount = async () => {
		let newData_1, newData_2, newData;
		try {
			switch (this.action) {
				case 'ADD':
					newData = deepCopy(this.state.data);
					newData = this.lineItemsHandler.dataArrayToFormFields(this.deliveryChallan.details, newData);
					this.deliveryChallan.header.seller_org_id = this.logged_in_company_id;
					newData.seller_org_id = this.logged_in_company_id;
					await this.populateDefaultInformation(newData);
					this.setState({ loading: false, data: newData });
					break;
				/*eslint no-fallthrough: 0*/
				case 'AMEND':
				case 'CANCEL':
				case 'ACCEPT':
				case 'REJECT':
				case 'VIEW':
				case 'APPROVE':
				case 'EDIT':
				case 'DELETE':
				case 'APPROVEAMENDMENT':
					switch (this.action) {
						/*eslint no-fallthrough: 0*/
						case 'AMEND':
						case 'CANCEL':
						case 'ACCEPT':
						case 'REJECT':
						case 'APPROVEAMENDMENT':
							this.deliveryChallan = await this.fetchAmended(this.deliveryNoteId);
							break;
						default:
							this.deliveryChallan = await this.fetch(this.deliveryNoteId);
							break;
					}

					newData_1 = deepCopy(this.state.data);
					copyDataFieldsToFormFields(header_mapping, this.deliveryChallan.header, newData_1);
					await this.populateDefaultInformation(newData_1);
					await this.populateBuyerOrgInfo(newData_1);
					newData_2 = this.lineItemsHandler.dataArrayToFormFields(this.deliveryChallan.details, newData_1);
					for (let i = 0; i < this.deliveryChallan.details.length; i++) {
						if (valuePresent(this.deliveryChallan.details[i].tax_details)) {
							this.tax_mrecs[TAX_MREC + i] = new MultiRecHandler(this.session, tax_fields, tax_mapping, TAX_MREC + i);
							newData_2 = this.tax_mrecs[TAX_MREC + i].dataArrayToFormFields(this.deliveryChallan.details[i].tax_details, newData_2);
						}
					}

					switch (this.action) {
						case 'DELETE':
							this.setState({ data: newData_2 });
							if (this.deliveryChallan.deleted === true) {
								this.setError(raiseError(window.getLabelLiteral("RECORD_DELETED") + " [" + this.deliveryNoteId + "]"));
								this.backHandler(this.action);
							}
							await this.delete(this.deliveryChallan);
							this.backHandler(this.action);
							break;
						case 'EDIT':
							if (newData_2.order_issue_date !== "" && newData_2.order_number !== "")
								await this.fetchOrder(newData_2.buyer_org_id, newData_2.order_issue_date, newData_2.order_number);
						default:
							this.setState({ loading: false, data: newData_2 });
							break;
					}

					break;
				default:
					this.loadBuyerAddress(newData_2);
					this.loadSellerAddress(newData_2);
					this.setError(raiseError("AddEditDeliveryChallan: Invalid action [" + this.action + "]"));
					this.backHandler(this.action, this.error);
					break;
			}
		}
		catch (e) {
			console.log(this.action);
			console.log(e);
			this.setError(raiseError((e.error_message) ? e.error_message : e.message));
			this.backHandler(this.action, this.error);
		}
	}

	low_computeAmount = (data, i) => {
		let ras = data[this.lineItemsHandler.getFieldIdentifier("rate_per_unit", i)];
		if (!ras) ras = "";
		const rate = Number(extractNumberStr(ras));

		let qs = data[this.lineItemsHandler.getFieldIdentifier("quantity", i)];
		if (!qs) qs = "";
		const quantity = Number(extractNumberStr(qs));

		let dps = data[this.lineItemsHandler.getFieldIdentifier("discount_percentage", i)];
		if (!dps) dps = "";
		const dp = Number(extractNumberStr(dps));

		let ds = data[this.lineItemsHandler.getFieldIdentifier("discount_amount", i)];
		if (!ds) ds = "";
		const d = Number(extractNumberStr(ds));

		return ((rate * ((100 - dp) / 100)) - d) * quantity;
	}

	low_computeTaxAmount = (ia, data, i, detail_i) => {
		let tps = data[this.tax_mrecs[TAX_MREC + detail_i].getFieldIdentifier("tax_percentage", i)];
		if (!tps) tps = "";
		const tp = Number(extractNumberStr(tps));
		const ta = (ia * tp) / 100;

		return ta;
	}

	computeTotalAmount = () => {
		let ta = 0;
		let tia = 0;
		let tta = 0;

		try {
			const dc = this.lineItemsHandler.getCount(this.state.data);
			for (let i = 0; i < dc; i++) {
				const ia = this.low_computeAmount(this.state.data, i);
				ta += ia;
				tia += ia;
				if (this.tax_mrecs[TAX_MREC + i]) {
					const tc = this.tax_mrecs[TAX_MREC + i].getCount(this.state.data);
					for (let j = 0; j < tc; j++) {
						const xa = (this.low_computeTaxAmount(ia, this.state.data, j, i));
						ta += xa;
						tta += xa;
					}
				}
			}
		} catch (e) {
			console.log(e);
			ta = 0;
			tta = 0;
			tia = 0;
		}

		return { ta, tta, tia };
	}

	populateBuyerOrgInfo = async (data) => {
		const buyerDetails = await commonServiceRoutines.fetchCompany(this.session, data.buyer_org_id);

		data.buyer_org_name = buyerDetails.org_name;
		data.buyer_contact_user_name = buyerDetails.contact_name;
		data.buyer_contact_email_id = buyerDetails.contact_email_id;
		data.buyer_contact_phone_num = buyerDetails.contact_phone_number;

		const buyerAddress = await commonServiceRoutines.fetchDefaultAddress(this.session, data.buyer_org_id);

		data.buyer_address_id = buyerAddress.address_id;
		data.buyer_address = formatAddress(buyerAddress);
	}

	addNewRow = () => {
		let data = this.state.data;
		this.resetError();
		let [newData, new_list] = this.lineItemsHandler.addMultiRecRow(data, this.deliveryChallan.details);
		this.deliveryChallan.details = new_list;
		this.setState({ data: newData });
	}

	button_inputs = [
		{
			literal: "Add",
			func: () => this.addNewRow()
		}
	]

	deleteRow = (rowData, i) => {
		if (this.deliveryChallan.details[i].version === null ||
			this.deliveryChallan.details[i].version === undefined) {
			this.resetError();
			let [newData, updated_list] = this.lineItemsHandler.deleteMultiRecRow(this.state.data, this.deliveryChallan.details, i);
			this.deliveryChallan.details = updated_list;
			this.setState({ data: newData });
		}
		else {
			this.deliveryChallan.details[i].deleted = true;
		}
	}

	computeAmount = (data, i) => {
		let rate;
		try {
			rate = Number(extractNumberStr(data[this.lineItemsHandler.getFieldIdentifier("rate_per_unit", i)].trim(), true));
		} catch (e) {
			console.log(e);
			return "";
		}
		let quantity = Number(data[this.lineItemsHandler.getFieldIdentifier("dispatched_quantity", i)]);
		if (isNaN(quantity)) return "";

		return (rate * quantity).toString();
	}

	deleteTaxRow = (rowData, i, detail_i) => {
		this.resetError();
		let [newData, updated_list] =
			this.tax_mrecs[TAX_MREC + detail_i].deleteMultiRecRow(this.state.data, this.deliveryChallan.details[detail_i].tax_details, i);
		this.deliveryChallan.details[detail_i].tax_details = updated_list;
		if (this.deliveryChallan.details[detail_i].tax_details.length === 0) {
			this.deliveryChallan.details[detail_i].tax_details = undefined;
			delete this.tax_mrecs[TAX_MREC + i];
		}

		this.setState({ data: newData });
	}

	getTaxHeader = () => {
		let key = 0;
		return (
			<tr key={++key}>
				<th key={++key}>{window.getLabelLiteral("TAX_TYPE")}</th>
				<th key={++key}>{window.getLabelLiteral("TAX_PERCENTAGE")}</th>
				<th key={++key}>{window.getLabelLiteral("TAX_AMOUNT")}</th>
				{(!this.readOnly) &&
					<th key={++key}>{window.getLabelLiteral("DELETE")}</th>
				}
			</tr>
		);
	}

	computeTaxAmount = (deliveryChallanAmount, data, i, detail_i) => {
		let ta = 0
		try {
			const ia = Number(extractNumberStr(deliveryChallanAmount));
			const tp = Number(extractNumberStr(data[this.tax_mrecs[TAX_MREC + detail_i].getFieldIdentifier("tax_percentage", i)]));
			ta = (ia * tp) / 100;
		} catch (e) {
			/* We ignore any errors here */
			console.log(e);
		}

		return ta;
	}

	getTaxRow = (rowData, index, detail_i, deliveryChallanAmount) => {
		const i = index - 1;
		let data = deepCopy(this.state.data);
		let key = 0;
		let style = { borderBottom: "0px solid" };

		const ca = (this.computeTaxAmount(deliveryChallanAmount, data, i, detail_i)).toString();

		let r = <React.Fragment key={index}>
			<tr>
				<td key={++key} style={style}>
					<MultiRecFormField field={tax_fields.tax_type} controlProps={this.controlProps} index={i}
						multiRecId={TAX_MREC + detail_i}
						w_pcnt="w-100"
						style={{ width: "100%" }}
						disabled={this.readOnly} />
				</td>
				<td key={++key} style={style}>
					<MultiRecFormField field={tax_fields.tax_percentage} controlProps={this.controlProps} index={i}
						formattingInputs={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
						multiRecId={TAX_MREC + detail_i}
						style={{ width: "100%", textAlign: "right", marginLeft: "1rem" }}
						disabled={this.readOnly} />
				</td>
				<td key={++key} style={style}>
					<MultiRecFormField field={tax_fields.tax_amount} controlProps={this.controlProps} index={i}
						currency={data.currency}
						valueSuperseed={ca}
						multiRecId={TAX_MREC + detail_i}
						style={{ width: "100%", textAlign: "right" }}
						readOnly={true} />
				</td>
				{!(this.readOnly) &&
					<td key={++key} style={style}>
						<div style={{ paddingLeft: "20px", cursor: "pointer" }}>
							<span onClick={() => { return this.deleteTaxRow(rowData, i, detail_i); }}>
								<i className="bi bi-trash3" style={{ fontSize: 20 }}></i>
							</span>
						</div>
					</td>
				}
			</tr>
		</React.Fragment>;

		return r;
	}

	addTax = (i) => {
		let data = deepCopy(this.state.data);
		if (!(this.deliveryChallan.details[i].tax_details)) {
			this.deliveryChallan.details[i].tax_details = [];
			this.tax_mrecs[TAX_MREC + i] = new MultiRecHandler(this.session, tax_fields, tax_mapping, TAX_MREC + i);
		}

		let [newData, new_list] = this.tax_mrecs[TAX_MREC + i].addMultiRecRow(data, this.deliveryChallan.details[i].tax_details);
		this.deliveryChallan.details[i].tax_details = new_list;
		this.setState({ data: newData });
	}

	getHeader = () => {
		let key = 0;
		return (
			<tr key={++key}>
				<th key={++key}>{window.getLabelLiteral("SELLER_ITEM_ID")}</th>
				<th key={++key}>{window.getLabelLiteral("DELIVERY_NOTE_REMARKS")}</th>
				{(valuePresent(this.deliveryChallan.header.entity_state) && this.deliveryChallan.header.entity_state !== "0") &&
					<th key={++key}>{window.getLabelLiteral("RECEIPT_NOTE_REMARKS")}</th>
				}
				<th key={++key}>{window.getLabelLiteral("SELLER_ITEM_DESCRIPTION")}</th>
				{/*(this.action === "ADD") && <th key={++key}>{window.getLabelLiteral("ORDERED_QUANTITY")}</th>*/}
				<th key={++key}>{window.getLabelLiteral("DISPATCHED_QUANTITY")}</th>
				{(this.action !== "ADD" &&
					(this.deliveryChallan.header.delivery_note_status === "4" ||
						this.deliveryChallan.header.delivery_note_status === "5"))
					&& <th key={++key}>{window.getLabelLiteral("ACCEPTED_QUANTITY")}</th>}
				<th key={++key}>{window.getLabelLiteral("UNIT_OF_MEASURE")}</th>
				<th key={++key}>{window.getLabelLiteral("RATE_PER_UNIT")}</th>
				<th key={++key}>{window.getLabelLiteral("AMOUNT")}</th>
				{(!this.readOnly) &&
					<>
						<th key={++key}>{window.getLabelLiteral("DELETE")}</th>
						<th key={++key}>{window.getLabelLiteral("ACTIONS")}</th>
					</>
				}
			</tr>
		);
	}

	showAdditional(i) {
		this.idx = i;
		let line_item = this.deliveryChallan.details[this.idx];
		let data = deepCopy(this.state.data);
		if (line_item.expiry_date || line_item.manufacture_date || line_item.lot_identification_number) {
			copyDataFieldsToFormFields(additional_mapping, line_item, data);
			this.setState({ data: data });
		}
		this.setState({ showModal: true })
	}

	getRow = (data, index) => {
		const i = index - 1;
		let delete_button = 1;
		if (data.version >= 0)
			delete_button = 2;
		let taxColSpan = "5";
		let deliveryNoteStatus = (valuePresent(this.deliveryChallan.header.delivery_note_status)) ?
									Number(this.deliveryChallan.header.delivery_note_status)
									:
									0;
		if (!valuePresent(this.deliveryChallan.header.entity_state) || this.deliveryChallan.header.entity_state === "0")
			taxColSpan = "4";
		else if (deliveryNoteStatus >= 2)
			taxColSpan = "6";

		let view_delete_option = 0;
		if (!this.readOnly && delete_button === 1) view_delete_option = 1;
		else if (!this.readOnly && delete_button === 2) view_delete_option = 2;

		let select = false;
		let selectList = [];
		if (this.order) {
			select = true;
			selectList = deepCopy(this.order.order_line_item);
		}

		selectList.forEach(item => {
			if (item.hasOwnProperty('seller_item_id')) {
				item['item_id'] = item['seller_item_id'];
				item['item_description'] = item['seller_item_description'];
				delete item['seller_item_id'];
				delete item['seller_item_description'];
			}
		});

		let itemIdChange = (event, i) => {
			let data = deepCopy(this.state.data);
			/*
			 * We have to set item id also because the state change done in 
			 * genericcomponent will get overwritten by the following change
			 */
			let selected_i = event.target.selectedIndex;

			if (selected_i === 0) {
				data[this.lineItemsHandler.getFieldIdentifier("seller_item_id", i)] = "";
				data[this.lineItemsHandler.getFieldIdentifier("seller_item_description", i)] = "";
				data[this.lineItemsHandler.getFieldIdentifier("unit_of_measure", i)] = "";
				data[this.lineItemsHandler.getFieldIdentifier("ordered_quantity", i)] = "";
				data[this.lineItemsHandler.getFieldIdentifier("rate_per_unit", i)] = "";
				data[this.lineItemsHandler.getFieldIdentifier("dispatched_quantity", i)] = "";
			} else {
				let selected_rec = selectList[selected_i-1];
				data[this.lineItemsHandler.getFieldIdentifier("seller_item_id", i)] = selected_rec.item_id;
				data[this.lineItemsHandler.getFieldIdentifier("seller_item_description", i)] = selected_rec.item_description;
				data[this.lineItemsHandler.getFieldIdentifier("unit_of_measure", i)] = selected_rec.unit_of_measure;
				data[this.lineItemsHandler.getFieldIdentifier("ordered_quantity", i)] = selected_rec.quantity;
				data[this.lineItemsHandler.getFieldIdentifier("rate_per_unit", i)] = selected_rec.rate_per_unit;
				data[this.lineItemsHandler.getFieldIdentifier("dispatched_quantity", i)] =
								(selected_rec.quantity - selected_rec.delivered_quantity > 0) ?
									String(selected_rec.quantity - selected_rec.delivered_quantity) : "";

			}
			this.setState({ data });
		}
		let key = 0;

		let data1 = deepCopy(this.state.data);
		const ca = this.computeAmount(data1, i);
		let style = valuePresent(this.deliveryChallan.details[i].tax_details) ? { borderBottom: "0px solid" } : undefined;

		let r =
			<React.Fragment key={index}>
				<tr key={++key}>
					<td style={style}>
						{(select) &&
							<MultiRecFormField field={details_fields.seller_item_id} controlProps={this.controlProps} index={i}
								readOnly={(this.readOnly) || (data.version !== undefined && data.version !== null)}
								select={select} selectList={selectList}
								style={{ width: "100%" }}
								multiRecId={LINE_ITEMS_MREC}
								onChange={(event) => { return itemIdChange(event, i); }} />
						}
						{!(select) &&
							<MultiRecFormField field={details_fields.seller_item_id}
								controlProps={this.controlProps} index={i}
								readOnly={(this.readOnly) || (data.version !== undefined && data.version !== null)}
								multiRecId={LINE_ITEMS_MREC}
								style={{ width: "100%" }}
							/>
						}
					</td>
					<td style={style}>
						<MultiRecFormField
							field={details_fields.delivery_note_remarks}
							controlProps={this.controlProps}
							index={i}
							multiRecId={LINE_ITEMS_MREC}
							style={{ width: "100%", textAlign: "left" }}
							readOnly={this.readOnly} />
					</td>
					{(valuePresent(this.deliveryChallan.header.entity_state) && this.deliveryChallan.header.entity_state !== "0") &&
					<td style={style}>
						<MultiRecFormField field={details_fields.receipt_note_remarks} controlProps={this.controlProps} index={i}
							multiRecId={LINE_ITEMS_MREC}
							style={{ width: "75%" }}
							readOnly={true}
						/>
					</td>
					}
					<td style={style}>
						<MultiRecFormField
							field={details_fields.seller_item_description}
							controlProps={this.controlProps}
							index={i}
							multiRecId={LINE_ITEMS_MREC}
							style={{ width: "100%", textAlign: "left" }}
							readOnly={this.readOnly} />
					</td>
					{/*(this.action === "ADD" && 1 !== 1) &&
						<td style={style}>
							<MultiRecFormField field={details_fields.ordered_quantity}
								controlProps={this.controlProps} index={i}
								style={{ width: "100%", textAlign: "right" }}
								multiRecId={LINE_ITEMS_MREC}
								readOnly={this.readOnly || select === true} />
						</td>
					*/}
					<td style={style}>
						<MultiRecFormField field={details_fields.dispatched_quantity}
							controlProps={this.controlProps}
							index={i}
							multiRecId={LINE_ITEMS_MREC}
							style={{ width: "100%", textAlign: "right" }}
							readOnly={this.readOnly} />
					</td>
					{
						(this.action !== "ADD" &&
							(this.deliveryChallan.header.delivery_note_status === "4" ||
								this.deliveryChallan.header.delivery_note_status === "5")) &&
						<td style={style}>
							<MultiRecFormField field={details_fields.accepted_quantity}
								controlProps={this.controlProps}
								index={i}
								multiRecId={LINE_ITEMS_MREC}
								style={{ width: "80%", textAlign: "right" }}
								readOnly={this.readOnly || select === true} />
						</td>
					}
					<td style={style}>
						<MultiRecFormField field={details_fields.unit_of_measure} controlProps={this.controlProps} index={i}
							multiRecId={LINE_ITEMS_MREC}
							disabled={this.readOnly || select === true} style={{ width: "100%" }}
						/>
					</td>
					<td key={++key} style={style}>
						<MultiRecFormField field={details_fields.rate_per_unit} controlProps={this.controlProps} index={i}
							currency={data1.currency}
							multiRecId={LINE_ITEMS_MREC}
							style={{ width: "100%", textAlign: "right" }}
							readOnly={this.readOnly} />
					</td>
					<td key={++key} style={style}>
						<MultiRecFormField field={details_fields.amount} controlProps={this.controlProps} index={i}
							currency={data1.currency}
							value={ca}
							multiRecId={LINE_ITEMS_MREC}
							style={{ width: "100%", textAlign: "right" }}
							readOnly={true} />
					</td>
					{(view_delete_option === 1) &&
						<>
							<td style={style}>
								<div className="mt-0" style={{ cursor: "pointer" }}>
									<span onClick={() => { return this.deleteRow(data, i); }}>
										<i className="bi bi-trash3" style={{ padding: "1rem", fontSize: 20 }}></i>
									</span>
								</div>
							</td>
						</>
					}
					{(view_delete_option === 2) &&
						<>
							<td style={style}>
								<div className="ml-0 mt-1" style={{ paddingLeft: "30px" }}>
									<MultiRecFormField field={details_fields.deleted} controlProps={this.controlProps}
										multiRecId={LINE_ITEMS_MREC}
										style={{ paddingLeft: "12px" }} index={i} disabled={this.readOnly} />
								</div>
							</td>
						</>
					}
					{(!this.readOnly) &&
						<>
							<td style={style}>
								<div className="mt-0" style={{ cursor: "pointer" }}>
									<span onClick={() => { return this.showAdditional(i) }} title="add additional details">
										<i className="bi bi-file-plus" style={{ padding: "1rem", fontSize: 20 }}></i>
									</span>
								</div>
							</td>
							<td key={++key} style={style}>
								<div style={{ cursor: "pointer" }}>
									<span onClick={() => { return this.addTax(i); }} data-toggle="tooltip" title="Tax">
										<i className="bi bi-plus-circle" style={{ fontSize: 20 }}></i>
									</span>
								</div>
							</td>
						</>

					}
				</tr>
				{(valuePresent(this.deliveryChallan.details[i].tax_details) && this.deliveryChallan.details[i].tax_details.length > 0) &&
					<tr key={++key}>
						<td key={++key} colSpan={taxColSpan}></td>
						<td key={++key} colSpan={(this.action === "ADD" || this.action === "EDIT") ? "4" : "3"} className="m-0 p-0">
							<MultiRec key={++key} tableClass={"table table-condensed"}
								getRow={(rowdata, index, pn, k, ainfo) => { return this.getTaxRow(rowdata, index, i, ainfo); }}
								getHeader={this.getTaxHeader}
								data={this.deliveryChallan.details[i].tax_details}
								buttons={null}
								tbodyStyle={{ borderTop: "2px solid", borderColor: "LightGray" }}
								additionalInfo={ca}
							/>
						</td>
					</tr>
				}
			</React.Fragment>
		return r;
	}

	getFooter = () => {
		const style1 = { borderBottom: "0px", margin: "0px" };
		const style2 = { borderBottom: "0px", textAlign: "right", margin: "0px" };
		let key = 0;
		return <React.Fragment>
			<tr key={++key}>
				<th key={++key} colSpan="6" style={style1}></th>
				<th key={++key} colSpan="1" style={style2}>
					{window.getLabelLiteral("INVOICE_AMOUNT")}
				</th>
				<th key={++key} colSpan="1" style={style1}>
					<TotalAmount
						atype="tia"
						field={header_fields.total_invoice_amount}
						style={{ width: "100%", textAlign: "right", fontWeight: "bold" }}
						currency={this.state.data.currency}
						controlProps={this.controlProps}
						readOnly={true}
					/>
				</th>
				<th key={++key} style={style1} />
				<th key={++key} style={style1} />
			</tr>
			<tr key={++key}>
				<th key={++key} colSpan="6" style={style1}></th>
				<th key={++key} colSpan="1" style={{ borderBottom: "1px solid", textAlign: "right" }}>
					{window.getLabelLiteral("TAX_AMOUNT")}
				</th>
				<th key={++key} colSpan="1" style={{ borderBottom: "1px solid" }}>
					<TotalAmount
						atype="tta"
						field={header_fields.total_tax_amount}
						style={{ width: "100%", textAlign: "right", fontWeight: "bold" }}
						currency={this.state.data.currency}
						controlProps={this.controlProps}
						readOnly={true}
					/>
				</th>
				<th key={++key} style={style1} />
				<th key={++key} style={style1} />
			</tr>
			<tr key={++key}>
				<th key={++key} colSpan="6" style={style1}></th>
				<th key={++key} colSpan="1" style={style2}>
					{window.getLabelLiteral("TOTAL_AMOUNT")}
				</th>
				<th key={++key} colSpan="1"
					style={style1}>
					<TotalAmount
						atype="ta"
						field={header_fields.total_amount}
						style={{ width: "100%", textAlign: "right", fontWeight: "bold" }}
						currency={this.state.data.currency}
						controlProps={this.controlProps}
						readOnly={true}
					/>
				</th>
				<th key={++key} style={style1} />
				<th key={++key} style={style1} />
			</tr>
		</React.Fragment>;
	}

	partnerSelectOption = async (org_id, org_name) => {
		let data = deepCopy(this.state.data)
		data.buyer_org_name = org_name;
		data.buyer_org_id = org_id;
		if (org_id !== undefined && org_id !== null && org_id !== '')
			await this.populateBuyerOrgInfo(data);
		this.setState({ data });
	}

	populateBuyerInfoFromOrder = (data, order) => {
		data.buyer_org_name = order.sales_order_header.buyer_org_name;
		data.buyer_contact_user_name = order.sales_order_header.billing_contact_user_name;
		data.buyer_contact_email_id = order.sales_order_header.billing_contact_email_id;
		data.buyer_contact_phone_num = order.sales_order_header.billing_contact_phone_num;

		data.buyer_address_id = order.sales_order_header.billing_address_id;
		data.buyer_address = formatAddress(order.sales_order_header.billing_address);
	}

	soSelectOption = async (order_number, order_issue_date, seller_org_id, buyer_org_id, buyer_org_name) => {
		let data = deepCopy(this.state.data)
		data.order_number = order_number;
		data.order_issue_date = order_issue_date;
		if (isSubscriber(this.session) && valuePresent(buyer_org_id) && buyer_org_id !== "") {
			data.buyer_org_id = buyer_org_id;
			data.buyer_org_name = buyer_org_name;
		}
		//this.setState({  data });
		this.setState( { loading : true });
		try {
			await this.fetchOrder(data.buyer_org_id, data.order_issue_date, data.order_number);

			if (this.order) {
				this.populateBuyerInfoFromOrder(data, this.order);
				/* this.populateSellerInfoFromOrder(data, this.order);
				 * Should this be populated from the order or should it be
				 * as per the ORG setup of the self organization
				 * Since the document is originating from here
				 * the self organiation is the owner of the info and
				 * the info present in order is only reference for the buyer
				 */
			}

			/* This is to only populate those fields not in order */
			/* This condition is less likely to happen if the Sales order
			 * originates from within BIOP */
			if (!(data.buyer_address_id) ||data.buyer_address_id === "" ||
				!(data.buyer_contact_user_name) || data.buyer_contact_user_name === "" ||
				!(data.buyer_contact_email_id) || data.buyer_contact_email_id === "" ||
				!(data.buyer_contact_phone_num) || data.buyer_contact_phone_num === "" ) {
				let new_data = deepCopy(data);
				if (valuePresent(buyer_org_id) && buyer_org_id !== '') {
					await this.populateBuyerOrgInfo(new_data);
					if (!(data.buyer_address_id) || data.buyer_address_id === "") {
						data.buyer_address_id = new_data.data.buyer_address_id;
						data.buyer_address = new_data.data.buyer_address;
					}
					if (!(data.buyer_contact_user_name) || data.buyer_contact_user_name === "") {
						data.buyer_contact_user_name = new_data.buyer_contact_user_name;
					}
					if (!(data.buyer_contact_email_id) || data.buyer_contact_email_id === "") {
						data.buyer_contact_email_id = new_data.buyer_contact_email_id;
					}
					if (!(data.buyer_contact_phone_num) || data.buyer_contact_phone_num === "") {
						data.buyer_contact_phone_num = new_data.buyer_contact_phone_num;
					}
				}
			}

			data.currency = (this.order?.sales_order_header?.currency)? this.order.sales_order_header.currency : '';

			this.setState({ loading:false,  data });
		} catch (e) {
			console.log(this.action);
			console.log(e);
			this.setState( { loading : false } );
			this.setError(raiseError((e.error_message)?e.error_message:e.message));
			//this.backHandler(this.action, this.error);
		}
	}

	selectAddressIdSeller = (address_id, formatted_address, address) => {
		let data = deepCopy(this.state.data);
		data.seller_address = formatted_address;
		data.seller_address_id = address_id;
		this.setState({ data });
	}

	clearSelectionSellerAddress = () => {
		let data = deepCopy(this.state.data);
		data.seller_address = ''
		data.seller_address_id = ''
		this.setState({ data });
	}

	selectAddressIdBuyer = (address_id, formatted_address, address) => {
		let data = deepCopy(this.state.data);
		data.buyer_address = formatted_address;
		data.buyer_address_id = address_id;
		this.setState({ data });
	};

	clearSelectionBuyerAddress = () => {
		let data = deepCopy(this.state.data);
		data.buyer_address = ''
		data.buyer_address_id = ''
		this.setState({ data });
	}

	proceed = async () => {
		this.resetError();
		let r = fieldValidations(header_fields, this.state.data);
		if (!r.status) {
			this.setError(r);
			return false;
		}
		let data = deepCopy(this.state.data);
		if (this.state.data.order_issue_date !== "" && this.state.data.order_number !== "") {
			await this.fetchOrder(this.state.data.buyer_org_id, this.state.data.order_issue_date, this.state.data.order_number);
			if (this.order) {
				data.buyer_org_id = this.order.sales_order_header.buyer_org_id;
			}
		}
		else {
			this.order = null
		}
		this.setState({ step: 2, data });
	}

	closeModal = () => {
		let data = deepCopy(this.state.data);
		data.expiry_date = "";
		data.manufacture_date = "";
		data.lot_identification_number = "";
		this.setState({ data: data });
		this.setState({ showModal: false });
	}

	onConfirm = () => {
		let data = deepCopy(this.state.data);
		if (data.expiry_date === "" && data.manufacture_date === "" && data.lot_identification_number === "") {
			return;
		}
		copyFormFieldsToDataFields(additional_mapping, this.state.data, this.deliveryChallan.details[this.idx], details_fields);
		data.expiry_date = "";
		data.manufacture_date = "";
		data.lot_identification_number = "";
		this.setState({ data: data });
		this.setState({ showModal: false });

	}

	data_render = () => {
		let modalContent = <>
			<div className='row'>
				<div className='col-sm-6'>
					<FormField field={header_fields.expiry_date} controlProps={this.controlProps}
						readOnly={this.readOnly} />
				</div>
				<div className='col-sm-6'>
					<FormField field={header_fields.manufacture_date} controlProps={this.controlProps}
						readOnly={this.readOnly} />
				</div>
				<div className='col-sm-6'>
					<FormField field={header_fields.lot_identification_number} controlProps={this.controlProps}
						readOnly={this.readOnly} />
				</div>
			</div>
		</>
		let buttons = [];
		let key = 0;
		let datablk = <div className="container-fluid pt-0 m-0 pe-2">
			<div className="row  p-0 m-0 g-0 " >
				<div className="col-sm-12 h6 text-left" >{this.getBreadcrumb([window.getLabelLiteral(this.action)])}</div>

				<div className="col-sm-6" >
					<SalesOrderList
						selectOption={this.soSelectOption}
						field={header_fields.order_number}
						issueDateFieldName="order_issue_date"
						controlProps={this.controlProps}
						onChange={this.soOnChange}
						readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
					/>
				</div>
				<div className="col-sm-6" >
					<FormField field={header_fields.order_issue_date} controlProps={this.controlProps}
						readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))} />
				</div>
				<div className="col-sm-6" >
					<FormField field={header_fields.delivery_note_number} controlProps={this.controlProps}
						readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
					/>
				</div>
				<div className="col-sm-6" >
					<FormField field={header_fields.delivery_note_date} controlProps={this.controlProps}
						readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))} />
				</div>
				<div className="col-sm-6" >
					<FormField field={header_fields.description} controlProps={this.controlProps}
						readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
					/>
				</div>

				<div className="col-sm-6" >
					<FormField field={header_fields.currency} controlProps={this.controlProps}
						readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
					/>
				</div>

				<div className="col-sm-6 mt-2 mb-3 ">
					<Attachments
						field={header_fields.attachments}
						controlProps={this.controlProps}
						readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
					/>
				</div>

				<div className="accordion accordion-flush" id="buyerdetails">
					<div className="accordion-item">
						<h2 className="accordion-header " id="buyerdetailsdata">
							<div className="col-sm-12 h4 text-left">
								<div className="col-sm-2">
									<button className=
										{`accordion-button btn link-dark my-0 py-0 mx-0 gx-0 px-0 ${this.aria_collapsed_buyer}`}
										id="collapseOne-button"
										style={{
											color: "black",
											backgroundColor: "white",
										}}
										type="button" data-bs-toggle="collapse"
										data-bs-target="#collapseOne" aria-expanded={this.aria_expanded_buyer}
										aria-controls="collapseOne">
										<h5>Buyer Details</h5>
									</button>
								</div>
							</div>
						</h2>
						<div id="collapseOne" className={`accordion-collapse collapse ${this.show_buyer}`}
							aria-labelledby="buyerdetailsdata" data-bs-parent="#buyerdetails">
							<div className={`accordion-body mx-0 gx-0 px-0`} id="buyerdetails-1">
								<div className="row	p-0 m-0 g-0 ">
									<div className="col-sm-6" >
										<PartnerList
											field={header_fields.buyer_org_id}
											selectOption={this.partnerSelectOption}
											controlProps={this.controlProps}
											relationship='D'
											readOnly={((this.readOnly) || (this.state.step > 1) ||
												((this.action !== "ADD") && (this.action !== "FORMINVOICE")))}
										/>
									</div>
									<div className="col-sm-6">
										<AddressFilter
											field={header_fields.buyer_address_id}
											controlProps={this.controlProps}
											orgId={this.state.data.buyer_org_id}
											selectOption={this.selectAddressIdBuyer}
											clearSelection={this.clearSelectionBuyerAddress}
											readOnly={this.readOnly}
                                            dependency={[this.state.data.buyer_org_id]}
										/>
									</div>
									<div className="col-sm-6">
										<FormField
											field={header_fields.buyer_contact_user_name}
											controlProps={this.controlProps}
											readOnly={this.readOnly}
										/>
									</div>
									<div className="col-sm-6">
										<FormField
											field={header_fields.buyer_contact_email_id}
											controlProps={this.controlProps}
											readOnly={this.readOnly}
										/>
									</div>
									<div className="col-sm-6">
										<FormField
											field={header_fields.buyer_contact_phone_num}
											controlProps={this.controlProps}
											readOnly={this.readOnly}
										/>
									</div>
								</div>
								<div className="col-sm-12">
									&nbsp;
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="accordion accordion-flush" id="sellerdetails">
					<div className="accordion-item">
						<h2 className="accordion-header " id="sellerdetailsdata">
							<div className="col-sm-12 h4 text-left">
								<div className="col-sm-2">
									<button
										className=
										{`accordion-button btn link-dark my-0 py-0 mx-0 gx-0 px-0 ${this.aria_collapsed_seller}`}
										id="collapseTwo-button"
										type="button" data-bs-toggle="collapse"
										style={{
											color: "black",
											backgroundColor: "white",
										}}
										data-bs-target="#collapseTwo" aria-expanded={this.aria_expanded_seller}
										aria-controls="collapseTwo">
										<h5>Seller Details</h5>
									</button>
								</div>
							</div>
						</h2>
						<div id="collapseTwo" className={`accordion-collapse collapse ${this.show_seller}`}
							aria-labelledby="sellerdetailsdata" data-bs-parent="#sellerdetails">
							<div className={`accordion-body mx-0 gx-0 px-0`} id="sellerdetails-1">
								<div className="row	p-0 m-0 g-0 ">
									<div className="col-sm-6">
										<AddressFilter
											field={header_fields.seller_address_id}
											controlProps={this.controlProps}
											orgId={this.logged_in_company_id}
											selectOption={this.selectAddressIdSeller}
											clearSelection={this.clearSelectionSellerAddress}
											readOnly={((this.readOnly) || (this.state.step > 1) ||
												((this.action !== "ADD") && (this.action !== "FORMINVOICE")))}
                                            dependency={[this.state.data.seller_org_id]}
										/>
									</div>
									<div className="col-sm-6">
										<FormField
											field={header_fields.seller_contact_user_name}
											controlProps={this.controlProps}
											readOnly={this.readOnly}
										/>
									</div>
									<div className="col-sm-6">
										<FormField
											field={header_fields.seller_contact_email_id}
											controlProps={this.controlProps}
											readOnly={this.readOnly}
										/>
									</div>
									<div className="col-sm-6">
										<FormField
											field={header_fields.seller_contact_phone_num}
											controlProps={this.controlProps}
											readOnly={this.readOnly}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>


				{(this.state.step === 1) &&
					<div className="col-sm-6 mt-4 d-flex justify-content-right ">
						<NarrowButton value="Proceed" onClick={this.proceed} />
						<NarrowButton value="Back" onClick={() => { return this.backHandler("BACK"); }} key={(++key).toString()} />
					</div>
				}

				{(this.state.step === 2) &&
					<div className='col-sm-12'>
						<div style={{ margin: 0, padding: 0, height: "25px" }}></div>
						<MultiRec
							getRow={this.getRow}
							getHeader={this.getHeader}
							data={this.deliveryChallan.details}
							buttons={(this.readOnly) ? null : this.button_inputs}
							tfootStyle={{borderTop:"0.1rem solid"}}
							tbodyStyle={{borderTop:"2.5px solid"}}
						/>
					</div>
				}
			</div>

			{(this.state.step === 2) &&
				<div className="row  p-0 m-0 g-0 " >
					<div className="col-sm-6 mt-4 d-flex justify-content-right ">
						{!(this.readOnly) &&
							<NarrowButton value="Submit" onClick={this.submitForm} key={(++key).toString()} />
						}
						{(this.readOnly && (this.action === "APPROVE")) &&
							<NarrowButton value="Approve" onClick={this.submitForm} key={(++key).toString()} />
						}
						{(this.readOnly && (this.action === "ACCEPT")) &&
							<NarrowButton value="Accept" onClick={this.submitForm} key={(++key).toString()} />
						}
						{(this.readOnly && (this.action === "CANCEL")) &&
							<NarrowButton value="Cancel" onClick={this.submitForm} key={(++key).toString()} />
						}
						<NarrowButton value="Back" onClick={() => { return this.backHandler("BACK"); }} key={(++key).toString()} />
					</div>
				</div>
			}

			{(this.state.showModal) && <Modal_generic_content
				title="Add Additional Information"
				btn1="Confirm"
				btn2="Cancel"
				onClick={this.closeModal}
				onConfirm={this.onConfirm}
				modalContent={modalContent}
			/>
			}
			{(this.state.showModal) && <Backdrop />}
		</div>

		let form = <GenericForm datablk={datablk} buttons={buttons} controlProps={this.controlProps} {...this.props} />
		return form;
	}

	loading_render = () => {
		let buttons = [];

		let datablk = <div className="container pt-0 m-0 ">
			<div className="row  p-0 m-0 g-0 " >
				{/*<div className="col-sm-12 h4 text-center" >{this.page_title}</div>*/}
				<div className="d-flex justify-content-evenly">
					<div className="spinner-border text-dark" role="status">
						<span className="sr-only">Loading...</span>
					</div>
				</div>
			</div>

		</div>

		let form = <GenericForm datablk={datablk} buttons={buttons} controlProps={this.controlProps} {...this.props} />
		return form;
	}

	render = () => {
		if (this.state.loading) {
			return this.loading_render();
		}
		else {
			return this.data_render();
		}
	}

	validateForm = () => {
		let r = fieldValidations(header_fields, this.state.data);
		if (!r.status) {
			console.log(r);
			this.setError(r);
			return false;
		}
		r = this.lineItemsHandler.multiRecFieldValidations(this.state.data);
		if (!r.status) {
			console.log(r);
			this.setState({ error: r });
			return false;
		}

		return true;
	}

	submitForm = async () => {
		this.resetError();
		if (!this.validateForm()) {
			return;
		}

		let data = deepCopy(this.state.data);
		copyFormFieldsToDataFields(header_mapping, data, this.deliveryChallan.header, header_fields);
		this.lineItemsHandler.formFieldsToDataArray(this.state.data, this.deliveryChallan.details);
		for (let i = 0; i < this.deliveryChallan.details.length; i++) {
			if (this.deliveryChallan.details[i].tax_details) {
				this.tax_mrecs[TAX_MREC + i].formFieldsToDataArray(data, this.deliveryChallan.details[i].tax_details);
			}
		}

		try {
			switch (this.action) {
				case "ADD":
					await deliveryChallanServiceRoutines.add(this.session, this.deliveryChallan);
					this.deliveryChallan =
						await deliveryChallanServiceRoutines.fetchUsingNumberAndDate(this.session,
							this.deliveryChallan.header.delivery_note_number,
							this.deliveryChallan.header.delivery_note_date,
							this.deliveryChallan.header.seller_org_id);

					break;
				case "EDIT":
					await deliveryChallanServiceRoutines.modify(this.session, this.deliveryChallan);
					this.deliveryChallan = await this.fetch(this.deliveryNoteId);
					break;
				case "AMEND":
					await deliveryChallanServiceRoutines.amend(this.session, this.deliveryChallan);
					this.deliveryChallan = await this.fetch(this.deliveryNoteId);
					break;
				case "CANCEL":
					await deliveryChallanServiceRoutines.cancelAmendMent(this.session, this.deliveryChallan);
					this.deliveryChallan = await this.fetch(this.deliveryNoteId);
					break;
				case "ACCEPT":
					await deliveryChallanServiceRoutines.send(this.session, this.deliveryChallan);
					this.deliveryChallan = await this.fetch(this.deliveryNoteId);
					break;
				case "REJECT":
					await deliveryChallanServiceRoutines.reject(this.session, this.deliveryChallan);
					this.deliveryChallan = await this.fetch(this.deliveryNoteId);
					break;
				case "APPROVE":
					await deliveryChallanServiceRoutines.approve(this.session, this.deliveryChallan);
					this.deliveryChallan = await this.fetch(this.deliveryNoteId);
					break;
				case "APPROVEAMENDMENT":
					await deliveryChallanServiceRoutines.approveAmendment(this.session, this.deliveryChallan);
					this.deliveryChallan = await this.fetch(this.deliveryNoteId);
					break;
				default:
					break;
			}

			this.backHandler(this.action);
		}
		catch (e) {
			console.log(this.deliveryChallan);
			console.log(e);
			this.setError(raiseError(e.error_message));
		}
	}

}


export default AddEditDeliveryChallan;
