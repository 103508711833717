import 	{
	GenericComponent,
	addressline,
	address_line,
	zipcode,
	zip_code,
	city,
	City,
	state,
	State,
	country,
	Country,
	getInitialState,
	deepCopy,
	NarrowButton,
	GenericForm,
	FormField,
	copyFormFieldsToDataFields,
	raiseError,
	fieldValidations
} from "WebUI_Framework";

import  serviceRoutines from './AddressServiceRoutines';

const fields = {
	address_1 : { id: "address_1", name : "address_1", label: "Address:",
		class : addressline, component: address_line, mandatory: true},

	address_2 : { id: "address_2", name : "address_2", label: "Address 2:",
		class : addressline, component: address_line, mandatory: false},

	zip_code : { id: "zip_code", name : "zip_code", label: "Zip Code:",
			class : zipcode, component: zip_code, mandatory: false},

	city : { id: "city", name : "city", label: "City:",
			 class : city, component: City, mandatory: true},

	state : { id: "state", name : "state", label: "State:",
			 class : state, component: State, mandatory: false},

	cntry: { id: "cntry", name : "cntry", label: "Country:",
			 class : country, component: Country, mandatory: false}
}

let mapping = [
	["address_1","address_line_1"],
	["address_2","address_line_2"],
	["zip_code","zip_code"],
	["city","city"],
	["state","state"],
	["cntry","cntry"],
	["version","version"],
	["org_id", "org_id"],
];

class AddAddress extends GenericComponent{
	constructor(props){
		super(props);
		this.submitForm = this.submitForm.bind(this);
		this.serverInstance = this.session.getServerInstance('ROC');
		this.accessToken = this.session.getAccessToken();
	}

	initialState = getInitialState(fields);

	state = {
		data : deepCopy(this.initialState),
	}

	reset = () => { // This is overrding the reset method in the base class
		let data  = deepCopy(this.initialState);
		this.setState({data : data, error : undefined });
	}

	backHandler = () => {
		if (this.isChildMode()) this.props.returnToParent({retFrom:"add"});
		else console.log("LOADED AS A PARENT");
	}

	render() {

		let buttons = [];
		let key = 0;

		let datablk = <div className="container-fluid pt-0 m-0 pe-2">
			<div className="row  p-0 m-0 g-0 " >
				<div className="col-sm-12 h6 text-left">
					{this.getBreadcrumb([window.getLabelLiteral("ADD")])}
				</div>
				<br>
				</br>
				<div className="col-sm-6" >
					<FormField field={fields.address_1} controlProps={this.controlProps} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.address_2} controlProps={this.controlProps} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.zip_code} controlProps={this.controlProps} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.city } controlProps={this.controlProps} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.cntry} controlProps={this.controlProps} />
				</div>

				<div className="col-sm-6" >
					<FormField
						field={fields.state}
						controlProps={this.controlProps}
						country={this.state.data.cntry}
					/>
				</div>

			</div>
			<div className="row  p-0 m-0 g-0 " >
				<div className="col-sm-6 mt-4 d-flex justify-content-right ">
					<NarrowButton value="Submit" onClick={this.submitForm} key={(++key).toString()} />
					<NarrowButton value="Clear" onClick={this.reset} key={(++key).toString()} />
					<NarrowButton value="Back" onClick={this.backHandler} key={(++key).toString()} />
				</div>
			</div>
		</div>

		let form = <GenericForm datablk={datablk} buttons={buttons} controlProps={this.controlProps} {...this.props }  />
		return form;
	}

	validateForm = () => {
		let r = fieldValidations(fields, this.state.data);
		if (!r.status) {
			this.setError(r);
			return false;
		}
		return true;
	}

	submitForm = async() => {
		this.resetError();
		if (!this.validateForm()) {
			return;
		}
		let retObj={};
		this.state.data.org_id = this.session.getLoggedInCompanyId();

		copyFormFieldsToDataFields(mapping, this.state.data,retObj, fields);
		let retData;
		try{
			retData = await serviceRoutines.add(this.serverInstance,this.accessToken,retObj)
			if(retData !== undefined && retData !== null){
				this.backHandler();
			}
		}
		catch(e){
			this.setError(raiseError(e.error_message));
		}
	}


}

export default AddAddress;
