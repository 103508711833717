let buckets = {
	COMPANIES: "COMPANIES"
	,COMPANY_ADDRESSES: "COMPANY_ADDRESSES"
	,PARTNER_LISTS: "PARTNER_LISTS"
	,COMPANY_DEFAULT_ADDRESSES: "COMPANY_DEFAULT_ADDRESSES"
	,COMPANIES_PARTNER_LISTS: "COMPANIES_PARTNER_LISTS"
    ,COMPANIES_ADDRESS_LISTS: "COMPANIES_ADDRESS_LISTS"
}

const getBucketNames = () => {
	return buckets;
}


export { getBucketNames }
