import { handleAxiosHTTPException } from "WebUI_Framework";

let addressserviceroutines = {};

addressserviceroutines.add = async (serverInstance, accessToken, retObj) => {
	let response;
	try {
		response = await serverInstance.post('/biop/registrar/company_address/add', retObj,
			{
				headers: {
					"X-Auth": accessToken
				}
			}
		);
	}
	catch(e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
}

addressserviceroutines.addressdata = async (serverInstance, accessToken, addressId) => {
	let response;
	try {
		 response = await serverInstance.get(`/biop/registrar/company_address/fetch?address_id=${addressId}`,
			{
				headers: {
					"X-Auth": accessToken
				}
			}
		);
	}
	catch(e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
}

addressserviceroutines.linked_Addresslist = async (serverInstance, accessToken, offset, recordsPerFrame, org_id) => {
	let response;
	try {
		 response = await serverInstance.get(
			`/biop/registrar/company_address/list?offset=${offset}&num_recs=${recordsPerFrame}&org_id=${org_id}&deleted=0`,
			{
				headers: {
					"X-Auth": accessToken
				}
			}
		);
	}
	catch(e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
}

addressserviceroutines.linked_Addressfetch = async (serverInstance, accessToken, selectedAddressId) => {
	let response;
	try {
		 response = await serverInstance.get(`/biop/registrar/company_address/fetch?address_id=${selectedAddressId}`,
			{
				headers: {
					"X-Auth": accessToken
				}
			}
		);
	}
	catch(e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
}

addressserviceroutines.linked_Addressdelete = async (serverInstance, accessToken, req_data) => {
	let response;
	try {
		 response = await serverInstance.put(`/biop/registrar/company_address/delete`, req_data,
			{
				headers: {
					"X-Auth": accessToken
				}
			}
		);
	}
	catch(e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
}

addressserviceroutines.linked_Addressmodify = async (serverInstance, accessToken, addressData) =>  { 
	let response;
	try {
		 response = await serverInstance.post('/biop/registrar/company_address/modify',
			addressData,
			{
				headers: {
					"X-Auth": accessToken
				}
			});
	}
	catch(e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
}

export default addressserviceroutines ;
