import {
	GenericComponent,
	GenericForm,
	getInitialState,
	GenericTable,
	deepCopy,
	raiseError,
} from 'WebUI_Framework';

import 'bootstrap-icons/font/bootstrap-icons.css';
import ViewSalesOrder from './ViewSalesOrder';
import serviceRoutines from './SalesOrdersServiceRoutines';

class ManageSalesOrders extends GenericComponent {
	constructor(p) {
		super(p);
		this.recordsPerFrame = window.getParam("CURSOR_FRAME_SIZE");
		this.pageNumber = 1;
		this.selectOrderId = 0;
		//this.recordsPerPage = 7;
        this.recordsPerPage = window.getParam("TABLE_PAGE_SIZE");
		this.state = {
			data: [],
			isLoading: true
		}
		this.getRow = this.getRow.bind(this);
		this.getFrame = this.getFrame.bind(this);
		this.company_id = this.session.getLoggedInCompanyId();
	}

	componentDidMount() {
		this.getFrame(1, this.recordsPerFrame);
	}

	initialState = getInitialState();

	state = {
		data: deepCopy(this.initialState),
	}

	viewHandler = async (orderId) => {
		this.selectOrderId = orderId;
		try {
			const retData =
				await serviceRoutines.fetch(this.session.getServerInstance('SUBSCRIBER'),
					this.session.getAccessToken(), this.selectOrderId)
			if (retData !== undefined && retData !== null) {
				let orderDetails = retData.order_line_item;
				let orderHeaderObject = retData.sales_order_header;
				this.callForm(ViewSalesOrder, { orderDetails, orderHeaderObject , ...this.props }, this.onReturn);
			}
		}
		catch (e) {
			this.setError(raiseError(e.error_message));
		}
	}

	async getFrame(offset, recordsPerFrame) {
		try {
			const retData =
				await serviceRoutines.list(this.session.getServerInstance('SUBSCRIBER'),
					this.session.getAccessToken(), offset, recordsPerFrame, this.company_id);
			this.setState({ isLoading: false })
			if (retData.length > 0) {
				this.setState({ data: this.state.data.concat(retData) });
			}
		}
		catch (e) {
			this.setError(raiseError(e.error_message));
		}
	}

	getRow(data, index, pageNumber, arrayIndex) {
		this.pageNumber = pageNumber;
		return (
			<tr key={index}>
				<td>
					<button type="button" style={{
						color: "blue", textDecoration: "underline",
						cursor: "pointer", border: "none", background: "none", padding: "0"
					}}
						onClick={() => this.viewHandler(data.order_id)}>{data.order_number}
					</button>
				</td>
				<td>{data.order_issue_date}</td>
				<td>{data.buyer_org_name}</td>
				<td>{data.description}</td>
				<td>{data.order_valid_from_date}</td>
				<td>{data.order_valid_to_date}</td>
			</tr>
		)
	}

	getHeader() {
		let key = 0;
		return (
			<tr key={++key}>
				<th key={++key}>Order Number</th>
				<th key={++key}>Issue Date</th>
				<th key={++key}>Issued By</th>
				<th key={++key}>Description</th>
				<th key={++key}>Valid From </th>
				<th key={++key}>Valid Till</th>
			</tr>
		);
	}

	render() {
		let buttons = [];

		const tableclass = "table table-striped border-dark";
		let datablk = <div className="container-fluid pt-0 m-0 pe-2">
			<div className="col-sm-12">
				<GenericTable
					pageHeading={this.getBreadcrumb()}
					pageHeadingClass="h6 text-left"
					data={this.state.data}
					recordsPerPage={this.recordsPerPage}
					getRow={this.getRow}
					getHeader={this.getHeader}
					tableclass={tableclass}
					getFrame={this.getFrame}
					recordsPerFrame={this.recordsPerFrame}
					pageNumber={this.pageNumber}
					isLoading={this.state.isLoading}
					button={this.button_inputs}
				/>
			</div>

		</div>

		let frm = <GenericForm
			datablk={datablk} buttons={buttons}
			controlProps={this.controlProps}
			{...this.props} />

		return frm;
	}
}

export default ManageSalesOrders;
