import { getServiceDetails } from '../common/utils'
import { handleAxiosHTTPException } from "WebUI_Framework";

let receivedQuotationServiceRoutines = {};

receivedQuotationServiceRoutines.list = async (session, obj) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(
			`biop/subscriber/received_quotation/list`,
			{
				headers: {
					"X-Auth": accessToken,
				},
				params : obj
			});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

receivedQuotationServiceRoutines.fetch = async (session, selectedQuotationId) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(`biop/subscriber/received_quotation/fetch?quotation_id=${selectedQuotationId}`,
			{
				headers: {
					"X-Auth": accessToken,
				},
			}
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

receivedQuotationServiceRoutines.fetchUsingNumberAndDate = async (session, quotationNumber, quotationDate, sellerOrgId) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
    let response;
	let params = {
		quotation_number : quotationNumber,
		quotation_issue_date : quotationDate,
		seller_org_id : sellerOrgId,
	};
	try {
		response = await serverInstance.get(
			`biop/subscriber/received_quotation/fetch_using_number_and_date`,
			{
				params,
				headers: {
					"X-Auth": accessToken,
				},
			}
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

receivedQuotationServiceRoutines.reject = async (session, receivedQuotation) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post('/biop/subscriber/received_quotation/reject',
			receivedQuotation,
			{
				headers: {
					"X-Auth": accessToken
				}
			}
		);
		return response.data;
	}
	catch (e) {
		return handleAxiosHTTPException(e);
	}
}

export default receivedQuotationServiceRoutines;
