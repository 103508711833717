import { getServiceDetails } from '../common/utils'
import { handleAxiosHTTPException } from "WebUI_Framework";

let invoiceserviceroutines = {};

invoiceserviceroutines.list = async (session, obj) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.put(
			`biop/subscriber/invoice/list`,
			obj,
			{
				headers: {
					"X-Auth": accessToken,
				},
			});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

invoiceserviceroutines.fetch = async (session, selectedInvoiceId) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(`biop/subscriber/invoice/fetch?invoice_id=${selectedInvoiceId}`,
			{
				headers: {
					"X-Auth": accessToken,
				},
			}
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

invoiceserviceroutines.fetchAmended = async (session, selectedInvoiceId) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(`biop/subscriber/invoice/fetch_for_amendment?invoice_id=${selectedInvoiceId}`,
			{
				headers: {
					"X-Auth": accessToken,
				},
			}
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

invoiceserviceroutines.fetchUsingNumberAndDate = async (session, invoiceNumber, invoiceDate, orgId) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
    let response;
	let params = {
		invoice_number : invoiceNumber,
		invoice_date : invoiceDate,
		seller_org_id : orgId
	};
	try {
		response = await serverInstance.get(
			`biop/subscriber/invoice/fetch_using_number_and_date`,
			{
				params,
				headers: {
					"X-Auth": accessToken,
				},
			}
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

invoiceserviceroutines.amendFetch = async (session, selectedInvoiceId) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(`biop/subscriber/invoice/fetch_for_amendment?invoice_id=${selectedInvoiceId}`,
			{
				headers: {
					"X-Auth": accessToken
				}
			}
		);
		return response.data;
	}
	catch (e) {
		return handleAxiosHTTPException(e);
	}
}

invoiceserviceroutines.delete = async (session, retData) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.put(`biop/subscriber/invoice/delete`, retData, {
			headers: {
				"X-Auth": accessToken,
			},
		});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

invoiceserviceroutines.datalist = async (session, parentOrgId, searchText) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(
			`/biop/subscriber/masters/partnerlist?parent_org_id=${parentOrgId}&org_name=${searchText}`,
			{
				headers: {
					"X-Auth": accessToken,
				},
			}
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

invoiceserviceroutines.modify = async (session, retObj_modify) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post("biop/subscriber/invoice/modify", retObj_modify, {
			headers: {
				"X-Auth": accessToken,
			},
		});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

invoiceserviceroutines.add = async (session, retObj) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post("biop/subscriber/invoice/add", retObj, {
			headers: {
				"X-Auth": accessToken,
			},
		});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

invoiceserviceroutines.approve = async (session, invoice) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post('/biop/subscriber/invoice/approve',
			invoice,
			{
				headers: {
					"X-Auth": accessToken
				}
			}
		);
		return response.data;
	}
	catch (e) {
		return handleAxiosHTTPException(e);
	}
}

invoiceserviceroutines.approveAmendment = async (session, invoice) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post('/biop/subscriber/invoice/approve_amendment',
			invoice,
			{
				headers: {
					"X-Auth": accessToken
				}
			}
		);
		return response.data;
	}
	catch (e) {
		return handleAxiosHTTPException(e);
	}
}

invoiceserviceroutines.amend = async (session, invoice) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post('/biop/subscriber/invoice/amend',
			invoice,
			{
				headers: {
					"X-Auth": accessToken
				}
			}
		);
		return response.data;
	}
	catch (e) {
		return handleAxiosHTTPException(e);
	}
}

invoiceserviceroutines.approveAmend = async (session, invoice) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post('/biop/subscriber/invoice/approve_amendment',
			invoice,
			{
				headers: {
					"X-Auth": accessToken
				}
			}
		);
		return response.data;
	}
	catch (e) {
		return handleAxiosHTTPException(e);
	}
}

invoiceserviceroutines.fetch_sales_order = async (session, buyerOrgId, orderIssueDate, orderNumber) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
    let response;
    let params = {};
    if (orderNumber) {
        params.order_number = orderNumber;
    }
    if (orderIssueDate) {
        params.order_issue_date = orderIssueDate;
    }
    if (buyerOrgId) {
        params.buyer_org_id = buyerOrgId;
    }

    try {
        response = await serverInstance.get(
            `/biop/subscriber/sales_order/fetch_using_number_and_date`,
            {
                params,
                headers: {
                    "X-Auth": accessToken
                }
            }
        );
        return response.data;
    }
    catch (e) {
        console.log(e);
        return handleAxiosHTTPException(e);
    }
}


export default invoiceserviceroutines;

