import React from 'react';
import {
    GenericComponent,
    NarrowButton,
    GenericForm,
    FormField,
    valuePresent,
    ErrorsForElement,

    fieldValidations,
    getInitialState,
    copyFormFieldsToDataFields,
    copyDataFieldsToFormFields,
    deepCopy,
    raiseError,
    addError,

    MultiRecFormField,
    MultiRecHandler,
    MultiRec,
    generic_string,
    genericstring,
    org_name,
    orgname,
    genericgenrefcode,
    generic_genrefcode,
    Backdrop,
    Modal_view_content,

} from 'WebUI_Framework';

import subscriberServiceRoutines from "./SubscriberServiceRoutines";

const fields = {
}

let mapping = [
];

const INBOXES_MREC = "INBOXES_MREC"

const inboxes_fields = {
    name: {
        id: "name", name: "name", label: window.getLabelLiteralWithColon("INBOX_NAME"),
        class: genericstring, component: generic_string, mandatory: true
    }
};

const inboxes_mapping = [
    ["name", "name"],
];

const PARTNERS_MREC = "PARTNERS_MREC"

const partner_fields = {
    name: {
        id: "name", name: "name", label: window.getLabelLiteralWithColon("PARTNER_CATEGORY"),
        class: genericstring, component: generic_string, mandatory: true
    }
};

const partner_mapping = [
    ["name", "name"],
];

const ATTACHMENTS_MREC = "ATTACHMENTS_MREC"

const attachment_fields = {
    name: {
        id: "name", name: "name", label: window.getLabelLiteralWithColon("ATTACHEMENT_CATEGORY"),
        class: genericstring, component: generic_string, mandatory: true
    }
};

const attachment_mapping = [
    ["name", "name"],
];

class EditSubscriberParameters extends GenericComponent {
    constructor(props) {
        super(props);
        this.state.loading = true;
        this.state.step = 1;
        this.state.editCount = 0;

        this.readOnly = false;
 
        this.subscriberServerInstance = this.session.getServerInstance('SUBSCRIBER');
        this.accessToken = this.session.getAccessToken();

        this.action = props.action;
        this.subscriber_parameters = {};
        this.existing_inbox_names = {};
        this.existing_attachment_names = {};
        this.existing_partner_category_names = {};

        this.logged_in_company_id = this.session.getLoggedInCompanyId();
        this.parent_company_id = this.session.getLoggedInParentCompanyId();
        this.is_subscriber = (this.logged_in_company_id === this.parent_company_id);
        this.org_id = this.parent_company_id;

        this.page_title = "";
        this.page_title += (this.action === "DELETE") ? window.getLabelLiteral("DELETING") : window.getLabelLiteral(this.action);
        if (this.action === "EDIT") {
        }
        else if (this.action === "VIEW") {
            this.readOnly = true;
        }
        this.page_title += " " + window.getLabelLiteral("SUBSCRIBER_PARAMETER_SETUP_TITLE");

        this.ibHandler = new MultiRecHandler(this.session, inboxes_fields, inboxes_mapping, INBOXES_MREC);
        this.inboxes_buttons = [
            {
                literal: "Add",
                func: () => this.addInbox()
            }
        ]
        this.pcHandler = new MultiRecHandler(this.session, partner_fields, partner_mapping, PARTNERS_MREC);
        this.partner_categories_buttons = [
            {
                literal: "Add",
                func: () => this.addPC()
            }
        ]
        this.acHandler = new MultiRecHandler(this.session, attachment_fields, attachment_mapping, ATTACHMENTS_MREC);
        this.attachment_categories_buttons = [
            {
                literal: "Add",
                func: () => this.addAC()
            }
        ]
    }

    initialState = getInitialState(fields);

    state = {
        data: deepCopy(this.initialState),
    }

    backHandler = (retFrom, error) => {
        if (this.isChildMode())
            this.props.returnToParent({
                retFrom, arrayIndex: this.props.arrayIndex,
                selectData: this.subscriber_parameters, error
            });
        else {
            this.setState({ loading: false });
        }
    }

    fetch = async (org_id) => {
        return await subscriberServiceRoutines.fetchParameters(this.session, org_id);
    }

    initializations = (state) => {
        if (!valuePresent(this.subscriber_parameters.valid_inboxes))
            this.subscriber_parameters.valid_inboxes = (this.action === 'EDIT')?[{}]:[];

        this.subscriber_parameters.valid_inboxes.map((item) => {
            if (item.name)
                this.existing_inbox_names[item.name] = true;
            return item;
        });
        state = this.ibHandler.dataArrayToFormFields(this.subscriber_parameters.valid_inboxes, state);

        if (!valuePresent(this.subscriber_parameters.valid_attachment_categories))
            this.subscriber_parameters.valid_attachment_categories = (this.action === 'EDIT')?[{}]:[];

        this.subscriber_parameters.valid_attachment_categories.map((item) => {
            if (item.name)
                this.existing_attachment_names[item.name] = true;
            return item;
        });
        state = this.acHandler.dataArrayToFormFields(this.subscriber_parameters.valid_attachment_categories, state);

        if (!valuePresent(this.subscriber_parameters.valid_partner_categories))
            this.subscriber_parameters.valid_partner_categories = (this.action === 'EDIT')?[{}]:[];

        this.subscriber_parameters.valid_partner_categories.map((item) => {
            if (item.name)
                this.existing_partner_category_names[item.name] = true;
            return item;
        });
        state = this.pcHandler.dataArrayToFormFields(this.subscriber_parameters.valid_partner_categories, state);

        copyDataFieldsToFormFields(mapping, this.subscriber_parameters, state);

        return state;
    }

    componentDidMount = async () => {
        let newData;
        try {
            switch (this.action) {
                /*eslint no-fallthrough: 0*/
                case 'VIEW':
                case 'EDIT':
                    newData = (this.state.data);
                    this.subscriber_parameters = await this.fetch(this.org_id);

                    newData = this.initializations(newData);


                    this.setState({ loading: false, data: newData });

                    break;
                default:
                    this.setError(raiseError("EditSubscriberParameters: Invalid action [" + this.action + "]"));
                    this.backHandler(this.action, this.error);
                    break;
            }
        }
        catch (e) {
            console.log(this.action);
            console.log(e);
            this.setError(raiseError((e.error_message) ? e.error_message : e.message));
            this.backHandler(this.action, this.error);
        }
    }

    addInbox = () => {
        let data = this.state.data;
        this.resetError();
        let [newData, new_list] = this.ibHandler.addMultiRecRow(data, this.subscriber_parameters.valid_inboxes, {});
        this.subscriber_parameters.valid_inboxes = new_list;
        this.setState({ data: newData });
    }

    deleteInbox = (data, i) => {
        this.resetError();
        let [newData, updated_list] =
            this.ibHandler.deleteMultiRecRow(this.state.data, this.subscriber_parameters.valid_inboxes, i);
        this.subscriber_parameters.valid_inboxes = updated_list;
        this.setState({ data: newData });
    }

    getInboxHeader = () => {
        let key = 0;
        return (
            <tr key={++key}>
                <th key={++key} width={"80%"}>{window.getLabelLiteral("INBOX_NAME")}</th>
                <th key={++key} width={"20%"}>{window.getLabelLiteral("ACTIONS")}</th>
            </tr>
        );
    }

    getInboxRow = (rowData, index) => {
        const i = index - 1;
        let key = 0;
        let style = { borderBottom: "0px solid" };

        let r = <React.Fragment key={index}>
            <tr key={++key}>
                <td key={++key} width={"80%"} style={style}>
                    <MultiRecFormField
                        field={inboxes_fields.name}
                        controlProps={this.controlProps}
                        index={i}
                        multiRecId={INBOXES_MREC}
                        readOnly={this.readOnly}
                        style={{ width: "90%" }}
                    />
                </td>
                <td key={++key} width={"20%"} style={style}>
                    {(!this.existing_inbox_names[rowData.name]) &&
                        <div style={{ paddingLeft: "20px", cursor: "pointer" }}>
                            <span onClick={() => { return this.deleteInbox(rowData, i); }}
                                data-toggle="tooltip" title={window.getLabelLiteral("DELETE")}
                            >
                                <i className="bi bi-trash3" style={{ fontSize: 20 }}></i>
                            </span>
                        </div>
                    }
                </td>
            </tr>
        </React.Fragment>
        return r;
    }

    addPC = () => {
        let data = this.state.data;
        this.resetError();
        let [newData, new_list] = this.pcHandler.addMultiRecRow(data, this.subscriber_parameters.valid_partner_categories, {});
        this.subscriber_parameters.valid_partner_categories = new_list;
        this.setState({ data: newData });
    }

    deletePC = (data, i) => {
        this.resetError();
        let [newData, updated_list] =
            this.pcHandler.deleteMultiRecRow(this.state.data, this.subscriber_parameters.valid_partner_categories, i);
        this.subscriber_parameters.valid_partner_categories = updated_list;
        this.setState({ data: newData });
    }

    getPCHeader = () => {
        let key = 0;
        return (
            <tr key={++key}>
                <th key={++key} width={"80%"}>{window.getLabelLiteral("PARTNER_CATEGORY")}</th>
                <th key={++key} width={"20%"}>{window.getLabelLiteral("ACTIONS")}</th>
            </tr>
        );
    }

    getPCRow = (rowData, index) => {
        const i = index - 1;
        let key = 0;
        let style = { borderBottom: "0px solid" };

        let r = <React.Fragment key={index}>
            <tr key={++key}>
                <td key={++key} width={"80%"} style={style}>
                    <MultiRecFormField
                        field={partner_fields.name}
                        controlProps={this.controlProps}
                        index={i}
                        multiRecId={PARTNERS_MREC}
                        readOnly={this.readOnly}
                        style={{ width: "90%" }}
                    />
                </td>
                <td key={++key} width={"20%"} style={style}>
                    {(!this.existing_partner_category_names[rowData.name]) &&
                        <div style={{ paddingLeft: "20px", cursor: "pointer" }}>
                            <span onClick={() => { return this.deletePC(rowData, i); }}
                                data-toggle="tooltip" title={window.getLabelLiteral("DELETE")}
                            >
                                <i className="bi bi-trash3" style={{ fontSize: 20 }}></i>
                            </span>
                        </div>
                    }
                </td>
            </tr>
        </React.Fragment>
        return r;
    }

    addAC = () => {
        let data = this.state.data;
        this.resetError();
        let [newData, new_list] = this.acHandler.addMultiRecRow(data, this.subscriber_parameters.valid_attachment_categories, {});
        this.subscriber_parameters.valid_attachment_categories = new_list;
        this.setState({ data: newData });
    }

    deleteAC = (data, i) => {
        this.resetError();
        let [newData, updated_list] =
            this.acHandler.deleteMultiRecRow(this.state.data, this.subscriber_parameters.valid_attachment_categories, i);
        this.subscriber_parameters.valid_attachment_categories = updated_list;
        this.setState({ data: newData });
    }

    getACHeader = () => {
        let key = 0;
        return (
            <tr key={++key}>
                <th key={++key} width={"80%"}>{window.getLabelLiteral("ATTACHEMENT_CATEGORY")}</th>
                <th key={++key} width={"20%"}>{window.getLabelLiteral("ACTIONS")}</th>
            </tr>
        );
    }

    getACRow = (rowData, index) => {
        const i = index - 1;
        let key = 0;
        let style = { borderBottom: "0px solid" };

        let r = <React.Fragment key={index}>
            <tr key={++key}>
                <td key={++key} width={"80%"} style={style}>
                    <MultiRecFormField
                        field={attachment_fields.name}
                        controlProps={this.controlProps}
                        index={i}
                        multiRecId={ATTACHMENTS_MREC}
                        readOnly={this.readOnly}
                        style={{ width: "90%" }}
                    />
                </td>
                <td key={++key} width={"20%"} style={style}>
                    {(!this.existing_attachment_names[rowData.name]) &&
                        <div style={{ paddingLeft: "20px", cursor: "pointer" }}>
                            <span onClick={() => { return this.deleteAC(rowData, i); }}
                                data-toggle="tooltip" title={window.getLabelLiteral("DELETE")}
                            >
                                <i className="bi bi-trash3" style={{ fontSize: 20 }}></i>
                            </span>
                        </div>
                    }
                </td>
            </tr>
        </React.Fragment>
        return r;
    }

    onReturn = (ret) => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });

        this.resetError();
        if (ret.error) {
            this.setError(ret.error);
            return;
        }

        let updatedArrayIndex = ((this.pageNumber - 1) * this.recordsPerPage) + ret.arrayIndex;
        /*eslint no-fallthrough: 0*/
        switch (ret.retFrom) {
            case "EDIT":
            case "VIEW":
                this.setState((prevState, props) => {
                    this.subscriber_parameters = ret.selectData;
                    prevState.data = this.initializations(prevState.data);
                    prevState.editCount++;
                    return deepCopy(prevState);
                });
                break;
            case 'BACK':
                break;
            default:
                this.setError(raiseError("Invalid return from EditSubscriberParameters"));
        }

    }

    handler = (action) => {
        this.callForm(EditSubscriberParameters, { ...this.props, action }, this.onReturn);
    }

    data_render = () => {
        let key = 0;

        let datablk = (
            <div className="container-fluid border border-0 pt-0 m-0 ">
                <div className="row    p-0 m-0 g-0 ">
                    <div className="container-xxl row border border-0 p-0 m-0 ">
                        <div className="col-4 m-0 p-0 g-0">
                            <span className="h6 text-start">
                                {this.getBreadcrumb([window.getLabelLiteral(this.action)])}
                            </span>
                        </div>
                        <div className="d-inline-flex col-7 justify-content-end">
                            <span className="h6 text-center">
                                {(this.action === "VIEW")&&
                                    <button type="button"
                                        style={{
                                            color: "blue", textDecoration: "underline", cursor: "pointer",
                                            border: "none", background: "none", padding: 0, margin:0,
                                            textAlign: "left"
                                        }}
                                        onClick={() => this.handler("EDIT")}
                                    >
                                        {"EDIT"}
                                    </button>
                                }
                            </span>
                        </div>
                        <div className="col-1 ">
                            &nbsp;&nbsp;
                        </div>
                    </div>
                    <hr></hr>
                    <div className="col-sm-12 mt-2" >
                        <div className="col-sm-6">
                            <MultiRec
                                noInfoMessage={""}
                                pageHeading={window.getLabelLiteral("VALID_INBOXES")}
                                pageHeadingClass={"fs-6 fw-bold"}
                                data={this.subscriber_parameters.valid_inboxes}
                                buttons={(this.readOnly) ? null : this.inboxes_buttons}
                                getRow={this.getInboxRow}
                                getHeader={this.getInboxHeader}
                                tfootStyle={{ borderTop: "0.1rem solid" }}
                                tbodyStyle={{ borderTop: "2.5px solid" }}
                            />
                            {(!valuePresent(this.subscriber_parameters?.valid_inboxes?.length) ||
                                this.subscriber_parameters?.valid_inboxes.length ===0) &&
                                <span>
                                {"NO DATA"}
                                </span>
                            }
                        </div>
                    </div>
                    <hr className="mt-5 mb-4"/>
                    <div className="col-sm-12 mt-2" >
                        <div className="col-sm-6">
                            <MultiRec
                                noInfoMessage={""}
                                pageHeading={window.getLabelLiteral("VALID_PARTNER_CATEGORIES")}
                                pageHeadingClass={"fs-6 fw-bold"}
                                data={this.subscriber_parameters.valid_partner_categories}
                                buttons={(this.readOnly) ? null : this.partner_categories_buttons}
                                getRow={this.getPCRow}
                                getHeader={this.getPCHeader}
                                tfootStyle={{ borderTop: "0.1rem solid" }}
                                tbodyStyle={{ borderTop: "2.5px solid" }}
                            />
                            {(!valuePresent(this.subscriber_parameters?.valid_inboxes?.length) ||
                                this.subscriber_parameters?.valid_inboxes.length ===0) &&
                                <span>
                                {"NO DATA"}
                                </span>
                            }
                        </div>
                    </div>
                    <hr className="mt-5 mb-4"/>
                    <div className="col-sm-12 mt-2" >
                        <div className="col-sm-6">
                            <MultiRec
                                noInfoMessage={""}
                                pageHeading={window.getLabelLiteral("VALID_ATTACHMENT_CATEGORIES")}
                                pageHeadingClass={"fs-6 fw-bold"}
                                data={this.subscriber_parameters.valid_attachment_categories}
                                buttons={(this.readOnly) ? null : this.attachment_categories_buttons}
                                getRow={this.getACRow}
                                getHeader={this.getACHeader}
                                tfootStyle={{ borderTop: "0.1rem solid" }}
                                tbodyStyle={{ borderTop: "2.5px solid" }}
                            />
                            {(!valuePresent(this.subscriber_parameters?.valid_inboxes?.length) ||
                                this.subscriber_parameters?.valid_inboxes.length ===0) &&
                                <span>
                                {"NO DATA"}
                                </span>
                            }
                        </div>
                    </div>
                    <div className="row  p-0 m-0 g-0 " >
                        <div className="col-sm-6 mt-4 d-flex justify-content-right ">
                            {(this.action !== 'VIEW') &&
                                <>
                                    <NarrowButton
                                        value="Submit" onClick={this.submitForm} key={(++key).toString()} />
                                    {/*
                                    <NarrowButton
                                        value="Back"
                                        onClick={() => { return this.backHandler("BACK"); }} key={(++key).toString()} />
                                    */}
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );

        let form = <GenericForm datablk={datablk} buttons={[]} controlProps={this.controlProps} {...this.props} />
        return form;
    }

    loading_render = () => {
        let datablk = <div className="container border border-0 pt-0 m-0 ">
            <div className="row  p-0 m-0 g-0 " >
                <div className="col-sm-12 h4 text-center" >{this.page_title}</div>
                <div className="d-flex justify-content-evenly">
                    <div className="spinner-border text-dark" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>
            </div>
        </div>

        let form = <GenericForm datablk={datablk} buttons={[]} controlProps={this.controlProps} {...this.props} />
        return form;
    }

    render = () => {
        if (this.state.loading) {
            return this.loading_render();
        }
        else {
            return this.data_render();
        }
    }

    validateForm = () => {
        let r = fieldValidations(fields, this.state.data);
        r = this.ibHandler.multiRecFieldValidations(this.state.data, null, r);
        r = this.pcHandler.multiRecFieldValidations(this.state.data, null, r);
        r = this.acHandler.multiRecFieldValidations(this.state.data, null, r);
        if (!r.status) {
            console.log(r);
            this.setState({ error: r });
            return false;
        }

        return true;
    }

    submitForm = async () => {
        this.resetError();
        if (!this.validateForm()) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
            return;
        }

        let subscriber_parameters = deepCopy(this.subscriber_parameters);
        let data = (this.state.data);
        copyFormFieldsToDataFields(mapping, data, subscriber_parameters, fields);
        this.ibHandler.formFieldsToDataArray(this.state.data, subscriber_parameters.valid_inboxes);
        this.acHandler.formFieldsToDataArray(this.state.data, subscriber_parameters.valid_attachment_categories);
        this.pcHandler.formFieldsToDataArray(this.state.data, subscriber_parameters.valid_partner_categories);

        if (subscriber_parameters.valid_inboxes.length === 0)
            delete subscriber_parameters.valid_inboxes;

        if (subscriber_parameters.valid_attachment_categories.length === 0)
            delete subscriber_parameters.valid_attachment_categories;

        if (subscriber_parameters.valid_partner_categories.length === 0)
            delete subscriber_parameters.valid_partner_categories;

        try {
            await subscriberServiceRoutines.modifyParameters(this.session, subscriber_parameters);
            subscriber_parameters = await this.fetch(this.org_id);
            this.subscriber_parameters = subscriber_parameters;
            this.backHandler(this.action);
        }
        catch (e) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
            console.log(this.subscriber_parameters);
            console.log(e);
            this.setError(raiseError(e.error_message));
        }
    }

}


export default EditSubscriberParameters;
