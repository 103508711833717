import {
    Backdrop,
    GenericComponent, GenericForm, GenericTable, Modal_view_content, deepCopy, raiseError,
} from 'WebUI_Framework';
import 'bootstrap-icons/font/bootstrap-icons.css';
import PartneshipRequestsServiceRoutines from './PartneshipRequestsServiceRoutines';
import EditPartners from '../partners/EditPartners';

let partners_type = window.getGenericRefCode('PartnersType');

class ManagePartnershipRequests extends GenericComponent {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            data1: [],
            isLoading: true,
            viewModal: false
        }
        this.org_id = null;
        this.recordsPerFrame = window.getParam("CURSOR_FRAME_SIZE");
        //this.recordsPerPage = 6;
        this.recordsPerPage = window.getParam("TABLE_PAGE_SIZE");
        this.pageNumber = 1;
        this.accessToken = this.session.getAccessToken();
        this.getRow = this.getRow.bind(this);
        this.getHeader = this.getHeader.bind(this);
    }

    componentDidMount() {
        this.getFrame(1, this.recordsPerFrame);
    }

    async getFrame(offset, recordsPerFrame) {
        let partnership_requests;
        try {
            partnership_requests = await PartneshipRequestsServiceRoutines.list(
                this.session.getServerInstance('SUBSCRIBER'),
                this.accessToken, offset, recordsPerFrame,
                this.session.getLoggedInParentCompanyId());
            if (partnership_requests !== undefined && partnership_requests !== null) {
                this.setState({ data: partnership_requests });
                this.setState({ isLoading: false });
            }
        }
        catch (e) {
            console.log(e);
            this.setError(raiseError(e.error_message));
        }
    }

    closeViewModal = () => {
        this.setState({ viewModal: false });
    }

    async fetchPartnershipRequestRecord(request_id) {
        let obj;
        try {
            obj = await PartneshipRequestsServiceRoutines.fetch(
                this.session.getServerInstance('SUBSCRIBER'), this.session.getAccessToken(), request_id)
            if (obj !== undefined && obj !== null) {
                return obj;
            }
        }
        catch (e) {
            console.log(e);
            this.setError(raiseError(e.error_message));
        }
    }

    rejectPartnership = async (request_id, arrayIndex) => {
        let partnership_request_obj = await this.fetchPartnershipRequestRecord(request_id);
        let retObj;
        try {
            retObj = await PartneshipRequestsServiceRoutines.reject(
                this.session.getServerInstance('SUBSCRIBER'), this.session.getAccessToken(), partnership_request_obj);
            if (retObj !== undefined && retObj !== null) {
                let index = ((this.pageNumber - 1) * this.recordsPerPage) + arrayIndex;
                let data = deepCopy(this.state.data);
                delete data[index];
                this.setState({ data: data });
            }
        }
        catch (e) {
            console.log(e);
            this.setError(raiseError(e.error_message));
        }     
    }

    acceptPartnership = async (request_id, arrayIndex) => {
        let partnership_request_obj = await this.fetchPartnershipRequestRecord(request_id);
        let retObj;
        try {
            retObj = await PartneshipRequestsServiceRoutines.accept(
                this.session.getServerInstance('SUBSCRIBER'), this.session.getAccessToken(), partnership_request_obj);
            if (retObj !== undefined && retObj !== null) {
                let index = ((this.pageNumber - 1) * this.recordsPerPage) + arrayIndex;
                let data = deepCopy(this.state.data);
                data.splice(index, 1);
                this.setState({ data: data });
            }
        }
        catch (e) {
            console.log(e);
            this.setError(raiseError(e.error_message));
        }
    }


    getRow(data, index, pageNumber, arrayIndex) {
        return (

            <tr key={index}>
                <td>{data.sender_org_name}</td>
                <td className="text-wrap" colSpan={2}
                    style={{ width: "10rem" }}>
                    {(data.relationship_type) ? partners_type[data.relationship_type] : "-"}
                </td>
                <td>{data.sender_contact_phone_number}</td>
                <td>{data.sender_contact_email_id}</td>
                <td>
                    <>
                        <span onClick={() => this.acceptPartnership(data.request_id, arrayIndex)}
                            title="Accept Partnership"
                            style={{ cursor: 'pointer' }} >
                            <i className="bi bi-hand-thumbs-up" style={{ fontSize: 20 }} ></i>
                        </span>
                    </>
                    &nbsp;&nbsp;
                    <>
                        <span onClick={() => this.rejectPartnership(data.request_id, arrayIndex)}
                            title="Reject Partnership"
                            style={{ cursor: 'pointer' }} >
                            <i className="bi bi-hand-thumbs-down" style={{ fontSize: 20 }} ></i>
                        </span>
                    </>
                </td>
            </tr>
        )
    }

    getHeader() {
        let key = 0;
        return (
            <tr key={++key}>
                <th key={++key}>Company Name</th>
                <th key={++key} colSpan={2} >Requested Relationship Type</th>
                <th key={++key}>Contact Number</th>
                <th key={++key}>Company Email</th>
                <th key={++key}>Actions</th>
            </tr>
        );
    }

    render() {
        let buttons = [];
        const tableclass = "table table-striped border-dark";
        let modalViewContent = <div className="container pt-0 m-0 ">
            <EditPartners
                orgId={this.org_id}
                readOnly={true}
                noFormTag={true}
                {...this.props} />
        </div>
        let datablk = <div className="container-fluid pt-0 m-0 pe-2">
            <div className="col-sm-12">
                <GenericTable
                    pageHeading={this.getBreadcrumb()}
                    pageHeadingClass="h6 text-left"
                    data={this.state.data}
                    recordsPerPage={this.recordsPerPage}
                    getRow={this.getRow}
                    getHeader={this.getHeader}
                    tableclass={tableclass}
                    getFrame={this.getFrame}
                    recordsPerFrame={this.recordsPerFrame}
                    pageNumber={this.pageNumber}
                    isLoading={this.state.isLoading}
                />
            </div>
            {(this.state.viewModal) && <Modal_view_content
                title={this.getBreadcrumb([window.getLabelLiteral("VIEW")])}
                btn1="Close"
                onClick={this.closeViewModal}
                modalContent={modalViewContent}
            />
            }
            {(this.state.viewModal) && <Backdrop />}
        </div>
        let frm = <GenericForm datablk={datablk} buttons={buttons} controlProps={this.controlProps} {...this.props} />
        return frm;
    }
}

export default ManagePartnershipRequests;
