import {GenericComponent,
	GenericTable,
	Modal,
	Backdrop,
	GenericForm,
	deepCopy,
	raiseError,
	Modal_view_content
} from 'WebUI_Framework';
import AddAddress from './AddAddress';
import EditAddress from './EditAddress';
import serviceRoutines from './AddressServiceRoutines';

let refcode_country = window.getRefCode('00001');

class ManageAddress extends GenericComponent {
	constructor(props){
		super(props);
		this.recordsPerFrame = window.getParam("CURSOR_FRAME_SIZE");
		this.pageNumber = 1;
		//this.recordsPerPage = 7;
        this.recordsPerPage = window.getParam("TABLE_PAGE_SIZE");
		this.state = {
			data : [],
			showModal : false,
			isLoading : true
		}
		this.serverInstance = this.session.getServerInstance('ROC');
		this.accessToken = this.session.getAccessToken();
		this.getRow = this.getRow.bind(this);
		this.getFrame = this.getFrame.bind(this);
		this.button.func = this.button.func.bind(this);
		this.org_id = this.session.getLoggedInCompanyId();
		this.addressId = null;
		this.addressDetails=[];
	}
	componentDidMount() {
		this.getFrame(1, this.recordsPerFrame);
	}

	async getFrame(offset, recordsPerFrame) {
		try{
			const retData =
				await serviceRoutines.linked_Addresslist(this.serverInstance, this.accessToken, offset,
														recordsPerFrame, this.session.getLoggedInCompanyId());
			this.setState({isLoading : false});
			if (retData.length > 0) {
				let addressList = retData;
				this.setState({ data: this.state.data.concat(addressList) });
			}
		}
		catch(e) {
			this.setError(raiseError(e));
		}
	}
	deleteHandler = (address_id, arrayIndex) => {
		this.selectedAddressId = address_id;
		this.selectArrayIndex = arrayIndex;
		this.setState({showModal: true});
	}

	viewHandler = async (addressId, arrayIndex) => {
		this.callForm(EditAddress, {readOnly: true, addressId, arrayIndex, ...this.props}, this.onReturn);
        /*
		try {
			const retData =
				await serviceRoutines.addressdata(this.session.getServerInstance('ROC'),
													this.session.getAccessToken(), address_id);
			if (retData !== undefined && retData !== null) {
				this.addressId = retData.address_id;
				this.addressDetails =retData;
				this.setState({data1:this.addressDetails});
				this.setState({viewModal: true});
			}
		}
		catch (e) {
			this.setError(raiseError(e.error_message));
		}
        */
	}


	editHandler = (addressId, arrayIndex) => {
		this.callForm(EditAddress, {addressId, arrayIndex, ...this.props}, this.onReturn);
	}

	closeModal= () => {
		this.setState({showModal:false});
	}

	closeViewModal = () => {
		this.setState({ viewModal: false });
	}
	getRow(data, index, pageNumber, arrayIndex){

		this.pageNumber = pageNumber;
		const is_default = (data.is_default === false);
		return(

			<tr key={index}>
				<td>
					<button type="button" style={{color: "blue", textDecoration: "underline",
							cursor: "pointer", border:"none", background:"none", padding:0}}
							onClick={() => this.viewHandler(data.address_id, arrayIndex)}>
						{data.address_line_1}
					</button>
				</td>
				<td>{data.address_line_2}</td>
				<td>{data.city}</td>
				<td>{refcode_country[data.cntry]}</td>
				<td>
					{is_default ? (
						<>
							<span onClick={()=>this.editHandler(data.address_id, arrayIndex)}
							title="Edit"
							style={{ cursor: 'pointer' }}>
							<i className="bi bi-pencil-square" style={{fontSize:20}} ></i>
							</span>&nbsp;&nbsp;
							<span onClick={()=>this.deleteHandler(data.address_id, arrayIndex)}
							title="Delete"
							style={{ cursor: 'pointer' }} >
							<i className="bi bi-trash3" style={{fontSize:20}} ></i>
							</span>&nbsp;&nbsp;
							</>
						) : (
							<></>
						)
					}
				</td>
			</tr>
		)
	}

	getHeader(){
		let key = 0;
		return (
			<tr key={++key}>
				<th key={++key}>Address L1</th>
				<th key={++key}>Address L2</th>
				<th key={++key}>City</th>
				<th key={++key}>Country</th>
				<th key={++key}>Actions</th>
			</tr>
		);
	}

	onReturn = (ret) => {
		this.setState((prevState, props) => {
			let newState = deepCopy(prevState);
			this.pageNumber = 1;
			newState.data = [];
			this.getFrame(1, this.recordsPerFrame);
			return newState;
		});
	}

	backHandler = () => {
		if (this.isChildMode()) this.props.returnToParent({retFrom:"edit"});
		else console.log("LOADED AS A PARENT");
	}

	onConfirm = async(control) => {
		try{
			const retData = await serviceRoutines.linked_Addressfetch(this.serverInstance, this.accessToken, this.selectedAddressId);
			if (retData !== undefined && retData !== null) {
				await serviceRoutines.linked_Addressdelete(this.serverInstance, this.accessToken, retData)
				let updatedArrayIndex = ((this.pageNumber - 1) * this.recordsPerPage) + this.selectArrayIndex;
				this.state.data.splice(updatedArrayIndex, 1);
				this.setState( { data: this.state.data } );
				this.setState( {showModal:false} );
			}
		}
		catch(e){
			control.error = true;
			control.errorMsg = e.error_message;
		}
	}
	button = {
		literal : "Add",
		func : function(){
			this.callForm(AddAddress, {...this.props}, this.onReturn);
		}
	}

	render(){
		let buttons = [];

		let modalContent = <div className="container pt-0 m-0 ">
			<EditAddress calledFrom={"view"}
				orgId={this.org_id}
				addressId={this.addressId}
				readOnly={true}
				noFormTag={true}
				{...this.props} />
		</div>

		const tableclass = "table table-striped border-dark";
		let datablk = <div className="container-fluid pt-0 m-0 pe-2">

			<div className="col-sm-12">
				<GenericTable
					pageHeading={this.getBreadcrumb()}
					pageHeadingClass="h6 text-left"
					data={this.state.data}
					recordsPerPage={this.recordsPerPage}
					getRow = {this.getRow}
					getHeader={this.getHeader}
					tableclass={tableclass}
					getFrame={this.getFrame}
					recordsPerFrame={this.recordsPerFrame}
					pageNumber={this.pageNumber}
					isLoading = {this.state.isLoading}
					button = {this.button}
				/>
			</div>
			{(this.state.showModal) && <>
					<Modal
						title="Delete Confirmation"
						msg="Are you sure, you want to delete the address?"
						btn1 = "Confirm"
						btn2 = "Cancel"
						onClick = {this.closeModal}
						onConfirm = {this.onConfirm}
					/>
					<Backdrop />
				</>
			}
			{(this.state.viewModal) && <>
					<Modal_view_content
						title={this.getBreadcrumb([window.getLabelLiteral("VIEW")])}
						btn1="Close"
						onClick={this.closeViewModal}
						modalContent={modalContent}
					/>
					<Backdrop />
				</>
			}
		</div>

		let frm = <GenericForm datablk={datablk} buttons={buttons} controlProps={this.controlProps} {...this.props } />
		return frm;
	}
}

export default ManageAddress;
