import { handleAxiosHTTPException } from "WebUI_Framework";

let receivedinvoicesserviceroutines = {};

receivedinvoicesserviceroutines.list = async (serverInstance, accessToken, obj) => {
    let response;
    try {
        response =
            await serverInstance.put(
                `biop/subscriber/received_invoice/list`,
                obj,
                {
                    headers: {
                        "X-Auth": accessToken
                    }
                }
            );
        return response.data;
    }
    catch (e) {
        return handleAxiosHTTPException(e);
    }
}

receivedinvoicesserviceroutines.fetch = async (serverInstance, accessToken, selectedInvoiceId) => {
    let response;
    try {
        response = await serverInstance.get(`biop/subscriber/received_invoice/fetch?invoice_id=${selectedInvoiceId}`,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
        );

        return response.data;
    }
    catch (e) {
        return handleAxiosHTTPException(e);
    }
}

receivedinvoicesserviceroutines.acknowledge = async (serverInstance, accessToken, invoice_header) => {
    let response;
    try {
        response = await serverInstance.put(`biop/subscriber/received_invoice/acknowledge`,
        invoice_header,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
        );

        return response.data;
    }
    catch (e) {
        return handleAxiosHTTPException(e);
    }
}

receivedinvoicesserviceroutines.accept = async (serverInstance, accessToken, invoice_header) => {
    let response;
    try {
        response = await serverInstance.put(`biop/subscriber/received_invoice/accept`,
            invoice_header,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
        );

        return response.data;
    }
    catch (e) {
        return handleAxiosHTTPException(e);
    }
}

receivedinvoicesserviceroutines.payment_done = async (serverInstance, accessToken, invoice_header) => {
    let response;
    try {
        response = await serverInstance.put(`biop/subscriber/received_invoice/payment_done`,
            invoice_header,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
        );

        return response.data;
    }
    catch (e) {
        return handleAxiosHTTPException(e);
    }
}

receivedinvoicesserviceroutines.reject = async (serverInstance, accessToken, invoice_header) => {
    let response;
    try {
        response = await serverInstance.put(`biop/subscriber/received_invoice/reject`,
            invoice_header,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
        );

        return response.data;
    }
    catch (e) {
        return handleAxiosHTTPException(e);
    }
}

receivedinvoicesserviceroutines.fetch_first_level_network = async (serverInstance, accessToken, org_id, offset,
    recordsPerFrame, searchtext, is_subscriber) => {
    let response;
    try {
        response = await serverInstance.get(`/biop/subscriber/control/fetch_first_level_network`,
            {
                headers: {
                    "X-Auth": accessToken
                },
                params: {
                    org_id: org_id,
                    offset: offset,
                    num_recs: recordsPerFrame,
                    org_name: searchtext,
                    is_subscriber: is_subscriber,
                }
            },
        );
        return response.data;
    }
    catch (e) {
        return handleAxiosHTTPException(e);
    }
}

export default receivedinvoicesserviceroutines;