import { deepCopy, FormField, MultiRecFormField } from 'WebUI_Framework';
import { useState } from "react";
import commonServiceRoutines from './CommonServiceRoutines'
import { formatAddress } from './utils';

function AddressFilter(props) {
	if (typeof(props.selectOption) !== "function") {
		throw new Error("AddressFilter: Invalid input selectOption");
	}
	if (props.field === null || props.field === undefined) {
		throw new Error("AddressFilter: Field definition must be passed");
	}
	if (props.controlProps === null || props.controlProps === undefined) {
		throw new Error("AddressFilter: controlProps definition must be passed");
	}
	let orgId;
	if (props.orgId === null || props.orgId === undefined) {
		throw new Error("orgId: controlProps definition must be passed");
	}
	orgId = props.orgId;
	let readOnly = false;
	if (props.readOnly !== null && props.readOnly !== undefined) {
		readOnly = props.readOnly;
	}
	let onChange = (event) => {
		if (props.onChange) {
			return props.onChange(event);
		}
	};
	let inMultiRec = false;
	let i;
	if (props.inMultiRec !== null && props.inMultiRec !== undefined) {
		inMultiRec = props.inMultiRec;
		if (props.i === null || props.i === undefined) {
			throw new Error("AddressFilter: iterator i is necessary in a multirec field");
		}
		i = props.i;
	}

	const session = props.controlProps.cInstance.session;
	if (session === null || session === undefined) {
		throw new Error("AddressFilter: Unable to deduce session");
	}
	const accessToken = session.getAccessToken();
	if (accessToken === null || accessToken === undefined) {
		throw new Error("AddressFilter: Unable to deduce accessToken");
	}
	const loggedInCompanyId = session.getLoggedInCompanyId();
	if (loggedInCompanyId === null || loggedInCompanyId === undefined) {
		throw new Error("AddressFilter: Unable to deduce loggedInCompanyId");
	}
	const parentCompanyId = session.getLoggedInParentCompanyId();
	if (parentCompanyId === null || parentCompanyId === undefined) {
		throw new Error("AddressFilter: Unable to deduce parentCompanyId");
	}

	let [addressId, setAddressId] = useState(!(props.addressId)?"":props.addressId);

	let clearSelection = () => {
		addressId = '';
		props.selectOption('', '');
		setAddressId(addressId);
	}

	let local_selectOption = (index, list) => {
		return props.selectOption(list[index].address_id, list[index].formatted_address);
	}

	const filterAddressList = (list, searchText) => {
		list.map((x, i) => {
            x.formatted_address = formatAddress(x)

            return x;
        });

        return list;
	}

	let getDataList = async (control, searchText) => {
		try {
			const retData =
				await commonServiceRoutines.fetchAddressList(session, orgId);
			const list = filterAddressList(deepCopy(retData), searchText);

            return list;
		}
		catch (e) {
			control.error = true;
			if (e.error_message !== null && e.error_message !== undefined) {
				control.errorMsg = e.error_message;
			}
			else {
				control.errorMsg = e.message;
			}
		}
	}

	if (!inMultiRec) {
		return <FormField
					field={ props.field }
					controlProps={ props.controlProps }
					getDataList={(control, searchtext) => { return getDataList(control, searchtext)}}
					selectOption={ local_selectOption }
					clearSelection={clearSelection}
					readOnly={readOnly}
					onChange={onChange}
					style={props.style}
					width2={props.width2}
					w_pcnt={props.w_pcnt}
					w_pcnt1={props.w_pcnt1}
					w_pcnt2={props.w_pcnt2}
                    keyName={"address_id"}
                    descName={"formatted_address"}
                    dependency={props.dependency}
				/>
			;
	}
	else {
		return <MultiRecFormField
					field={ props.field }
					controlProps={ props.controlProps }
					getDataList={(control, searchtext) => { return getDataList(control, searchtext)}}
					selectOption={ local_selectOption }
					clearSelection={clearSelection}
					readOnly={readOnly}
					onChange={onChange}
					index={i}
					style={props.style}
					width2={props.width2}
					w_pcnt={props.w_pcnt}
					w_pcnt1={props.w_pcnt1}
					w_pcnt2={props.w_pcnt2}
					multiRecId={props.multiRecId}
                    keyName={"address_id"}
                    descName={"formatted_address"}
                    dependency={props.dependency}
				/>
			;
	}

}

export default AddressFilter;
