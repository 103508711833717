import {
	handleAxiosHTTPException
} from "WebUI_Framework";

let DeliveryNoteServiceRoutines = {};

DeliveryNoteServiceRoutines.list = async ( serverInstance, accessToken, offset, recordsPerFrame, org_id) => {
	let response;
	try {
		response = await serverInstance.get(
			`/biop/subscriber/delivery_note/list`,
			{
				headers: {
					"X-Auth": accessToken,
				},
				params : {
					offset: offset,
					num_recs: recordsPerFrame,
					deleted: 0,
                    seller_org_id : org_id
				}
			}
		);
	} catch (e) {
		console.log(e);
		return handleAxiosHTTPException(e);
	}
	return response.data;
}

DeliveryNoteServiceRoutines.fetch = async (serverInstance, accessToken, deliveryNoteId) => {
    let response;
    try {
        response = await serverInstance.get(
			`/biop/subscriber/delivery_note/fetch?delivery_note_id=${ deliveryNoteId }`,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
		console.log(e);
        return handleAxiosHTTPException(e);
	}
}

DeliveryNoteServiceRoutines.fetchAmended = async (serverInstance, accessToken, deliveryNoteId) => {
    let response;
    try {
        response = await serverInstance.get(
			`/biop/subscriber/delivery_note/fetch_amended?delivery_note_id=${ deliveryNoteId }`,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
		console.log(e);
        return handleAxiosHTTPException(e);
	}
}

DeliveryNoteServiceRoutines.fetchUsingNumberAndDate = async (serverInstance, accessToken, deliveryNoteNumber, deliveryNoteDate, sellerOrgId) => {
    let response;
	let params = {
		delivery_note_number : deliveryNoteNumber,
		delivery_note_date : deliveryNoteDate,
        seller_org_id : sellerOrgId
	};
    try {
        response = await serverInstance.get(
			`/biop/subscriber/delivery_note/fetch_using_number_and_date`,
            {
				params,
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
		console.log(e);
        return handleAxiosHTTPException(e);
	}
}

DeliveryNoteServiceRoutines.fetch_sales_order = async (serverInstance, accessToken, buyerOrgId, orderIssueDate, orderNumber) => {
    let response;
    let params = {};
    if (orderNumber) {
        params.order_number = orderNumber;
    }
    if (orderIssueDate) {
        params.order_issue_date = orderIssueDate;
    }
    if (buyerOrgId) {
        params.buyer_org_id = buyerOrgId;
    }

    try {
        response = await serverInstance.get(
            `/biop/subscriber/sales_order/fetch_using_number_and_date`,
            {
                params,
                headers: {
                    "X-Auth": accessToken
                }
            }
        );
        return response.data;
    }
    catch (e) {
        console.log(e);
        return handleAxiosHTTPException(e);
    }
}

DeliveryNoteServiceRoutines.add = async (serverInstance, accessToken, deliveryNote) => {
    let response;
    try {
        response = await serverInstance.post('/biop/subscriber/delivery_note/add',
            deliveryNote,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}

DeliveryNoteServiceRoutines.modify = async (serverInstance, accessToken, deliveryNote) => {
    let response;
    try {
        response = await serverInstance.put('/biop/subscriber/delivery_note/modify',
            deliveryNote,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}

DeliveryNoteServiceRoutines.amend = async (serverInstance, accessToken, deliveryNote) => {
    let response;
    try {
        response = await serverInstance.put('/biop/subscriber/delivery_note/amend',
            deliveryNote,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}

DeliveryNoteServiceRoutines.cancelAmendMent = async (serverInstance, accessToken, deliveryNote) => {
    let response;
    try {
        response = await serverInstance.put('/biop/subscriber/delivery_note/cancel_amendment',
            deliveryNote,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}

DeliveryNoteServiceRoutines.approve = async (serverInstance, accessToken, deliveryNote) => {
    let response;
    try {
        response = await serverInstance.put('/biop/subscriber/delivery_note/approve',
            deliveryNote,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}

DeliveryNoteServiceRoutines.delete = async (serverInstance, accessToken, deliveryNote) => {
    let response;
    try {
        response = await serverInstance.put('/biop/subscriber/delivery_note/delete',
            deliveryNote,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}

DeliveryNoteServiceRoutines.send = async (serverInstance, accessToken, deliveryNote) => {
    let response;
    try {
        response = await serverInstance.put('/biop/subscriber/delivery_note/send',
            deliveryNote,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}


export default DeliveryNoteServiceRoutines;
