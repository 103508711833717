import {
		GenericComponent,
		GenericTable,
		GenericForm ,
		deepCopy,
		Modal,
		Backdrop,
		getInitialState,
		raiseError,
		Modal_view_content } from 'WebUI_Framework';
import  AddBankDetail from './AddBankDetail';
import  EditBankDetail from './EditBankDetail';
import 'bootstrap-icons/font/bootstrap-icons.css';
import  serviceRoutines from './BankdetailsServiceRoutines';

class ManageBankAccounts extends GenericComponent{

	constructor(p){
		super(p);
		this.recordsPerFrame = window.getParam("CURSOR_FRAME_SIZE");
		this.pageNumber = 1;
		this.selectedOrgId = 0;
		this.selected_acct_identifier = "";
		this.selectArrayIndex = 0;
        this.recordsPerPage = window.getParam("TABLE_PAGE_SIZE");
		this.state.showErrorFlag = false;
		this.error1 = "";
		this.state = {
			isLoading : true,
			data : [],
			showModal : false
		}
		this.org_id = this.session.getLoggedInCompanyId();
		this.serverInstance = this.session.getServerInstance('SUBSCRIBER');
		this.accessToken = this.session.getAccessToken();
		this.getRow = this.getRow.bind(this);
		this.getFrame = this.getFrame.bind(this);
		this.onConfirm = this.onConfirm.bind(this);
		this.button.func = this.button.func.bind(this);
		this.acct_identifier=null;
	}

	componentDidMount() {
		this.getFrame(1,this.recordsPerFrame);
	}

	initialState = getInitialState();

	state = {
		data : deepCopy(this.initialState),
	}


	onReturn = (ret) => {

		this.setState((prevState, props) => {
			let newState = deepCopy(prevState);

			if(ret.retFrom==="add"){
				this.pageNumber = 1;
				newState.data = [];
				this.getFrame(1, this.recordsPerFrame);
			}
			else if (ret.retFrom==="edit"){
				let updatedArrayIndex = ((this.pageNumber - 1) * this.recordsPerPage) + ret.arrayIndex;
				newState.data[updatedArrayIndex] = ret.selectData;
			}
			return newState;
		});
	}

	viewHandler = async (orgId,acct_identifier, arrayIndex) => {
		this.callForm(EditBankDetail, {readOnly: true, orgId, acct_identifier, arrayIndex,...this.props}, this.onReturn );
        /*
		try {
			let retData =
					await serviceRoutines.fetch(this.session.getServerInstance('ROC'),
									this.session.getAccessToken(), orgId,acct_identifier)
			if (retData !== undefined && retData !== null) {
				this.acct_identifier = retData.acct_identifier;
				this.orgId=retData.org_id;
				this.setState({data1:this.bankDetails});
				this.setState({ viewModal: true });
			}
		}
		catch (e) {
			this.setError(raiseError(e.error_message));
		}
        */
	}

	addHandler = () => {
		this.callForm(AddBankDetail, {...this.props}, this.onReturn );
	}

	editHandler = (orgId, acct_identifier, arrayIndex) => {
		this.callForm(EditBankDetail, {orgId, acct_identifier, arrayIndex,...this.props}, this.onReturn );
	}


	deleteHandler = ( orgId,acct_identifier, arrayIndex) => {

		this.selectedOrgId = orgId;
		this.selected_acct_identifier = acct_identifier;
		this.selectArrayIndex = arrayIndex;
		this.setState({showModal: true});
	}

	closeModal = () =>{
		this.setState({showModal : false});
	}


	onConfirm = async(control) =>{
		try {
			const retData = await serviceRoutines.fetch(this.serverInstance,this.accessToken,this.selectedOrgId,this.selected_acct_identifier)
			if(retData !== undefined && retData !== null)
			{
				await serviceRoutines.delete(this.serverInstance,this.accessToken,retData)
				let updatedArrayIndex = ((this.pageNumber - 1) * this.recordsPerPage) + this.selectArrayIndex;
				this.state.data.splice(updatedArrayIndex,1);
				this.setState({data: this.state.data});
				this.setState({showModal:false});
			}
		}
		catch(e) {
			control.error = true;
			control.errorMsg = e.error_message;
		}
	}

	async getFrame(offset,recordsPerFrame) {
		let retData;
		try {
			retData  = await serviceRoutines.list(this.serverInstance,this.accessToken,offset,recordsPerFrame,this.org_id);
			this.setState({isLoading : false});
			if (retData.length > 0) {
				let bankDetail = retData;
				this.setState({ data: this.state.data.concat(bankDetail) });
			}
		}
		catch(e) {
			console.log(e);
			this.setError(raiseError(e.err_message));
		}
	}

	closeViewModal = () => {
		this.setState({ viewModal: false });
	}

	getHeader(){
		let key = 0;
		return (
		<tr key={++key}>
			<th key={++key}>Account Identifier</th>
			<th key={++key}>Account Name</th>
			<th key={++key}>Account Number</th>
			<th key={++key}>Bank Identifier</th>
			<th key={++key}>Actions</th>
		</tr>
		);
	}



	getRow(data, index, pageNumber, arrayIndex){

		this.pageNumber = pageNumber;
		return(
			<tr key={index}>
				<td>
					<button type="button" style={{ color: "blue", textDecoration: "underline",
							cursor: "pointer",border:"none", background:"none", padding:0 }}
							onClick={() => this.viewHandler(data.org_id, data.acct_identifier, arrayIndex)}>
						{data.acct_identifier}
					</button>
				</td>
				<td>{data.acct_name}</td>
				<td>{data.acct_number}</td>
				<td>{data.bank_identifier}</td>
				<td>
					<span onClick={()=>this.editHandler(data.org_id, data.acct_identifier, arrayIndex )}
					title="Edit"
					style={{ cursor: 'pointer' }}>
					<i className="bi bi-pencil-square" style={{fontSize:20}} ></i>
					</span>&nbsp;&nbsp;
					<span onClick={()=>this.deleteHandler(data.org_id,data.acct_identifier,arrayIndex)}
					title="Delete"
					style={{ cursor: 'pointer' }} >
					<i className="bi bi-trash3" style={{fontSize:20}} ></i>
					</span>
				</td>
			</tr>
		)
	}

	button = {
		literal : "Add",
		func : function(){
			this.callForm(AddBankDetail, {...this.props}, this.onReturn );
		}
	}

	render() {
		let buttons = [];

		let modalContent = <div className="container pt-0 m-0 ">
			<EditBankDetail calledFrom={"view"}
				orgId={this.org_id}
				acct_identifier={this.acct_identifier}
				readOnly={true}
				noFormTag={true}
				{...this.props} />
			</div>

		const tableclass = "table table-striped border-dark";
		let datablk = <div className="container-fluid pt-0 m-0 pe-2">
			<div className="col-sm-12">
				<GenericTable
					pageHeading={this.getBreadcrumb()}
					pageHeadingClass="h6 text-left"
					data={this.state.data}
					recordsPerPage={this.recordsPerPage}
					getRow = {this.getRow}
					getHeader={this.getHeader}
					tableclass={tableclass}
					getFrame={this.getFrame}
					recordsPerFrame={this.recordsPerFrame}
					pageNumber={this.pageNumber}
					isLoading={this.state.isLoading}
					button = {this.button}
				 />
			</div>
			{(this.state.showModal) && <Modal
				 title="Delete Confirmation"
				 msg="Are you sure, you want to delete the Bank Details?"
				 btn1 = "Confirm"
				 btn2 = "Cancel"
				 onClick = {this.closeModal}
				 onConfirm = {this.onConfirm}
				 />

				 }
				 {(this.state.showModal) && <Backdrop />}
			{(this.state.viewModal) && <Modal_view_content
				title={this.getBreadcrumb([window.getLabelLiteral("VIEW")])}
				btn1="Close"
				onClick={this.closeViewModal}
				modalContent={modalContent}
				/>
			}
			{(this.state.viewModal) && <Backdrop />}
		</div>

		let frm = <GenericForm datablk={datablk} buttons={buttons} controlProps={this.controlProps} {...this.props } />
		return frm;
	}
}

export default ManageBankAccounts;
