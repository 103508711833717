import {
	GenericComponent,
	email_id,
	emailid,
	user_name,
	username,
	contact_phone_number,
	contactphonenumber,
	genericstring,
	generic_string,
	generic_integer,
	genericinteger,
	genericgenrefcode,
	generic_genrefcode,
	documentnumber,
	document_number,
	inputdate,
	input_date,
	currency,
	Currency,
	getInitialState,
	deepCopy,
	FormField,
	NarrowButton,
	GenericForm,
	copyDataFieldsToFormFields,
	GenericTable,
	formatAmount,
    file_set,
    FileSet
} from 'WebUI_Framework';
import Attachments from '../common/Attachments';

let mapping = [
	["order_number", "order_number"],
	["order_issue_date", "order_issue_date"],
	["order_contact_user_name", "order_contact_user_name"],
	["order_contact_email_id", "order_contact_email_id"],
	["order_contact_phone_num", "order_contact_phone_num"],
	["order_valid_from_date", "order_valid_from_date"],
	["order_valid_to_date", "order_valid_to_date"],
	["seller_contact_user_name", "seller_contact_user_name"],
	["seller_contact_email_id", "seller_contact_email_id"],
	["seller_contact_phone_num", "seller_contact_phone_num"],
	["delivery_contact_user_name", "delivery_contact_user_name"],
	["delivery_contact_email_id", "delivery_contact_email_id"],
	["delivery_contact_phone_num", "delivery_contact_phone_num"],
	["description", "description"],
	["notes", "notes"],
	["billing_contact_user_name", "billing_contact_user_name"],
	["billing_contact_email_id", "billing_contact_email_id"],
	["billing_contact_phone_num", "billing_contact_phone_num"],
	["payment_period", "payment_period"],
	["payment_period_unit", "payment_period_unit"],
	["currency", "currency"],
	["version", "version"],
	["attachments", "attachments"]
];

const fields = {
	order_number: {
		id: "order_number", name: "order_number", label: "PO Number:",
		class: documentnumber, component: document_number, mandatory: false
	},

	order_issue_date: {
		id: "order_issue_date", name: "order_issue_date",
		label: "PO Issue Date:", class: inputdate, component: input_date, mandatory: false
	},

	order_contact_user_name: {
		id: "order_contact_user_name", name: "order_contact_user_name",
		label: "Contact Person:", class: username, component: user_name, mandatory: false
	},

	order_contact_email_id: {
		id: "order_contact_email_id", name: "order_contact_email_id",
		label: "Contact Email:", class: emailid, component: email_id, mandatory: false
	},

	order_contact_phone_num: {
		id: "order_contact_phone_num", name: "order_contact_phone_num",
		label: "Contact Phone:", class: contactphonenumber, component: contact_phone_number, mandatory: false
	},

	order_valid_from_date: {
		id: "order_valid_from_date", name: "order_valid_from_date",
		label: "PO Valid From:", class: inputdate, component: input_date, mandatory: false
	},

	order_valid_to_date: {
		id: "order_valid_to_date", name: "order_valid_to_date",
		label: "PO Valid Till:", class: inputdate, component: input_date, mandatory: false
	},

	description: {
		id: "description", name: "description", label: "Description:",
		class: genericstring, component: generic_string, mandatory: false
	},
	buyer_org_name: {
		id: "buyer_org_name", name: "buyer_org_name", label: "Issued By:",
		class: genericstring, component: generic_string, mandatory: false
	},
	str_seller_address: {
		id: "str_seller_address", name: "str_seller_address",
		label: "Address:", class: genericstring, component: generic_string, mandatory: false
	},

	seller_contact_user_name: {
		id: "seller_contact_user_name", name: "seller_contact_user_name",
		label: "Contact Person:", class: username, component: user_name, mandatory: false
	},

	seller_contact_email_id: {
		id: "seller_contact_email_id", name: "seller_contact_email_id",
		label: "Contact Email:", class: emailid, component: email_id, mandatory: false
	},

	seller_contact_phone_num: {
		id: "seller_contact_phone_num", name: "seller_contact_phone_num",
		label: "Contact Phone:", class: contactphonenumber, component: contact_phone_number, mandatory: false
	},

	notes: {
		id: "notes", name: "notes", label: "Notes:",
		class: genericstring, component: generic_string, mandatory: false
	},

	str_billing_address: {
		id: "str_billing_address", name: "str_billing_address",
		label: "Address:", class: genericstring, component: generic_string, mandatory: false
	},

	billing_contact_user_name: {
		id: "billing_contact_user_name", name: "billing_contact_user_name",
		label: "Contact Person:", class: username, component: user_name, mandatory: false
	},

	billing_contact_email_id: {
		id: "billing_contact_email_id", name: "billing_contact_email_id",
		label: "Contact Email:", class: emailid, component: email_id, mandatory: false
	},

	billing_contact_phone_num: {
		id: "billing_contact_phone_num", name: "billing_contact_phone_num",
		label: "Contact Phone:", class: contactphonenumber, component: contact_phone_number, mandatory: false
	},

	str_delivery_address: {
		id: "str_delivery_address", name: "str_delivery_address",
		label: "Address:", class: genericstring, component: generic_string, mandatory: false
	},


	delivery_contact_user_name: {
		id: "delivery_contact_user_name", name: "delivery_contact_user_name",
		label: "Contact Person:", class: username, component: user_name, mandatory: false
	},

	delivery_contact_email_id: {
		id: "delivery_contact_email_id", name: "delivery_contact_email_id",
		label: "Contact Email:", class: emailid, component: email_id, mandatory: false
	},

	delivery_contact_phone_num: {
		id: "delivery_contact_phone_num", name: "delivery_contact_phone_num",
		label: "Contact Phone:", class: contactphonenumber, component: contact_phone_number, mandatory: false
	},

	currency: {
		id: "currency", name: "currency", label: "Currency:",
		class: Currency, component: currency, mandatory: false
	},

	payment_period: {
		id: "payment_period", name: "payment_period", label: "Payment period:",
		class: genericinteger, component: generic_integer, mandatory: false
	},

	payment_period_unit: {
		id: "payment_period_unit", name: "payment_period_unit", label: "Payment period unit:",
		class: genericgenrefcode, component: generic_genrefcode, mandatory: false
	},

    attachments: {
		id: "attachments",
		name: "attachments",
		label: window.getLabelLiteralWithColon("ATTACHMENTS"),
		class: file_set,
		component: FileSet,
		mandatory: false,
		//initialValue: "1234244.00",
	},
}

const formatAddress = (address) => {
	let strAddress = "";
	strAddress += ((address.address_line_1) ? address.address_line_1 : "") + ",";
	strAddress += strAddress + ((address.address_line_2) ? address.address_line_2 : "") + ",";
	strAddress += strAddress + (address.city) ? address.city : "";
	return strAddress;
}

class ViewSalesOrder extends GenericComponent {
	constructor(props) {
		super(props)
		this.salesOrderDetails = this.props.orderDetails;
		this.salesOrderHeader = this.props.orderHeaderObject;
		this.pageNumber = 1;
		this.readOnly = true;
		this.getRow = this.getRow.bind(this);
		this.getFrame = this.getFrame.bind(this);
	}

	componentDidMount() {
		this.setState({ data: this.salesOrderHeader });
		let data = {};

		data.str_seller_address = formatAddress(this.salesOrderHeader.seller_address);
		data.str_billing_address = formatAddress(this.salesOrderHeader.billing_address);
		data.str_delivery_address = formatAddress(this.salesOrderHeader.delivery_address);
		data.buyer_org_name = this.salesOrderHeader.buyer_org_name;

		copyDataFieldsToFormFields(mapping, this.salesOrderHeader, data);

		this.setState({ data });
	}

	initialState = getInitialState(fields);

	state = {
		data: deepCopy(this.initialState),
	}

	getFrame(offset, recordsPerFrame) {

		return true;
	}

	getRow(data, index, pageNumber, arrayIndex) {

		let amount = data.quantity * data.rate_per_unit;
		let currency = this.salesOrderHeader.currency;

		return (
			<tr key={index}>
				<td>{data.seller_item_id}</td>
				<td>{data.seller_item_description}</td>
				<td>{window.getRefCode("00006")[data.unit_of_measure]}</td>
				<td>{data.quantity}</td>
				<td>{formatAmount(data.rate_per_unit, currency)}</td>
				<td>{formatAmount(amount, currency)}</td>
				<td>{data.item_delivery_to_date}</td>
				{(!this.readOnly) && (!data.deleted) && (data.status === undefined || data.status === false) &&
					<td>
						<input
							className="form-check-input"
							onChange={e => this.checkDeletedHandler(e, arrayIndex)} style={{ fontSize: 18 }}
							type="checkbox" id={"checkDeleted" + index}
							name={"checkDeleted" + index} value=""
							checked={(data.status) ? "checked" : ''} />
						&nbsp;<label className="form-check-label" htmlFor={"checkDeleted" + index}>Deleted</label>
					</td>
				}
				{(!this.readOnly) && (data.deleted) && (data.status === true) &&
					<td>
						<input
							className="form-check-input"
							onChange={e => this.checkDeletedHandler(e, arrayIndex)} style={{ fontSize: 18 }}
							type="checkbox"
							id={"checkDeleted" + index}
							name={"checkDeleted" + index} value=""
							checked={(data.status) ? "checked" : ''} />
						&nbsp;<label className="form-check-label" htmlFor={"checkDeleted" + index}>Deleted</label>
					</td>
				}
				{(!this.readOnly) && (data.deleted) && (data.status === undefined) &&
					<td>
						&nbsp;&nbsp; &nbsp;&nbsp;
						<label className="form-check-label">Deleted</label>
					</td>
				}
				{(!this.readOnly) && (data.deleted) &&
					<td> </td>
				}
				{(!this.readOnly) && (!data.deleted) &&
					<td>
						<span onClick={() => this.editHandler(data, arrayIndex)}>
							<i className="bi bi-pencil-square" style={{ fontSize: 20 }} ></i>
						</span>

					</td>
				}
				{(this.readOnly) && (data.deleted) && (data.status) &&
					<td> To Delete </td>
				}
				{(this.readOnly) && (data.deleted) && (!data.status) &&
					<td> Deleted </td>
				}
				{(this.readOnly) && (!data.deleted) &&

					<td> Active </td>
				}
			</tr>
		)
	}

	getHeader() {
		let key = 0;
		return (
			<tr key={++key}>
				<th key={++key}>Seller Item Id</th>
				<th key={++key}>Description</th>
				<th key={++key}>Unit</th>
				<th key={++key}>Qty</th>
				<th key={++key}>Rate</th>
				<th key={++key}>Amount</th>
				<th key={++key}>Delivery Date</th>
				<th key={++key}>Status</th>
			</tr>

		);
	}

	backHandler = (retFrom) => {
		if (this.isChildMode()) this.props.returnToParent({ retFrom: "view" });
		else console.log("LOADED AS A PARENT");
	}

	render() {
		let buttons = [];
		let key = 0;
		if (this.props.readOnly) {
			this.readOnly = true;
			this.disabled = true;
		}

		let datablk = <div className="container-fluid pt-0 m-0 pe-2">
			<div className="row p-0 m-0 g-0 " >
				<div className="col-sm-6 h6 text-left" >
					{this.getBreadcrumb(["View"])}
				</div>
				<div className="col-sm-6 d-flex justify-content-end">
					<NarrowButton value="Back" onClick={this.backHandler} key={(++key).toString()} />
				</div>


				<div className="col-sm-6" >
					<FormField field={fields.order_number} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.order_issue_date} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>


				<div className="col-sm-6" >
					<FormField field={fields.order_contact_user_name} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.order_contact_email_id} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.order_contact_phone_num} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.description} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>
				<div className="col-sm-6" >
					<FormField field={fields.order_valid_from_date} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.order_valid_to_date} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>
				<div className="col-sm-6" >
					<FormField field={fields.currency} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>
				<div className="col-sm-6" >
					<FormField field={fields.buyer_org_name} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>
				<div className="col-sm-6">
					<FormField
						field={fields.payment_period}
						style={{ textAlign: "left" }}
						controlProps={this.controlProps}
						readOnly={this.readOnly}
					/>
				</div>
				<div className="col-sm-6">
					<FormField
						field={fields.payment_period_unit}
						controlProps={this.controlProps}
						readOnly={this.readOnly}
						refType={"PeriodUnitType"}
					/>
				</div>
                <div className="col-sm-6 mt-3 mb-3 ">
						<Attachments
							field={fields.attachments}
							controlProps={this.controlProps}
							readOnly={this.readOnly}
						/>
				</div>

				<div className="col-sm-6" ></div>

				<div className="col-sm-12 h4 text-left" > <hr></hr>Vendor Details </div>

				<div className="col-sm-6" >
					<FormField field={fields.str_seller_address} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.seller_contact_user_name} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.seller_contact_email_id} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.seller_contact_phone_num} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.notes} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>


				<div className="col-sm-12 h4 text-left" > <hr></hr>Billing Details </div>

				<div className="col-sm-6" >
					<FormField field={fields.str_billing_address} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.billing_contact_email_id} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.billing_contact_phone_num} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.billing_contact_user_name} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-12 h4 text-left" > <hr></hr>Delivery Details </div>

				<div className="col-sm-6" >
					<FormField field={fields.str_delivery_address} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.delivery_contact_email_id} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.delivery_contact_phone_num} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.delivery_contact_user_name} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-12 h4 text-left" > <hr></hr>Line Items </div>
				<div className="col-sm-12">
					<GenericTable
						data={this.salesOrderDetails}
						recordsPerPage={50}
						getRow={this.getRow}
						getHeader={this.getHeader}
						tableclass={"table table-striped border-dark"}
						getFrame={this.getFrame}
						recordsPerFrame={20}
						pageNumber={this.pageNumber}
						isLoading={false}
						showSearch={false}
					/>
				</div>

			</div>
		</div>

		let form = <GenericForm datablk={datablk} buttons={buttons} controlProps={this.controlProps} {...this.props} />;
		return form;
	}
}

export default ViewSalesOrder;
