import React from 'react';
import {
	GenericComponent
	, GenericForm
	, getInitialState
	, GenericTable
	, raiseError
	, deepCopy
} from "WebUI_Framework";

import rfqServiceRoutines from "./rfqServiceRoutines";
import AddEditRFQ from './AddEditRFQ';

class ManageRFQs extends GenericComponent {
	constructor(p) {
		super(p);
		this.recordsPerFrame = window.getParam("CURSOR_FRAME_SIZE");
		this.pageNumber = 1;
		//this.recordsPerPage = 15;
		this.recordsPerPage = window.getParam("TABLE_PAGE_SIZE");
		this.subscriberServer = this.session.getServerInstance("SUBSCRIBER");
		this.accessToken = this.session.getAccessToken();
		this.rfqs = [];
	}

	initialState = getInitialState();

	state = {
		data: deepCopy(this.initialState),
	};

	componentDidMount() {
		this.getFrame(1, this.recordsPerFrame);
	}

	getFrame = async (offset, recordsPerFrame) => {
		this.resetError();
		try {
			let retData = await rfqServiceRoutines.list(this.session, {
				offset, num_recs: recordsPerFrame,
				buyer_org_id: this.session.getLoggedInCompanyId()
			});
			if (retData.length > 0) {
				this.rfqs = this.rfqs.concat(retData);
			}
		} catch (e) {
			this.setError(raiseError(e.error_message));
		}
		this.setState({ isLoading: false });
	}

	onReturn = (ret) => {
		this.resetError();
		if (ret.error) {
			this.setError(ret.error);
			return;
		}
		/*eslint no-fallthrough: 0*/
		switch (ret.retFrom) {
			case "ADD":
				let header_rec = ret.selectData.header;
				header_rec.sellers = ret.selectData.sellers;
				this.rfqs.splice(((this.pageNumber - 1) * this.recordsPerPage), 0, ret.selectData.header);
				break;
			case "EDIT":
			case "APPROVE":
			case "VIEW":
				let updatedArrayIndex = ((this.pageNumber - 1) * this.recordsPerPage) + ret.arrayIndex;
				this.setState((prevState, props) => {
					this.rfqs[updatedArrayIndex] = ret.selectData.header;
					this.rfqs[updatedArrayIndex].sellers = ret.selectData.sellers;

					let newState = deepCopy(prevState);
					newState.editCount++;
					return newState;
				});
				break;
			case "BACK":
				break;
			case "DELETE":
				this.rfqs.splice(ret.arrayIndex, 1);
				this.setState({ editCount: this.state.editCount + 1 });
				break;
			default:
				this.setError(raiseError("Invalid return from AddEditRFQ"));
		}
		return;
	}

	handler = async (action, rfqId, pageNumber, arrayIndex) => {
		this.pageNumber = pageNumber;
		this.callForm(AddEditRFQ, { action, rfqId, arrayIndex, pageNumber, ...this.props }, this.onReturn);
	}

	table_buttons = {
		literal: "Add",
		func: () => {
			const ucp = this.session.getUserCompanyProps(this.session.getLoggedInCompanyId(),
				this.session.getLoggedInParentCompanyId());
			if (!(ucp.org_is_subscriber)) {
				if (ucp.partner_relationship_type === 'S') {
					this.setError(raiseError(window.parseLiteral("CANNOT_CREATE_RFQ"
						, this.session.getLoggedInCompanyName())));
					return false;
				}
			}
			this.handler("ADD", null, this.pageNumber, 0);
		}
	};

	pageChange = (pageNumber) => {
		this.pageNumber = pageNumber;
	}

	getHeader() {
		let key = 0;
		return (
			<tr key={++key}>
				<th key={++key}>RFQ No.</th>
				<th key={++key}>issue Date</th>
				<th key={++key}>Description</th>
				<th key={++key}>Valid From</th>
				<th key={++key}>Valid Till</th>
				<th key={++key}>Actions</th>
			</tr>
		);
	}

	getRow = (data, index, pageNumber, arrayIndex) => {

		this.pageNumber = pageNumber;

		let key = 0;
		let ik = 0;
		return (
			<React.Fragment key={index}>
				<tr key={key++}>
					<td>
						<button type="button"
							style={{
								color: "blue", textDecoration: "underline", cursor: "pointer",
								border: "none", background: "none", padding: 0
							}}
							onClick={() => this.handler("VIEW", data.rfq_id, pageNumber, arrayIndex)}
						>
							{data.rfq_number}
						</button>
					</td>
					<td>{data.rfq_issue_date}</td>
					<td>{data.description}</td>
					<td>{data.rfq_valid_from_date}</td>
					<td>{data.rfq_valid_to_date}</td>
					<td>
						{(data.entity_state === "0") && (
							<>
								<span onClick={() => this.handler("EDIT", data.rfq_id, pageNumber, arrayIndex)}
										title='Edit' style={{ cursor : "pointer"}}>
									<i className="bi bi-pencil-square" style={{ fontSize: "20"}} ></i>
								</span>&nbsp;&nbsp;
								<span onClick={() => this.handler("DELETE", data.rfq_id, pageNumber, arrayIndex)}
										title='Delete' style={{ cursor : "pointer"}}>
									<i className="bi bi-trash3" style={{ fontSize: "20"}} ></i>
								</span>
								&nbsp;&nbsp;
								<span onClick={() => this.handler("APPROVE", data.rfq_id, pageNumber, arrayIndex)}
										title='Approve' style={{ cursor : "pointer"}}>
									<i className="bi bi-check2" style={{ fontSize: "20"}} ></i>
								</span>&nbsp;&nbsp;
							</>
						)}
					</td>
				</tr>
				<tr key={key++}>
					<td colSpan="2" className="m-0 p-0">
						<table className="table m-0">
							<thead>
								<tr>
									<th>Prospect Sellers</th>
								</tr>
							</thead>
							<tbody>
								{data.sellers.map((seller) => {
									return <tr key={ik++}>
										<td key={ik++}>{seller.seller_org_name}</td>
									</tr>
								})
								}
							</tbody>
						</table>
					</td>
					<td colSpan="4" className="m-0 p-0"/>
				</tr>
			</React.Fragment>
		);
	}

	render = () => {
		const tableclass = "table table-striped border-dark";
		let datablk = (
			<div className="container-fluid pt-0 m-0 ">
				<div className="col-sm-12">
					<GenericTable
						pageHeading={this.getBreadcrumb()}
						pageHeadingClass="h6 text-left"
						data={this.rfqs}
						recordsPerPage={this.recordsPerPage}
						getRow={this.getRow}
						getHeader={this.getHeader}
						tableclass={tableclass}
						getFrame={this.getFrame}
						recordsPerFrame={this.recordsPerFrame}
						pageNumber={this.pageNumber}
						isLoading={this.state.isLoading}
						button={this.table_buttons}
						pageChange={this.pageChange}
					/>
				</div>
			</div>
		);

		let frm = (
			<GenericForm
				datablk={datablk}
				buttons={[]}
				controlProps={this.controlProps}
				{...this.props}
			/>
		);
		return frm;
	}
}

export default ManageRFQs;
