import React, { useState, useRef } from 'react';
import {
	GenericComponent,
	NarrowButton,
	GenericForm,
	FormField,
	valuePresent,
    addError,
    ErrorsForElement,

	fieldValidations,
	getInitialState,
	copyFormFieldsToDataFields,
	copyDataFieldsToFormFields,
	deepCopy,
	raiseError,

    InnerHTML,
	generic_string,
	genericstring,
	genericgenrefcode,
	generic_genrefcode,
	Backdrop,
	Modal_view_content,

} from 'WebUI_Framework';

import ruleServiceRoutines from "./ruleServiceRoutines";

const partsMapping = {
    "0": "rfqParts"
    ,"1": "quotationParts"
    ,"2": "orderParts"
    ,"3": "orderParts"
    ,"4": "deliveryNoteParts"
    ,"5": "deliveryChallanParts"
    ,"6": "receiptNoteParts"
    ,"7": "invoiceParts"
    ,"8": "invoiceParts"
    ,"9": "drCrNoteParts"
    ,"A": "drCrNoteParts"
};

const header_fields = {
	description: {
		id: "description", name: "description", label: window.getLabelLiteralWithColon("DESCRIPTION"),
        class: genericstring, component: generic_string, mandatory: true
	},
    condition: {
        id: "condition", name: "condition", label: window.getLabelLiteralWithColon("CONDITION_TITLE"),
        class: genericstring, component: generic_string, mandatory: false
    },
    document_type: {
        id: "document_type", name: "document_type", label: window.getLabelLiteralWithColon("DOCUMENT_TYPE"),
        class: genericgenrefcode, component: generic_genrefcode, mandatory: true
    },
}

let header_mapping = [
	["description", "description"],
    ["condition", "condition"],
    ["document_type", "document_type"],
];


class AddEditRule extends GenericComponent {
	constructor(props) {
		super(props);
		this.state.loading = true;
        this.state.viewing_documentation = false;
		this.state.step = 1;

		this.readOnly = false;
        this.sections = [];
        this.configured_rules = {};
        this.selected_rules = {};
        this.selected_rule_versions = {};
 
		this.subscriberServerInstance = this.session.getServerInstance('SUBSCRIBER');
		this.accessToken = this.session.getAccessToken();

		this.action = props.action;
		this.rule_set_id = props.rule_set_id;
		this.rule_set = { header: {}, items: [{}] };

		this.logged_in_company_id = this.session.getLoggedInCompanyId();
		this.parent_company_id = this.session.getLoggedInParentCompanyId();
		this.is_subscriber = (this.logged_in_company_id === this.parent_company_id);

		this.page_title = "";
		this.page_title += (this.action === "DELETE") ? window.getLabelLiteral("DELETING") : window.getLabelLiteral(this.action);
		if (this.action === "ADD") {
		}
		else if (this.action === "EDIT") {
            this.state.step = 2;
		}
		else if (this.action === "AMEND") {
            this.state.step = 2;
		}
		else if (this.action === "APPROVE") {
            this.state.step = 2;
			this.readOnly = true;
		}
		else if (this.action === "CANCEL") {
            this.state.step = 2;
			this.readOnly = true;
		}
		else if (this.action === "VIEW") {
            this.state.step = 2;
			this.readOnly = true;
		}
		else if (this.action === "DELETE") {
            this.state.step = 2;
			this.readOnly = true;
		}
		this.page_title += " " + window.getLabelLiteral("RULE_SETUP_TITLE");
	}

	initialState = getInitialState(header_fields);

	state = {
		data: deepCopy(this.initialState),
	}

	backHandler = (retFrom, error) => {
		if (this.isChildMode())
			this.props.returnToParent({
				retFrom, arrayIndex: this.props.arrayIndex,
				selectData: this.rule_set, error
			});
		else console.log("LOADED AS A PARENT");
	}

	fetch = async (rule_set_id) => {
		return await ruleServiceRoutines.fetch(this.session, rule_set_id);
	}

	fetchAmended = async (rule_set_id) => {
		return await ruleServiceRoutines.fetchAmended(this.session, rule_set_id);
	}

	delete = async (rule_set) => {
		let retData;
		try {
			await ruleServiceRoutines.delete(this.session, rule_set);
            this.rule_set = await this.fetchAmended(this.rule_set_id);
            console.log(this.rule_set);
		}
		catch (e) {
			//this.setError(raiseError(e.error_message));
			throw e;
		}

		return retData
	}

    getSections = (document_type) => {
        let parts_ref = window.getGenericRefCode(partsMapping[document_type]);
        let sections = [];
        for (const key in parts_ref) {
            sections.push({key: key, desc: parts_ref[key] });
        }

        return sections;
    }

    getConfiguredRules = async (sections, document_type) => {
        const configured_rules = {};
        sections.map((section) => {
            configured_rules[section.key] = [];

            return section;
        });
        const config_rules = await ruleServiceRoutines.fetchConfiguredRules(this.session, {document_type : document_type});
        config_rules.map((rule) => {
            configured_rules[rule.document_part].push(rule);
            if (this.selected_rule_versions[rule.rule_id+rule.rule_version]) {
                this.selected_rule_versions[rule.rule_id+rule.rule_version].rule_inputs = rule.rule_inputs;
            }

            return rule;
        });

        return configured_rules;
    }

    getSelectedRules = (rule_set) => {
        const selected_rules = {};
        const selected_rule_versions = {};
        rule_set.items.map((item) => {
            selected_rules[item.rule_id] = item;
            selected_rule_versions[item.rule_id+item.rule_version] = item;

            return item;
        })

        return { selected_rules, selected_rule_versions };
    }

    selectDeselectRule = (rule) => {
        if (valuePresent(this.selected_rule_versions[rule.rule_id+rule.rule_version])) {
            delete this.selected_rule_versions[rule.rule_id+rule.rule_version];
            delete this.selected_rules[rule.rule_id];

        }
        else {
            if (valuePresent(this.selected_rules[rule.rule_id])) {
                if (this.selected_rules[rule.rule_id].rule_version !== rule.rule_version) {
                    this.setError(raiseError(window.getLiterals("ONLY_ONE_RULE_VERSION")));

                    return ;
                }
            }
            const r = deepCopy(rule);
            r.excp_type = "X";
            if (valuePresent(r.rule_inputs)) {
                let i = 0;
                r.rule_item_inputs = [];
                r.rule_inputs.map((item) => {
                    r.rule_item_inputs[i] = {};
                    r.rule_item_inputs[i].name = item.variable_name;
                    r.rule_item_inputs[i].value = "";
                    i++;
                    return item;
                });
            }
            this.selected_rule_versions[rule.rule_id+rule.rule_version] = r;
            this.selected_rules[rule.rule_id] = r;
        }

        this.forceUpdate();

        return;
    }

	componentDidMount = async () => {
		let newData;
		try {
			switch (this.action) {
				case 'ADD':
					newData = deepCopy(this.state.data);
					this.setState({ loading: false, data: newData });
					break;
				/*eslint no-fallthrough: 0*/
				case 'VIEW':
				case 'EDIT':
				case 'DELETE':
					this.rule_set = await this.fetch(this.rule_set_id);

                    const ss1 = this.getSelectedRules(this.rule_set);
                    this.selected_rules = ss1.selected_rules;
                    this.selected_rule_versions = ss1.selected_rule_versions;

                    this.sections = this.getSections(this.rule_set.header.document_type);
                    this.configured_rules = await this.getConfiguredRules(this.sections, this.rule_set.header.document_type);

					newData = deepCopy(this.state.data);
					copyDataFieldsToFormFields(header_mapping, this.rule_set.header, newData);
					switch (this.action) {
						case 'DELETE':
							this.setState({ data: newData });
							if (this.rule_set.header.deleted === true) {
								this.setError(raiseError(window.getLabelLiteral("RECORD_DELETED") + " [" + this.rule_set_id + "]"));
								this.backHandler(this.action);
							}
							await this.delete(this.rule_set);
							this.backHandler(this.action);
							break;
						case 'EDIT' : 
						//issue
						default:

							this.setState({ loading: false, data: newData });
							break;
					}

					break;
				case 'CANCEL':
				case 'APPROVE':
				case 'AMEND':
					this.rule_set = await this.fetchAmended(this.rule_set_id);

					newData = deepCopy(this.state.data);
					copyDataFieldsToFormFields(header_mapping, this.rule_set.header, newData);
                    this.sections = this.getSections(this.rule_set.header.document_type);

                    const ss2 = this.getSelectedRules(this.rule_set);
                    this.selected_rules = ss2.selected_rules;
                    this.selected_rule_versions = ss2.selected_rule_versions;

                    this.configured_rules = await this.getConfiguredRules(this.sections, this.rule_set.header.document_type);
                    this.setState({ loading: false, data: newData });
                    break;
				default:
					this.setError(raiseError("AddEditRule: Invalid action [" + this.action + "]"));
					this.backHandler(this.action, this.error);
					break;
			}
		}
		catch (e) {
			console.log(this.action);
			console.log(e);
			this.setError(raiseError((e.error_message) ? e.error_message : e.message));
			this.backHandler(this.action, this.error);
		}
	}

    proceed = async () => {
		this.resetError();
		let r = fieldValidations(header_fields, this.state.data);
		if (!r.status) {
			this.setError(r);
			return false;
		}

        try {
            let data = deepCopy(this.state.data);
            this.sections = this.getSections(data.document_type);
            this.configured_rules = await this.getConfiguredRules(this.sections, data.document_type);

            this.setState({ step: 2, data });
        } catch (e) {
			throw e;
        }
    }

	close_modal_view = () => {
		this.setState({ viewing_documentation: false });
	}

    setup_modal_view = async (rule) => {
        const html =
            await ruleServiceRoutines.fetchFormattedDocumentation(this.session,
                            {rule_id : rule.rule_id, rule_version: rule.rule_version});
        this.modal_content = <InnerHTML rawHTML={html.html} />;
        this.setState({ viewing_documentation: true });

        return;
    }

    get_modal_content = () => {
        return this.modal_content;
    }

	data_render = () => {
        const InpValue = (props) => {
            let readOnly=false;
            if (props.readOnly === true) readOnly = props.readOnly;
            let disabled = (props.disabled === true)?props.disabled:readOnly;

            // eslint-disable-next-line react-hooks/rules-of-hooks
            let [dc, setDc] = useState(0);
            // eslint-disable-next-line react-hooks/rules-of-hooks
            let inpRef = useRef(null);
            // eslint-disable-next-line react-hooks/rules-of-hooks

            const item = props.item;
            const rule= props.rule;
            let selectedRule = props.selectedRule;
            const index = props.varIndex;

            return <input
                type="text"
                ref={inpRef}
                name={item.variable_name+rule.rule_id+rule.rule_version}
                id={item.variable_name+rule.rule_id+rule.rule_version}
                maxLength="100"
                className={`form-control-sm border w-60 text-body`}
                style={{}}
                readOnly={disabled}
                value={selectedRule.rule_item_inputs[index].value}
                onChange={(e) => {
                    selectedRule.rule_item_inputs[index].value = e.target.value;
                    setDc(++dc);
                }}
            />;
        }
		let key = 0;

		let datablk = (
			<div className="container-fluid border border-0 pt-0 m-0 ">
				<div className="row	p-0 m-0 g-0 ">
					<div className="col-sm-12 h6 text-left">
						{this.getBreadcrumb([window.getLabelLiteral(this.action)])}
					</div>
					<hr></hr>
					<div className="col-sm-6">
						<FormField
							field={header_fields.description}
							controlProps={this.controlProps}
							readOnly={(this.readOnly) }
						/>
					</div>
					<div className="col-sm-6">
						<FormField
							field={header_fields.document_type}
							controlProps={this.controlProps}
							readOnly={(this.readOnly || this.action !==  'ADD' || this.state.step > 1) }
                            refType={"documentTypes"}
						/>
					</div>
					<div className="col-sm-6">
						<FormField
							field={header_fields.condition}
							controlProps={this.controlProps}
							readOnly={(this.readOnly) }
						/>
					</div>
					<div className="col-sm-6">
                        &nbsp;
					</div>
                </div>
                {(this.state.step === 1) &&
                    <div className="col-sm-6 mt-4 d-flex justify-content-right ">
                        <NarrowButton value="Proceed" onClick={this.proceed} />
                        <NarrowButton value="Back" onClick={() => { return this.backHandler("BACK"); }} key={(++key).toString()} />
                    </div>
                }
                {(this.state.step === 2) &&
                    <>
                    { this.sections.map((section) => {
                        return <React.Fragment key={++key}>
                            <div key={++key} className="col-sm-12 h6 text-left pt-3 pb-0 mb-0">
                            {section.desc}
                            </div>
                            <hr key={++key} className="m-0 mt-1 mb-1 pt-0"></hr>
                            {
                                this.configured_rules[section.key].map((rule) => {
                                    return <React.Fragment key={++key}>
                                    <div className="container ms-3">
                                        <div className="row">
                                            <div className="col-6 form-check text-start " >
                                                <input
                                                    key={++key}
                                                    type="checkbox"
                                                    className="form-check-input text-start"
                                                    id={rule.rule_id+rule.rule_version}
                                                    name={rule.rule_id+rule.rule_version}
                                                    checked={valuePresent(this.selected_rule_versions[rule.rule_id+rule.rule_version])}
                                                    disabled={this.readOnly}
                                                    onChange={(event) => { return this.selectDeselectRule(rule)  }}
                                                />
                                                <button type="button"
                                                        style={{ color: "blue", textDecoration: "underline", cursor: "pointer",
                                                                border:"none", background:"none", padding:0, textAling: "start" }}
                                                        onClick={async (event) => { return await this.setup_modal_view(rule)}}>
                                                    {rule.description}
                                                </button>
                                            </div>
                                            {(valuePresent(this.selected_rule_versions[rule.rule_id+rule.rule_version])) &&
                                                <>
                                                <div className="col-1 form-check m-0 p-0 " >
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name={"excp"+rule.rule_id+rule.rule_version}
                                                        id={"excp"+rule.rule_id+rule.rule_version}
                                                        value={"X"}
                                                        disabled={this.readOnly}
                                                        defaultChecked={
                                                        this.selected_rule_versions[rule.rule_id+rule.rule_version].excp_type === 'X' }
                                                        onChange={(e) => {
                                                            this.selected_rule_versions[rule.rule_id+rule.rule_version].excp_type =
                                                                e.target.value;
                                                        }}
                                                    />
                                                    <span className="form-check-label" >
                                                        {"Exception"}
                                                    </span>
                                                </div>
                                                <div className="col-1 form-check m-0 p-0 ps-2 " >
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name={"excp"+rule.rule_id+rule.rule_version}
                                                        id={"err"+rule.rule_id+rule.rule_version}
                                                        value={"E"}
                                                        disabled={this.readOnly}
                                                        defaultChecked={
                                                        this.selected_rule_versions[rule.rule_id+rule.rule_version].excp_type === 'E' }
                                                        onChange={(e) => {
                                                            this.selected_rule_versions[rule.rule_id+rule.rule_version].excp_type =
                                                                e.target.value;
                                                        }}
                                                    />
                                                    <span className="form-check-label" >
                                                        {"Error"}
                                                    </span>
                                                </div>
                                                </>
                                            }
                                        </div>
                                        {valuePresent(this.selected_rule_versions[rule.rule_id+rule.rule_version]) &&
                                            valuePresent(rule.rule_inputs) &&
                                            <div className="container-fluid ms-3 ps-3 my-3 ">
                                            <div className="col-6">
                                                <table className="table border-dark">
                                                    <thead>
                                                        <tr>
                                                            <th key={++key}>
                                                                <div className="text-center">
                                                                    {"Variable name"}
                                                                </div>
                                                            </th>
                                                            <th key={++key}>
                                                                <div className="text-start">
                                                                    {"Input value"}
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {rule.rule_inputs.map((item, i) => {
                                                        return <tr key={++key}>
                                                            <td key={++key}>
                                                                <div className="text-center">
                                                                    {item.variable_name}
                                                                </div>
                                                            </td>
                                                            <td key={++key}>
                                                                <div className="text-start ">
                                                                    <div className="row">
                                                                        <InpValue
                                                                            item={item}
                                                                            rule={rule}
                                                                            selectedRule=
                                                                             {this.selected_rule_versions[rule.rule_id+rule.rule_version]}
                                                                            varIndex={i}
                                                                            readOnly={this.readOnly}
                                                                        />
                                                                    </div>
                                                                    <div className="text-start">
                                                                    <ErrorsForElement id={item.variable_name+rule.rule_id+rule.rule_version}
                                                                        name={item.variable_name+rule.rule_id+rule.rule_version}
                                                                        controlProps={this.controlProps}
                                                                    />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            </div>
                                        }
                                    </div>
                                    </React.Fragment>
                                })
                            }
                        </React.Fragment>
                    })
                    }
                    <div className="row  p-0 m-0 g-0 " >
                        <div className="col-sm-6 mt-4 d-flex justify-content-right ">
                            {(this.action !== 'VIEW') &&
                                <NarrowButton value="Submit" onClick={this.submitForm} key={(++key).toString()} />
                            }
                            <NarrowButton value="Back" onClick={() => { return this.backHandler("BACK"); }} key={(++key).toString()} />
                        </div>
                    </div>
                    </>
                }
                {(this.state.viewing_documentation) && <Modal_view_content
                    modalDialogClass={"modal-dialog"}
                    modalDialogStyle={{marginLeft: "10em"}}
                    title={[window.getLabelLiteral("DOCUMENTATION")]}
                    btn1="Close"
                    onClick={this.close_modal_view}
                    modalContent={this.get_modal_content()}
                    />
                }
                {(this.state.viewing_documentation) && <Backdrop />}
			</div>
		);

		let form = <GenericForm datablk={datablk} buttons={[]} controlProps={this.controlProps} {...this.props} />
		return form;
	}

	loading_render = () => {
		let datablk = <div className="container border border-0 pt-0 m-0 ">
			<div className="row  p-0 m-0 g-0 " >
				<div className="col-sm-12 h4 text-center" >{this.page_title}</div>
				<div className="d-flex justify-content-evenly">
					<div className="spinner-border text-dark" role="status">
						<span className="sr-only">Loading...</span>
					</div>
				</div>
			</div>
		</div>

		let form = <GenericForm datablk={datablk} buttons={[]} controlProps={this.controlProps} {...this.props} />
		return form;
	}

	render = () => {
		if (this.state.loading) {
			return this.loading_render();
		}
		else {
			return this.data_render();
		}
	}

	validateForm = () => {
		let r = fieldValidations(header_fields, this.state.data);
        for (const s_key in this.selected_rule_versions ) {
            if (this.selected_rule_versions[s_key].rule_item_inputs) {
                for (let j=0; j <  this.selected_rule_versions[s_key].rule_item_inputs.length; j++) {
                    if ((this.selected_rule_versions[s_key].rule_inputs[j].mandatory === true) &&
                        ("" === this.selected_rule_versions[s_key].rule_item_inputs[j].value)) {

                        const rule = this.selected_rule_versions[s_key];
                        const item = this.selected_rule_versions[s_key].rule_inputs[j];

                        addError("Mandatory input should contain value", item.variable_name+rule.rule_id+rule.rule_version, r);
                    }
                }
            }
        }

		if (!r.status) {
			console.log(r);
			this.setError(r);
			return false;
		}

		return true;
	}

	submitForm = async () => {
		this.resetError();
		if (!this.validateForm()) {
			return;
		}

		let data = deepCopy(this.state.data);
		copyFormFieldsToDataFields(header_mapping, data, this.rule_set.header, header_fields);
        this.rule_set.items = [];
        switch (this.action) {
            case "ADD":
            case "EDIT":
            case "AMEND":
                for (const key in this.selected_rule_versions) {
                    const item = {};
                    item.rule_id = this.selected_rule_versions[key].rule_id;
                    item.rule_version = this.selected_rule_versions[key].rule_version;
                    item.rule_item_inputs = this.selected_rule_versions[key].rule_item_inputs;
                    item.excp_type = this.selected_rule_versions[key].excp_type;
                    this.rule_set.items.push(item);
                }
            default:
                break;
        }

		try {
			switch (this.action) {
				case "ADD":
					this.rule_set = await ruleServiceRoutines.add(this.session, this.rule_set);
					break;
				case "EDIT":
					await ruleServiceRoutines.modify(this.session, this.rule_set);
					this.rule_set = await this.fetch(this.rule_set_id);
					break;
				case "AMEND":
					await ruleServiceRoutines.amend(this.session, this.rule_set);
					this.rule_set = await this.fetchAmended(this.rule_set_id);
					break;
				case "APPROVE":
					await ruleServiceRoutines.approve(this.session, this.rule_set);
					this.rule_set = await this.fetch(this.rule_set_id);
					break;
				case "CANCEL":
					await ruleServiceRoutines.cancel(this.session, this.rule_set);
					this.rule_set = await this.fetch(this.rule_set_id);
					break;
				default:
					break;
			}

			this.backHandler(this.action);
		}
		catch (e) {
			console.log(this.rule_set);
			console.log(e);
			this.setError(raiseError(e.error_message));
		}
	}

}


export default AddEditRule;
