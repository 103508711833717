import { handleAxiosHTTPException } from "WebUI_Framework";

let SwitchOrgServiceRoutines = {};

SwitchOrgServiceRoutines.switchOrgId = async (serverInstance, accessToken, companyId, parentCompanyId) => {
	try {
		let ret = await serverInstance.get(
			`/biop/subscriber/masters/switch_org_id?subscriber_org_id=${parentCompanyId}&org_id=${companyId}`,
			{
				headers: {
					"X-Auth": accessToken
				}
			});
		return ret;
	}
	catch (e) {
		return handleAxiosHTTPException(e);
	}
}

SwitchOrgServiceRoutines.subscribersList = async (serverInstance, accessToken) => {
	try {
		let ret = await serverInstance.get(
			`/biop/subscriber/control/list_subscribers`,
			{
				headers: {
					"X-Auth": accessToken
				}
			});
		return ret;
	}
	catch (e) {
		return handleAxiosHTTPException(e);
	}
}

export default SwitchOrgServiceRoutines;
