import { getServiceDetails } from '../common/utils'
import {
	handleAxiosHTTPException
} from "WebUI_Framework";

let subscriberserviceroutine = {};

subscriberserviceroutine.list = async ( serverInstance, accessToken, offset, recordsPerFrame) => {
	let response;
	try {
	response = await serverInstance.get(
			`/biop/subscriber/control/list_subscribers`,
			{
				headers: {
					"X-Auth": accessToken,
				},
				params : {
					offset: offset,
					num_recs: recordsPerFrame,
					deleted: 0
				}
			}
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
}

subscriberserviceroutine.companyList = async ( serverInstance, accessToken, searchText) => {
	let response;
	try {
		response = await serverInstance.get(
			`/biop/subscriber/control/list_unregistered_subscribers`,
			{
				headers: {
					"X-Auth": accessToken,
				},
			}
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
}

subscriberserviceroutine.add = async ( serverInstance, accessToken,orgId) => {
	let response;
	try {
		response = await serverInstance.post(
			`/biop/subscriber/control/activate_subscription?org_id=${orgId}&override=true`, null,
			{
				headers: {
					"X-Auth": accessToken,
				},
			}
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

subscriberserviceroutine.fetchParameters = async (session, orgId) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
    const qp = {
        org_id : orgId
    }
	let response;
	try {
		response = await serverInstance.get(
			`/biop/subscriber/subscriber_parameters_manager/fetch`,
			{
				headers: {
					"X-Auth": accessToken,
				},
                params: qp,
			}
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};


subscriberserviceroutine.modifyParameters = async ( session, subsciberParameters) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post(
			`/biop/subscriber/subscriber_parameters_manager/modify`,
            subsciberParameters,
			{
				headers: {
					"X-Auth": accessToken,
				},
			}
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};


export default subscriberserviceroutine;
