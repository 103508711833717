import AddCommercialTaxDetail from "./AddCommercialTaxDetail";
import EditCommercialTaxDetail from "./EditCommercialTaxDetail";
import { 
		GenericComponent,
		GenericTable,
		GenericForm,
		deepCopy,
		Modal,
		Backdrop,
		getInitialState,
		raiseError,
		Modal_view_content
} from 'WebUI_Framework';
import 'bootstrap-icons/font/bootstrap-icons.css';
import  serviceRoutines from './CommercialtaxdetailServiceRoutines';

let refCode_location = window.getRefCode('00012');
class ManageCommercialTax extends GenericComponent{

	constructor(p){
		super(p);
		this.recordsPerFrame = window.getParam("CURSOR_FRAME_SIZE");
		this.pageNumber = 1;
		this.selectArrayIndex = 0;
		this.selectedOrgId = 0;
		this.selectedData="";
		//this.recordsPerPage = 10;
        this.recordsPerPage = window.getParam("TABLE_PAGE_SIZE");
		this.state.showErrorFlag = false;
		this.error1 = "";
        this.state = {
			isLoading : true,
			data : [], 
			showModal : false			
		}
        this.org_id = this.session.getLoggedInCompanyId();
		this.serverInstance = this.session.getServerInstance('ROC');
		this.accessToken = this.session.getAccessToken();		 
        this.getRow = this.getRow.bind(this);
	    this.getFrame = this.getFrame.bind(this);
	    this.onConfirm = this.onConfirm.bind(this);
		this.button.func = this.button.func.bind( this );
		this.data = [];
		this.state.data1 = [];
		this.Commercialtaxdetails = {};
	}

	componentDidMount() {
		this.getFrame(1,this.recordsPerFrame);
	}

	initialState = getInitialState();

	state = {
		data : deepCopy(this.initialState),
	}
	onReturn = (ret) => {
		console.log("manage commercial tax detail page");

		this.setState((prevState, props) => {
			let newState = deepCopy(prevState);
	
			if(ret.retFrom==="add"){
				this.pageNumber = 1;
				newState.data = [];
				this.getFrame(1, this.recordsPerFrame);
			}
			else if (ret.retFrom==="edit"){
				let updatedArrayIndex = ((this.pageNumber - 1) * this.recordsPerPage) + ret.arrayIndex;
				newState.data[updatedArrayIndex] = ret.selectedData;
			}			
			/*
			else if (ret.retFrom==="back"){
				let updatedArrayIndex = ((this.pageNumber - 1) * this.recordsPerPage) + ret.arrayIndex;
			}	
			*/
			return newState;
		}); 
	}


    addHandler = () => {
		this.callForm(AddCommercialTaxDetail, {...this.props}, this.onReturn );
	}

	editHandler = (selectedData, arrayIndex) => {
		this.callForm(EditCommercialTaxDetail, {selectedData,arrayIndex,...this.props}, this.onReturn );
	}

	
	deleteHandler = ( selectedData, arrayIndex) => {
		
		this.selectedData = selectedData;
	    this.selectArrayIndex = arrayIndex;
		this.setState({showModal: true});
	}

	
	viewHandler = async (selectedData, arrayIndex) => {
		this.callForm(EditCommercialTaxDetail, {readOnly: true, selectedData,arrayIndex,...this.props}, this.onReturn );
        /*
		let retData;
        try {
            retData = await serviceRoutines.fetch(this.session.getServerInstance('ROC'), this.session.getAccessToken(), selectedData)
            if (retData !== undefined && retData !== null) {

                this.Commercialtaxdetails =retData;
                this.data = retData;
				this.setState( { data1: this.Commercialtaxdetails } )
				this.setState({ viewModal: true });

            }
        }
        catch (e) {
            this.setError(raiseError(e.error_message));
        }
        */
	}

	onConfirm = async(control) =>{
		try {
			const retData = await serviceRoutines.fetch(this.serverInstance,this.accessToken,this.selectedData)
			if(retData !== undefined && retData !== null)
			{
				await serviceRoutines.delete(this.serverInstance,this.accessToken,retData)
				let updatedArrayIndex = ((this.pageNumber - 1) * this.recordsPerPage) + this.selectArrayIndex;
				this.state.data.splice(updatedArrayIndex,1);
				this.setState( { data: this.state.data } );
				this.setState({showModal:false});
			}
		}
		catch(e) {
			control.error = true;
			control.errorMsg = e.error_message;
		}
	}

	closeModal = () =>{
		this.setState({showModal : false});
	}
	closeViewModal = () => {
        this.setState({ viewModal: false });
	}
    async getFrame(offset,recordsPerFrame) {

		let retData;
		try {
			retData  = await serviceRoutines.list(this.serverInstance,this.accessToken,offset,recordsPerFrame,this.org_id);
			this.setState({isLoading : false});
			if (retData.length > 0) {
				let CommercialTaxDetail = retData;
				this.setState({ data: this.state.data.concat(CommercialTaxDetail) });
			}
		}
		catch(e) {
			console.log(e);
			this.setError(raiseError(e.err_message));
		}
	}
	getHeader(){
		let key = 0;
		return (
		<tr key={++key}>
			<th key={++key}>Location</th>
			<th key={++key}>Tax Identification Type</th>
            <th key={++key}>Tax Identification Number</th>	
            <th key={++key}>Actions</th>		
		</tr>
		);
	}


    getRow(data, index, pageNumber, arrayIndex){
	
		this.pageNumber = pageNumber;
		return(
			
			<tr key={ index }>
				<td>
					<button type="button"
						style={{ color: "blue", textDecoration: "underline", cursor: "pointer",
								border:"none", background:"none", padding:0 }}
						onClick={ () => this.viewHandler( data, arrayIndex ) }>{refCode_location[data.location]}
					</button>
				</td>
				<td>{data.tin_type}</td>
				<td>{data.tin_number}</td>	
				<td>
					<span onClick={()=>this.editHandler(data, arrayIndex )}
					title="Edit"
					style={{ cursor: 'pointer' }}>
					<i className="bi bi-pencil-square" style={{fontSize:20}} ></i>
					</span>&nbsp;&nbsp;
					<span onClick={()=>this.deleteHandler(data,arrayIndex)}
					title="Delete"
					style={{ cursor: 'pointer' }}>
					<i className="bi bi-trash3" style={{fontSize:20}} ></i>
					</span>
				</td>				
			</tr>
		)
	} 

   

    button = {
		literal : "Add",
		func : function(){
			this.callForm(AddCommercialTaxDetail, {...this.props}, this.onReturn );
		}
	}


    render() {
        let buttons = [];
		let modalViewContent = <div className="container pt-0 m-0 ">
			<EditCommercialTaxDetail
				selectedData={ this.data }
				readOnly={true}
				noFormTag={true}
				{...this.props} />
		</div>
        const tableclass = "table table-striped border-dark";
        let datablk = <div className="container-fluid pt-0 m-0 pe-2">
                        <div className="col-sm-12">
                        <GenericTable
							pageHeading={this.getBreadcrumb()}
							pageHeadingClass="h6 text-left"
                            data={this.state.data}
                            recordsPerPage={this.recordsPerPage}
                            getRow = {this.getRow}
                            getHeader={this.getHeader}
                            tableclass={tableclass}
                            getFrame={this.getFrame}
                            recordsPerFrame={this.recordsPerFrame}
                            pageNumber={this.pageNumber}
                            isLoading={this.state.isLoading}
                            button = {this.button}
                        />

                        </div>

                        {(this.state.showModal) && <Modal 
                             title="Delete Confirmation"
                             msg="Are you sure, you want to delete the Commercial Tax Details?"
                             btn1 = "Confirm"
                             btn2 = "Cancel"
                             onClick = {this.closeModal}
                             onConfirm = {this.onConfirm}
                             /> 

                             }
						{ (this.state.showModal) && <Backdrop /> }

						{(this.state.viewModal) && <Modal_view_content
							title={this.getBreadcrumb([window.getLabelLiteral("VIEW")])}
							btn1="Close"
							onClick={this.closeViewModal}
							modalContent={modalViewContent}
						/>
						}
						{(this.state.viewModal) && <Backdrop />}
                                 
                    </div>
                                              
                                       
                        
                    let frm = <GenericForm datablk={datablk} buttons={buttons} controlProps={this.controlProps} {...this.props } />
                                
                     return frm;

             
    }
                           
                                        
}

export default ManageCommercialTax;


